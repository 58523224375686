import React, { useEffect, useState, useRef } from "react";
import CareerForm from "./Careerform";
import Header from "./Header";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import {
  server_post_data,
  job_vancancy_details_url,
} from "../ServiceConnection/serviceconnection.js";
function CareerDetails() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);

  const [VacancytData, setVacancytData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const handleFetchData = async () => {
    setShowLoader(true);

    var form_data = new FormData();
    form_data.append("current_url", "/" + currentUrl);
    await server_post_data(job_vancancy_details_url, form_data)
      .then((response) => response.json())
      .then((data_data) => {
        console.log(data_data);
        setShowLoader(false);
        setVacancytData(data_data.vancancy_loop[0]);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const careerFormRef = useRef(null);
  const scrollToForm = () => {
    if (careerFormRef.current) {
      const testimonialsElement = careerFormRef.current;
      const testimonialsOffset = testimonialsElement.offsetTop;
      const scrollToPosition =
        testimonialsOffset -
        6 * parseFloat(getComputedStyle(document.documentElement).fontSize);

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  };

  const footerRef = useRef(null);
  const topRef = useRef(null);

  return (
    <div className="careerDetails" ref={topRef}>
      <div className={showLoader ? "loading" : ""}></div>
      <div className="navStickyTop">
        <Header footerRef={footerRef} />
      </div>
      <div className="careerDetails_container container-lg">
        <div className="vacncyheadDiv">
          <h1 className="vacncyhead_">{VacancytData.designation}</h1>
        </div>
        <div className="POXCards">
          <div className="row col-xl-10 col-lg-11">
            <div className="col-lg-3 col-sm-4">
              <div className="POXcard">
                <div className="POXcard-body">
                  <h5 className="POXcard-title">Experience</h5>
                  <p className="POXcard-text">
                    {VacancytData.min_experience}-{VacancytData.max_experience}{" "}
                    Years{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4">
              <div className="POXcard">
                <div className="POXcard-body">
                  <h5 className="POXcard-title">Opening</h5>
                  <p className="POXcard-text">{VacancytData.no_of_position}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-4">
              <div className="POXcard">
                <div className="POXcard-body">
                  <h5 className="POXcard-title">Package</h5>
                  <p className="POXcard-text">{VacancytData.salary_details}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Dscphead col-lg-8 col-11">
          <h5>Qualification </h5>
          <p>{VacancytData.qualification}</p>
        </div>
        <div className="Dscphead col-lg-8 col-11">
          <h5>Skills </h5>

          <p>
            {" "}
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(VacancytData.skills),
              }}
            />{" "}
          </p>
        </div>
        <div className="Dscphead col-lg-8 col-11">
          <h5>Job Description</h5>
          <p>
            {" "}
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(VacancytData.job_description),
              }}
            />{" "}
          </p>
          <button
            type="button"
            onClick={scrollToForm}
            className="btn btn-primary "
          >
            Apply Now
          </button>
        </div>

        <div ref={careerFormRef} className="applyvacancyDiv">
          <h4>Enter Below Details To Apply</h4>
          <div className="formDetailsContainer">
            <CareerForm dynamic_id={VacancytData.v_id} />
          </div>
        </div>
      </div>
      <div ref={footerRef}>
        <Footer servicesRef={null} topRef={topRef} updateHomeData={null} />
      </div>
    </div>
  );
}

export default CareerDetails;
