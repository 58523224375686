import React, { useEffect, useState, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { useLocation } from "react-router-dom";
import triosoftsmall from "../assets/images/triosoft_small.png";
import Insta from "../assets/images/insta2.svg";
import Linked from "../assets/images/Linkedin.svg";
import twitter from "../assets/images/twitter1.svg";
import DOMPurify from "dompurify";
import "../Css/Blog.css";
import {
  server_post_data,
  get_blog_details_url,
} from "../ServiceConnection/serviceconnection.js";
import { Link } from "react-router-dom";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
function Blogdetail() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [SEOloop, setSEOloop] = useState([]);
  const [VacancytData, setVacancytData] = useState([]);
  const [CentertData, setCentertData] = useState([]);
  const [BlogtData, setBlogtData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const DateFormatdate = (dateformat) => {
    const myDate = new Date(dateformat);
    // Options for formatting the date
    const options = { year: "numeric", month: "long", day: "numeric" };
    // Format the date using the options
    const formattedDate = myDate.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  const handleFetchData = async () => {
    setShowLoader(true);

    var form_data = new FormData();
    form_data.append("current_url", "/" + currentUrl);

    await server_post_data(get_blog_details_url, form_data)
      .then((response) => response.json())
      .then((data_data) => {
        console.log(data_data);
        setShowLoader(false);
        setVacancytData(data_data.vancancy_loop[0]);
        setCentertData(data_data.center_loop[0]);
        setBlogtData(data_data.blog_list);
        setSEOloop(data_data.seo_loop);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };
  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/Blogdetails/blog/" + v_id;
    let data_seo_link = data_seo_link_final;

    const matchedItem = SEOloop.find(
      (data) => data_seo_link === data.call_function_name
    );

    if (matchedItem) {
      data_seo_link_final = matchedItem.pretty_function_name;
    }

    return data_seo_link_final;
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const footerRef = useRef(null);
  const topRef = useRef(null);

  return (
    <div className="shoblog" ref={topRef}>
      <div className={showLoader ? "loading" : ""}></div>
      <div className="header_container">
        <Header footerRef={footerRef} />
      </div>

      <div className="shoblog_container container-lg">
        <div className="shoblogHead col-lg-11 mx-auto">
          <div className="blogprofileinfo">
            <img
              src={triosoftsmall}
              alt="Profile"
              className="blogdetails_icon_user"
            />
            <div className="blogprofileinfoText">
              <h5>{VacancytData.author}</h5>
              <div className="Post_date">
                <p>Posted on {DateFormatdate(VacancytData.entry_date)}</p>
              </div>
            </div>
          </div>
          <div className="blogsocialicons">
            <a href={CentertData.instagram_link}>
              <img src={Insta} alt="Instagram Icon" />
            </a>
            <a href={CentertData.linkdin_link}>
              <img src={Linked} alt="Linkedin Icon" />
            </a>
            <a href={CentertData.twiter_link}>
              <img src={twitter} alt="Twitter Icon" width="32" height="32" />
            </a>
          </div>
        </div>
        <div className="blogheading1 col-lg-10 mx-auto">
          <h3>{VacancytData.title_name}</h3>
        </div>
        <div className="blogImg11 col-12">
          <img src={VacancytData.image_name} alt="Header_Robot" />
        </div>

        <div className="Robo_content col-md-10 m-auto">
          {" "}
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(VacancytData.description),
            }}
          />{" "}
        </div>
        <div className="whattoread">
          <div className="blogupfooter col-xl-12">
            <h3>What to Read Next</h3>
          </div>

          <section className="blog blogSHowSEction">
            <div className="blog_section container-lg">
              <div className="card_container">
                <div className="row">
                  {BlogtData.map((data, index) => (
                    <div className="col-md-4 col-sm-5 mx-auto p-1" key={index}>
                      <Link
                        onClick={() =>
                          handleLinkClick(match_and_return_seo_link(data.b_id))
                        }
                      >
                        <div className="card">
                          <div className="image">
                            <img src={data.image_name} alt="Card 1" />
                          </div>
                          <div className="post_dateTimes">
                            <p>
                              By <span>{data.author}</span>{" "}
                              {DateFormatdate(data.entry_date)}
                            </p>
                          </div>
                          <div className="blogtext_container">
                            <h6 style={{ textAlign: "left" }} className="my-2">
                              {data.title_name}
                            </h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div ref={footerRef}>
      <Footer servicesRef={null} topRef={topRef} updateHomeData={null} />

      </div>
    </div>
  );
}

export default Blogdetail;
