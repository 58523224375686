import React, { useRef, useEffect, useState } from "react";
import "../Css/Career.css";
import Header from "./Header";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import {
  server_post_data,
  view_partial_emp_reg,
} from "../ServiceConnection/serviceconnection.js";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
import EmployResgistrationform from "./EmployResgistrationform.js";
import { useLocation } from "react-router-dom";
function EmployResgistration() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [VacancytData, setVacancytData] = useState([]);
  const [Centerloop, setCenterloop] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const handleFetchData = async () => {
    setShowLoader(true);
    var form_data = new FormData();
    form_data.append("current_url", "/" + currentUrl);
    await server_post_data(view_partial_emp_reg, form_data)
      .then((response) => response.json()) 
      .then((data_data) => {
        setShowLoader(false);
        console.log(data_data);
        setCenterloop(data_data.center_loop[0]);
        setVacancytData(data_data.vancancy_loop);
        setSEOloop(data_data.seo_loop);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const EmployResgistrationformRef = useRef(null);
  const scrollToForm = () => {
    if (EmployResgistrationform.current) {
      const testimonialsElement = EmployResgistrationform.current;
      const testimonialsOffset = testimonialsElement.offsetTop;
      const scrollToPosition =
        testimonialsOffset -
        6 * parseFloat(getComputedStyle(document.documentElement).fontSize);

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  };

  const footerRef = useRef(null);
  const topRef = useRef(null);

  return (
    <div className="career" ref={topRef}>
      <div className={showLoader ? "loading" : ""}></div>

      <div className="navStickyTop">
        <Header footerRef={footerRef} />
      </div>

      <section className="careerForm container-lg">
        <div className="careerForm_container">
          <div className="careerFormHead col-md-10 mx-auto">
            <p>Employee Registration</p>
          </div>
        </div>

        <div ref={EmployResgistrationformRef} className="formDetailsContainer">
          <EmployResgistrationform dynamic_id={37} />
        </div>
      </section>

      <div ref={footerRef}>
        <Footer servicesRef={null} topRef={topRef} updateHomeData={null} />
      </div>
    </div>
  );
}

export default EmployResgistration;
