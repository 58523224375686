import React, { useEffect, useState, useRef } from "react";
import "../Css/Portfolio.css";
import Header from "./Header";
import Footer from "./Footer";
import Slider from "react-slick";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import {
  server_post_data,
  case_studies_details_url,
} from "../ServiceConnection/serviceconnection.js";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
function Portfolio() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [data_front_image, setdata_front_image] = useState([]);
  const [data_test_image, setdata_test_image] = useState([]);
  const [VacancytData, setVacancytData] = useState([]);
  const [Testimonial, setTestimonial] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const handleFetchData = async () => {
    setShowLoader(true);

    var form_data = new FormData();
    form_data.append("current_url", "/" + currentUrl);
    await server_post_data(case_studies_details_url, form_data)
      .then((response) => response.json())
      .then((data_data) => {
        setShowLoader(false);
        setVacancytData(data_data.case_loop[0]);
        setdata_front_image(data_data.front_image);
        setdata_test_image(data_data.testimonial_image);
        setTestimonial(data_data.testimonial);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const footerRef = useRef(null);
  const topRef = useRef(null);

  return (
    <div className="company" ref={topRef}>
      <div className={showLoader ? "loading" : ""}></div>
      <div className="navStickyTop">
        <Header footerRef={footerRef} />
      </div>
      <div className="companyContainer">
        <div>
          <div
            className="portfolio_bg .hue-img "
            style={{
              backgroundImage: `url(${data_front_image}${VacancytData.front_img})`,
            }}
          >
            <div className="port_txt_bg">
              <h1
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(VacancytData.title_name),
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="container-lg">
            <div className=" row  ">
              <div className=" col-md-6">
                <div className="head_txt_btm">
                  <div>
                    <h4>About {VacancytData.title_name}</h4>
                  </div>
                  <div>
                    <p>{VacancytData.short_description}</p>
                  </div>
                </div>
              </div>
              <div className=" col-md-6 ct_bot">
                <div className="row ">
                  <div className="col-md-6">
                    <p>
                      <span className="color_heading">Industry</span>
                      <br />
                      <span className="color_black_study">
                        {VacancytData.lndustry_name}
                      </span>
                    </p>
                    <p>
                      <span className="color_heading">Business Type</span>
                      <br />
                      <span className="color_black_study">
                        {VacancytData.services_name}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-6 ">
                    <p>
                      <span className="color_heading">Services</span>
                      <br />
                      <span className="color_black_study">
                        {VacancytData.business_type}
                      </span>
                    </p>
                    <p>
                      <span className="color_heading">Build your idea</span>
                      <br />
                      <Link onClick={() => handleLinkClick("/contact-us")}>
                        <span className="color_first">Consult</span>{" "}
                        <span className="color_second">Our Expert →</span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-lg">
            <div className=" row ">
              <div className="col-md-6">
                <p className="color_heading_p">
                  {VacancytData.short_description_one}
                </p>
              </div>
              <div className="col-md-6">
                <p>{VacancytData.short_description_two}</p>
              </div>
            </div>
          </div>
          <div
            className="port_img_bg hue-img"
            style={{
              backgroundImage: `url(${data_front_image}${VacancytData.center_image})`,
            }}
          ></div>

          <div className="cht_bot_txt">
            <div className="txti_m_worxds">
              <div
                className="row"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(VacancytData.long_description),
                }}
              />
            </div>
          </div>
        </div>
        {Testimonial.length > 0 && (
          <div className="txti_m">
            <div
              className="frontBannner_container mb-0"
              style={{ height: "100%" }}
            >
              <Slider {...settings}>
                {Testimonial.map((data, index) => (
                  <div className={`slide slide--${index}`}>
                    <div>
                      <div className="slider_prt">
                        <div className="container-lg padding0">
                          <div className="row m-0">
                            <div className=" col-sm-3 mb-sm-0 mb-3">
                              <div className="img_tsts">
                                <img
                                  src={data_test_image + data.image}
                                  alt={data.c_name}
                                  className="image_width_100_per"
                                />
                              </div>
                            </div>
                            <div className=" col-sm-9 d-flex justify-content-center">
                              <div className="slider_txt d-flex justify-content-center">
                                <div className="d-flex justify-content-center">
                                  <div className="slider_dt d-flex justify-content-center flex-column">
                                    <h3 style={{ color: "#ffffff" }}>
                                      {data.c_name}
                                    </h3>
                                    <p style={{ color: "#ffffff" }}>
                                      {data.c_position}
                                    </p>
                                    <p style={{ color: "#ffffff" }}>
                                      {data.msg}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        )}
      </div>
      <div className="show_back_color">
        <Footer  topRef={topRef} ref={footerRef} updateHomeData={null}  servicesRef={null}/>

      </div>
    </div>
  );
}

export default Portfolio;
