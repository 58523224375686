import React, { useState, useEffect, useRef } from "react";
import "../Css/Contactus.css";
import { Link } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import Header from "./Header";
import Footer from "./Footer";
import ModelPopUp from "./ModelPopUp";

import {
  server_post_data,
  server_get_data,
  get_all_contries,
  contact_details_save_url,
} from "../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  getRandomSixLetterString,
  empty_form,
  handleAphabetsChange,
  handleEmailChange,
  handleLinkClick,
} from "../CommonJquery/CommonJquery.js";
import Loader from "./Loader.js";

let captch_check = "";

function Contactus() {
  const [countries, setCountries] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [inputFilled, setInputFilled] = useState(false);
  const footerRef = useRef(null);
  const topRef = useRef(null);

  useEffect(() => {
    setShowLoader(true);
    server_get_data(get_all_contries)
      .then((responsessss) => responsessss.json())
      .then((Response) => {
        console.log(Response);
        const countryNames = Response.map((country) => country.name.common);
        console.log(countryNames);
        setCountries(countryNames);
        setShowLoader(false);
        if (captch_check === "") {
          captch_check = getRandomSixLetterString();
        }
      })
      .catch((error) => {
        setShowLoader(false);
        console.log(error);
      });
  }, []);

  const [userInputCpt, setUserInputCpt] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      if (userInputCpt === captch_check) {
        setShowLoader(true);
        let fd_from = combiled_form_data(form_data, null);
        console.log(fd_from);
        await server_post_data(url_for_save, fd_from)
          .then((responsessss) => responsessss.json())
          .then((Response) => {
            console.log(Response);
            setShowLoader(false);
            const stringArray = Response.split("~@~");
            if (stringArray[0] === "1") {
              alert(stringArray[1]);
            } else {
              empty_form(form_data);
              captch_check = getRandomSixLetterString();
              call_model_function("title", stringArray[1]);
            }
          })
          .catch((error) => {
            setShowLoader(false);

            console.log(error);
          });
      } else {
        setIsCaptchaValid(false);
      }
    }
  };
  const call_model_function = async (title, description) => {
    setTitle(title);
    setDescription(description);
    setShowModel(true);
  };
  const handleCheckboxChange = (event) => {
    setInputFilled(event.target.checked);
  };

  const handleSubmit = () => {
    if (!inputFilled) {
      alert("Please agree to the Privacy Policy.");
    } else {
      handleSaveChangesdynamic("contactusForm", contact_details_save_url);
    }
  };

  return (
    <div className="contactus" ref={topRef}>
      {showLoader && <Loader />}
      <div className="navStickyTop">
        <Header footerRef={footerRef} />
      </div>
      <div className="fcontact_back">
        <div className="contactus_container">
          <div className="container-lg ">
            <div className="contactusForm_container col-xl-8 col-lg-10 col-md-11">
              <div className="contactusHead">
                <h3 className="center ">
                  <span className="text-white">
                    Talk With <span className="mid_heading">Our Experts!</span>
                  </span>
                </h3>
                <p className="center">
                  Fill up your details, our representative will reach you within
                  24 Hours.
                </p>
              </div>

              <form id="contactusForm">
                <div className="row">
                  <div className="contactusInput contactusInputName col-md-6">
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control trio_name trio_mendate black_bg_white_text"
                        id="name"
                        name="name"
                        minLength="3"
                        maxLength="75"
                        placeholder="Your Name*"
                        onInput={handleAphabetsChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="contactusInput col-md-6">
                    <div className="mb-3">
                      <input
                        type="email"
                        className="form-control trio_email trio_mendate black_bg_white_text"
                        id="email"
                        name="email"
                        maxLength="100"
                        placeholder="Email Address*"
                        onInput={handleEmailChange}
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="contactusInput col-md-6">
                    <div className="mb-3">
                      <label htmlFor="country" className="form-label"></label>
                      <select
                        className="form-select trio_mendate black_bg_white_text"
                        id="country"
                        name="country"
                      >
                        <option value="">Select Your Country*</option>
                        {countries.map((country, index) => (
                          <option
                            key={index}
                            value={country}
                            className="form-select"
                          >
                            {country}
                          </option>
                        ))}
                      </select>
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="contactusInput col-md-6">
                    <div className="mb-3">
                      <label
                        htmlFor="phone"
                        className="form-label black_bg_white_text"
                      ></label>
                      <PhoneInput
                        id="phone"
                        className=" trio_no"
                        name="phone"
                        defaultCountry="in"
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                </div>
                <div className="contactusInput mb-3">
                  <label htmlFor="message" className="form-label"></label>
                  <textarea
                    className="form-control black_bg_white_text trio_mendate"
                    style={{ backgroundColor: "transparent" }}
                    id="message"
                    name="message"
                    rows="5"
                    maxLength="100"
                    placeholder="Type Your Message Here"
                  ></textarea>
                  <span className="condition_error"></span>
                </div>
              </form>

              <div className="inputFormCareer captchadIV col-md-12">
                <div className="contactusInput ">
                  <div className="captchaText">
                    <label htmlFor="captcha">Enter Captcha:</label>

                    <h6 className="captch_select">{captch_check}</h6>
                  </div>
                  <input
                    type="text"
                    className="form-control black_bg_white_text trio_mendate"
                    id="captcha"
                    name="captcha"
                    required
                    onChange={(e) => setUserInputCpt(e.target.value)}
                  />
                  {isCaptchaValid ? (
                    <p></p>
                  ) : (
                    <p className="text-danger mt-1 text-center">
                      Invalid CAPTCHA .
                    </p>
                  )}
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="FooterCheckBxx">
                      <label>
                        <input
                          type="checkbox"
                          onChange={handleCheckboxChange}
                        />
                        <span style={{ margin: "0 4px" }}>
                          I agree to the
                          <Link
                            onClick={() => handleLinkClick("/Terms_Of_Use")}
                            style={{ margin: "0 3px" }}
                          >
                            Terms Of Use
                          </Link>
                          <Link
                            onClick={() => handleLinkClick("/Privacy_Policy")}
                          >
                            Privacy Policy
                          </Link>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sbmtbtnDiv">
                <button
                  className="primary_btn "
                  type="button"
                  onClick={handleSubmit}
                >
                  <p>Submit</p>
                  <span className="spancheckmaker">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.293 4.293a1 1 0 0 1 1.414 1.414l-7 7a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 10.586l6.293-6.293a1 1 0 0 1 1.414 0z" />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="What_happen_nxt_heading_div">
            <h4 className="What_happen_nxt_heading">
              {/* <span className="what_heading">What </span>
                <span className="happen_nxt_heading">Happens Next?</span> */}
              <span className="text-white what_heading ">
                What <span className="happen_nxt_heading">Happens Next?</span>
              </span>
            </h4>
          </div>
        </div>

        <div className="container-lg">
          <div className="whatsnext_container">
            <div className="whatsnext_container_wrapper">
              <div className="whatsnextTextwrapper row">
                <div className=" whatsnextText col-sm-5 mx-auto">
                  <div className="numbox ">
                    <p>01</p>
                  </div>
                  <p>Our team will contact you.</p>
                </div>
                <div className="whatsnextText col-sm-5 mx-auto">
                  <div className="numbox">
                    <p>02</p>
                  </div>
                  <p>Analyse your requirements and discuss on the scope.</p>
                </div>
                <div className="whatsnextText col-sm-5 mx-auto">
                  <div className="numbox">
                    <p>03</p>
                  </div>
                  <p>Interact about the process in detail.</p>
                </div>
                <div className="whatsnextText col-sm-5 mx-auto">
                  <div className="numbox">
                    <p>04</p>
                  </div>
                  <p>Project Commitment and Resource Allocation.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="emptyBook"></div>
        </div>
      </div>

      {showModel && <ModelPopUp title={title} dis={description} />}

      <button
        hidden
        id="model_click"
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modalasdad
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="dynamic-content">
                <h5>{title}</h5>
                <p>{description}</p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div ref={footerRef}>
        <Footer servicesRef={null} topRef={topRef} updateHomeData={null} />
      </div>
    </div>
  );
}

export default Contactus;
