import React, { useState, useEffect, useRef } from "react";
import "../Css/Casestudy.css";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import {
  server_post_data,
  case_studies_url,
} from "../ServiceConnection/serviceconnection.js";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
function Casestudy() {
  const [dataloop, setData] = useState([]);
  const [data_front_image, setdata_front_image] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [SEOloop, setSEOloop] = useState([]);
  const handleFetchData = async () => {
    setShowLoader(true);
    await server_post_data(case_studies_url, null)
      .then((response) => response.json())
      .then((data_data) => {
        setShowLoader(false);
        setData(data_data.case_loop);
        setdata_front_image(data_data.front_image);
        setSEOloop(data_data.seo_loop);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };
  useEffect(() => {
    handleFetchData();
  }, []);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/Casestudydetails/case/" + v_id;
    let data_seo_link = data_seo_link_final;

    const matchedItem = SEOloop.find(
      (data) => data_seo_link === data.call_function_name
    );

    if (matchedItem) {
      data_seo_link_final = matchedItem.pretty_function_name;
    }

    return data_seo_link_final;
  };

  const footerRef = useRef(null);
  const topRef = useRef(null);

  return (
    <div className="caseStudy" ref={topRef}>
      <div className={showLoader ? "loading" : ""}></div>
      <div className="navStickyTop">
        <Header footerRef={footerRef} />
      </div>
      <div className="caseStudy_container">
        <div className="container-lg">
          <div className="contactusHead">
            <h4>Case Studies</h4>
            <h3 className="center heading_casestudy">
              <span className="text-white">
                Optimize. <span className="mid_heading">Innovate</span>.
                Disrupt.
              </span>
            </h3>
            <div className="text-center">
              <h5 className="center heading_txt ">
                The success stories talk about our expertise in delivering{" "}
                <br /> technology-led breakthroughs in business.
              </h5>
            </div>
          </div>
          {dataloop.map((data, index) => (
            <div className="Casestudycard" key={index}>
              <div
                className={`row m-0 ${index % 2 === 0 ? "rowReverse1" : ""}`}
              >
                <div className="col-sm-6">
                  <div className="Casestudytxt d-flex justify-content-center align-items-start flex-column">
                    <div className="col-xl-3 col-md-4 col-6 mb-5">
                      <img
                        className="caselogo"
                        src={data_front_image + data.logo_img}
                        alt="My logo"
                      />
                    </div>
                    <div>
                      <div className="CasestudytxtCOnt">
                        <h3>{data.title_name}</h3>
                        <p>{data.short_description}</p>
                      </div>
                      <div>
                        <Link
                          onClick={() =>
                            handleLinkClick(
                              match_and_return_seo_link(data.cs_id)
                            )
                          }
                        >
                          <button className="primary_btn ">
                            <p>View Case Study</p>
                            <span className="right_arrow">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path
                                  d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="Casestudyimg">
                    <img
                      src={data_front_image + data.small_big_image}
                      data-aos={`${
                        index % 2 === 0 ? "fade-right" : "fade-left"
                      }`}
                      data-aos-delay="200"
                      data-aos-easing="ease-in-sine"
                      data-aos-duration="800"
                      alt={data.title_name}
                      className="caseCardImg width_image"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="casescard text-center">
            <h5 className="casescard_text col-xl-9 col-lg-10 col-md-11">
              Get on board with our full stack of developmental experience we
              have with Fintech, E-Commerce, Healthcare, Aviation and 20+ other
              industries and benefit yourself.
            </h5>
            <Link onClick={() => handleLinkClick("/contact-us")}>
              <button className="btn_H">talk to our Expert</button>
            </Link>
          </div>
        </div>
      </div>
      <div ref={footerRef}>
      <Footer servicesRef={null} topRef={topRef} updateHomeData={null} />

      </div>
    </div>
  );
}

export default Casestudy;
