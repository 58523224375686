const appauth_key = "cjR4Y3hGTHlpLzcwQ2pjUG9CNWFNUT09";
const local_server_link_sssss = "http://192.168.1.8/triosoft_backend/";
const local_server_link_ssss = "http://16.170.152.179/triosoft_backend/";
const local_server_link= "https://api.triosoft.ai/";

const get_all_contries = "https://restcountries.com/v3.1/all";

const local_server_link_react = local_server_link + "ReactMaster/";

const footer_data = local_server_link_react + "get_footer_data";
const subscribe_mail = local_server_link_react + "subscribe_mail_submit";
const job_vancancy_url = local_server_link_react + "job_vancancy";
const seo_data_url = local_server_link_react + "seo_data";
const job_vancancy_details_url =
  local_server_link_react + "job_vancancy_details";
const vancancy_details_save_url =
  local_server_link_react + "vancancy_details_save";
const contact_details_save_url =
  local_server_link_react + "contact_details_save";

const case_studies_url = local_server_link_react + "get_case_studies";
const case_studies_details_url =
  local_server_link_react + "case_studies_details";
const get_testimonial_list_url =
  local_server_link_react + "get_testimonial_list";
const get_blog_list_url = local_server_link_react + "get_blog_list";
const get_blog_details_url = local_server_link_react + "blog_details";
const front_blog_url = local_server_link_react + "front_blog";
const get_seo_data_url = local_server_link_react + "get_seo_data";
const view_partial_emp_reg = local_server_link_react + "view_partial_emp_reg";
const save_emp_reg = local_server_link_react + "save_emp_reg";
// Retrieving data
const server_post_data = async (url_for, form_data) => {
  const headers = {};
  if (form_data === null) {
    form_data = new FormData();
  }
  form_data.append("appauth_key", appauth_key);

  return fetch(url_for, {
    method: "POST",
    headers: headers,
    body: form_data, //convert data to JSON
  });
};

const server_get_data = async (url_for) => {
  const headers = {};
  return fetch(url_for, {
    method: "GET",
    headers: headers,
  });
};

export {
  appauth_key,
  footer_data,
  local_server_link,
  server_post_data,
  server_get_data,
  subscribe_mail,
  job_vancancy_url,
  seo_data_url,
  job_vancancy_details_url,
  vancancy_details_save_url,
  contact_details_save_url,
  get_all_contries,
  case_studies_url,
  case_studies_details_url,
  get_testimonial_list_url,
  get_blog_list_url,
  get_blog_details_url,
  front_blog_url,
  view_partial_emp_reg,
  get_seo_data_url,
  save_emp_reg
};
