import React from "react";

function Loader() {
  return (
    <div className="loading">
      <div class="loader-wrapper">
        <div class="blur">
          <div class="loader">
            <div class="background"></div>
          </div>
        </div>
        <div class="loader">
          <div class="background"></div>
          <svg
            width="177"
            height="102"
            viewBox="0 0 177 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 53.5C2.5 41.3684 6.62959 28.795 19.1488 19.1694C31.7795 9.45806 53.2656 2.5 88.5 2.5C123.734 2.5 145.22 9.45806 157.851 19.1694C170.37 28.795 174.5 41.3684 174.5 53.5C174.5 70.3828 168.735 81.6976 161.164 88.802C153.537 95.9601 143.913 99 136 99C121.884 99 112.335 91.9213 104.22 85.8534L104.136 85.7904C101.516 83.8308 98.9541 81.9153 96.4845 80.502C93.9848 79.0716 91.3231 78 88.5 78C85.6769 78 83.0152 79.0716 80.5155 80.502C78.0459 81.9153 75.4844 83.8308 72.8638 85.7904L72.7796 85.8534C64.6651 91.9213 55.1163 99 41 99C33.0872 99 23.4632 95.9601 15.8358 88.802C8.26547 81.6976 2.5 70.3828 2.5 53.5Z"
              stroke="white"
              stroke-opacity="0.5"
              stroke-width="5"
            />
          </svg>
        </div>
        <div class="image-wrapper">Where AI Shapes Reality</div>
      </div>
    </div>
  );
}

export default Loader;
