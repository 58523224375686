import React, { useState, useEffect, useRef } from "react";
import "../Css/Testimonials.css";
import Header from "./Header";
import Footer from "./Footer";
import {
  server_post_data,
  get_testimonial_list_url,
} from "../ServiceConnection/serviceconnection.js";
function Testimonials() {
  const [dataloop, setData] = useState([]);
  const [data_front_image, setdata_front_image] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const handleFetchData = async () => {
    setShowLoader(true);
    await server_post_data(get_testimonial_list_url, null)
      .then((response) => response.json())
      .then((data_data) => {
        setShowLoader(false);
        setData(data_data.testimonial);
        setdata_front_image(data_data.testimonial_image);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };
  useEffect(() => {
    handleFetchData();
  }, []);

  const footerRef = useRef(null);
  const topRef = useRef(null);
  return (
    <div ref={topRef}>
      <div className={showLoader ? "loading" : ""}></div>
      <div className="navStickyTop">
        <Header footerRef={footerRef} />
      </div>
      <div>
        <div className="testi_cont">
          <div className="container-lg">
            <div className="contactusHead">
              <h3 className="center heading_casestudy">
                <span className="text-white">Testimonials</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        {dataloop.map((data, index) => (
          <div key={index}>
            <div className="bx_pd">
              <div className=" crds_tsti col_pd">
                <div className="tsti_card">
                  <div className="row m-0">
                    <div className=" col-md-3 col-sm-4 mx-auto col_pd">
                      <div className="tsti_image">
                        <img
                          src={data_front_image + data.image}
                          alt="My logo"
                          className="tsti_img"
                        />
                      </div>
                    </div>
                    <div className="col-md-8 col-sm-8 col_pd tsti_colm">
                      <div className="testi_txt">
                        <div>
                          {/* <div className="col-xl-3 col-md-4 col-6 mb-3">
                                <p>{index + 1}</p>
                              </div> */}
                          <div className="text_contnt">
                            <div>
                              <h3>{data.c_name}</h3>
                              <h6>Director & CEO</h6>
                            </div>
                            <p className="txt_h">
                              {/* <span className="doubleQuote">"</span>&nbsp; */}
                              "{data.msg}"&nbsp;
                              {/* <span className="doubleQuote">"</span> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div ref={footerRef}>
        <Footer servicesRef={null} topRef={topRef} updateHomeData={null} />
      </div>
    </div>
  );
}

export default Testimonials;
