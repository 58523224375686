import React, { useState, useEffect, useRef } from "react";
import "../Css/Footer.css";
import "../Css/Contactus.css"
import "../Css/Home.css";
import { BrowserRouter, Route } from 'react-router-dom';
import trilogonew from "../assets/images/revisedlogoooooo.png";
import { Link } from "react-router-dom";
import Youtube from "../assets/images/S1.png";
import Insta from "../assets/images/S2.png";
import LinkedIn from "../assets/images/S3.png";
import facebook from "../assets/images/fb.png";
import Whatsapp from "../assets/images/whats2.svg";
import Twitter from "../assets/images/twitterXXXXX.png";
import Skype from "../assets/images/S5.png";
import Enter from "../assets/images/enterGrey.svg";
import Phone from "../assets/images/phone-call.svg";
import Adress from "../assets/images/addresssssss.png";
import Email from "../assets/images/emailwhite.svg";
import CertiLogo1 from "../assets/images/ambitionBoxpmg.png";
import CertiLogo2 from "../assets/images/glassDoorPNg.png";
import CertiLogo3 from "../assets/images/gOOgleLogo.svg";
import Star from "../assets/images/star.png";
import ArrowCircle from "../assets/images/arrowRound.png";
import ScrollUp from "../assets/images/scrollTOp.png";
import {
  footer_data,
  server_post_data,
  subscribe_mail,
} from "../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleLinkClick,
} from "../CommonJquery/CommonJquery.js";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate , useLocation } from "react-router-dom";
import ModelPopUp from "./ModelPopUp";

//--------------------//

import "../Css/Footer.css";
import { Modal, Button } from "react-bootstrap";
import posthog from "posthog-js";
import Cookies from "js-cookie";
import crossIcn from "../assets/images/vectorCrossicon.svg";
import righIcn from "../assets/images/VectorRighy.svg";
import imgdropdown from "../assets/images/dropdown_white.png"
import imgDrp from "../assets/images/dropdown-svgrepo-com.svg";
import { ReactComponent as UpArrow } from "../assets/images/right-arrow-svgrepo-com.svg";
import { ReactComponent as DownArrow } from "../assets/images/down-arrow-svgrepo-com.svg";
import "../Css/HomePageCss.css";
import PrivacyPolicy from "../Components/PrivacyPolicy.js";

function Footer({ servicesRef, topRef, updateHomeData }) {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(" ");
  const [dataloop, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const handleFetchData = async () => {
    await server_post_data(footer_data, null)
      .then((response) => response.json())
      .then((data_data) => {
        setData(data_data.data_loop[0]);
        console.log(data_data.data_loop[0]);
      })
      .catch((error) => {});
  };

  const location = useLocation(); 
  const isPrivacyPolicyPage = location.pathname === "/Privacy_Policy";

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setErrorMsg(" ");
      setShowLoader(true);
      let fd_from = combiled_form_data(form_data, null);
      await server_post_data(url_for_save, fd_from)
        .then((responsessss) => responsessss.json())
        .then((Response) => {
          setShowLoader(false);
          const stringArray = Response.split("~@~");
          if (stringArray[0] === "1") {
            alert(stringArray[1]);
          } else {
            empty_form(form_data);
            call_model_function("titel", stringArray[1]);
          }
        })
        .catch((error) => {
          setShowLoader(false);
          console.log(error);
        });
    } else {
      setErrorMsg("Please enter an email address");
    }
  };
  const call_model_function = async (title, description) => {
    setTitle(title);
    setDescription(description);
    setShowModel(true);
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  function sendMessageToWhatsApp(mobile_no) {
    const message = "Hello, How may i help You!"; // Replace with your desired message
    // Create a WhatsApp URL with the phone number and message
    const whatsappURL = `https://wa.me/${mobile_no}?text=${encodeURIComponent(
      message
    )}`;

    // Open the WhatsApp chat in a new tab or window
    window.open(whatsappURL, "_blank");
  }

  const servicesDivRef = servicesRef;
  const scrollToForm = () => {
    if (servicesDivRef.current) {
      const testimonialsElement = servicesDivRef.current;
      const testimonialsOffset = testimonialsElement.offsetTop;
      const scrollToPosition =
        testimonialsOffset -
        6 * parseFloat(getComputedStyle(document.documentElement).fontSize);

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  };

  const topRefDiv = topRef;
  const ScrollToTop = () => {
    if (topRefDiv.current) {
      const testimonialsElement = topRefDiv.current;
      const testimonialsOffset = testimonialsElement.offsetTop;
      const scrollToPosition =
        testimonialsOffset -
        6 * parseFloat(getComputedStyle(document.documentElement).fontSize);

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  };

  const handleclick = (newData) => {
    // Navigate to the home page
    navigate("/");
    setTimeout(() => {
      document.getElementById("service_kaam").scrollIntoView({
        behavior: "smooth",
      });
      try {
        updateHomeData(newData); // Call updateHomeData function passed via props
      } catch (err) {
        //err
      }
    }, 3);
  };

  const [privacyPolicyModalOpen, setPrivacyPolicyModalOpen] = useState(false);
  const [cookieModalOpen, setCookieModalOpen] = useState(true);

  const showPrivacyPolicyModal = () => {
    setPrivacyPolicyModalOpen(true);
    setCookieModalOpen(false);
  };

  const closeModal = () => {
    setPrivacyPolicyModalOpen(false);
    setCookieModalOpen(false);
  };

  const [showSecondModal, setShowSecondModal] = useState(false);

  const [cook1Enabled, setCook1Enabled] = useState(false);
  const [cook2Enabled, setCook2Enabled] = useState(false);
  const [cook3Enabled, setCook3Enabled] = useState(false);
  const [cook4Enabled, setCook4Enabled] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [showPrivacy, setshowPrivacy] = useState(false);
  const [inputFilled, setInputFilled] = useState(false);

  const handlePrivacy = () => {
    setshowPrivacy(true);
  };

  const handlePrivacyClose = () => {
    setshowPrivacy(false);
  };

  const send_null_value = "true";

  // Storing data
  const storeData = (key, value) => {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      // Handle the error
      console.log(error);
    }
  };

  // Retrieving data
  // const retrieveData = (key) => {
  //   try {
  //     const value = localStorage.getItem(key);
  //     if (value !== null) {
  //       return value;
  //     }
  //     return send_null_value;
  //   } catch (error) {
  //     return send_null_value;
  //   }
  // };
  // const retrive_data = Boolean(retrieveData("cookie_save"));
  const [showCookies, setShowCookies] = useState(true);
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    loadCookieSettings();
  }, []);
  const loadCookieSettings = () => {
    const savedSettings = JSON.parse(localStorage.getItem("cookieSettings"));
    if (savedSettings) {
      setCook1Enabled(savedSettings.cook1Enabled);
      setCook2Enabled(savedSettings.cook2Enabled);
      setCook3Enabled(savedSettings.cook3Enabled);
      setCook4Enabled(savedSettings.cook4Enabled);
      setShowModal(false); // Modal will not be shown if settings are saved
    }
  };

  //model buttons
  const handleAccept = () => {
    storeData("cookie_save", "false");
    setShowModal(false);
  };

  const handleManage = () => {
    setShowSecondModal(true);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowSecondModal(false);
    setShowModal(false);
  };

  const handlesavesetting = () => {
    setShowSecondModal(false);
    setShowModal(false);

    const cookieSettings = {
      cook1Enabled,
      cook2Enabled,
      cook3Enabled,
      cook4Enabled,
    };

    localStorage.setItem("cookieSettings", JSON.stringify(cookieSettings));
  };

  const calculateEnabledTogglesCount = () => {
    return [cook1Enabled, cook2Enabled, cook3Enabled, cook4Enabled].filter(
      Boolean
    ).length;
  };
  const handleClick = () => {
    setShowCookies(!showCookies);
    const enabledTogglesCount = calculateEnabledTogglesCount();
    console.log(`Allowed: ${enabledTogglesCount} of 4`);
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const toggleAccordion2 = () => {
    setIsOpen2(!isOpen2);
  };
  const toggleAccordion3 = () => {
    setIsOpen3(!isOpen3);
  };
  const toggleAccordion4 = () => {
    setIsOpen4(!isOpen4);
  };
  //toggle
  const handleCook1Toggle = () => {
    setCook1Enabled(!cook1Enabled);
  };

  const handleCook2Toggle = () => {
    setCook2Enabled(!cook2Enabled);
  };

  const handleCook3Toggle = () => {
    setCook3Enabled(!cook3Enabled);
  };

  const handleCook4Toggle = () => {
    setCook4Enabled(!cook4Enabled);
  };

  //cookie Accept
  const acceptCookies = () => {
    posthog.opt_in_capturing();
    setShowSecondModal(false);
    setShowModal(false);

    const cookieSettings = {
      cook1Enabled: true,
      cook2Enabled: true,
      cook3Enabled: true,
      cook4Enabled: true,
    };

    // Save cookie settings to local storage
    localStorage.setItem("cookieSettings", JSON.stringify(cookieSettings));
  };

  const declineCookies = () => {
    posthog.opt_out_capturing();
    setShowBanner(false);
  };

  //footer email valiation privacy policy

  const handleCheckboxChange = (event) => {
    setInputFilled(event.target.checked);
  };

  const handleSubmit = () => {
    if (!inputFilled) {
      alert("Please agree to the Privacy Policy.");
    } else {
      handleSaveChangesdynamic("updatesucribemail", subscribe_mail);
    }
  };

  return (
    <>
      <div className="footer" id="footer" style={{ position: "relative" }}>
        <div class="footer-container">
          <Link to="/contact-us">
            <div class="circle"></div>
            <div className="arrrowCircle">
              <img src={ArrowCircle} alt="icon" />
            </div>
          </Link>
          <div class="thing">
            <div className="radiusDiv1"></div>
            <div className="radiusDiv2"></div>
          </div>
        </div>
        <div className={showLoader ? "loading" : ""}></div>
        <div className="footer_container">
          <div className="footer_wrapper container-lg">
            <a href="/">
              <div className="FlogoDiv">
                <img src={trilogonew} alt="Logo" style={{ width: "20%" }} />
              </div>
            </a>
            <div className="row m-0">
              <div className="col-md-6 col-sm-7 mb-3">
                <div className="footerlistContainer">
                  <div className="row m-0">
                    <div className="col-lg-6 col-sm-5 col-5">
                      <div className="compnyDiv">
                        <div className="compnyDivHead">
                          <h5>Company</h5>
                          <ul>
                            <li>
                              <Link to="/">
                                <p>Who We Are</p>
                              </Link>
                            </li>
                            {/* <li>
                            <Link onClick={() => handleLinkClick("/Insights")}>
                              <p>Blogs</p>
                            </Link>
                          </li> */}
                            <li>
                              <Link
                                onClick={() => handleLinkClick("/Casestudy")}
                              >
                                <p>Case Studies</p>
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() => handleLinkClick("/Insights")}
                              >
                                <p>Insights</p>
                              </Link>
                            </li>
                            <li>
                              <Link onClick={() => handleLinkClick("/Career")}>
                                <p>Career</p>
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() => handleLinkClick("/Terms_Of_Use")}
                              >
                                <p>Terms Of Use</p>
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={() =>
                                  handleLinkClick("/Privacy_Policy")
                                }
                              >
                                <p>Privacy Policy</p>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-7 col-7">
                      <div className="compnyDiv">
                        <div className="compnyDivHead">
                          <h5>Services</h5>
                          <ul>
                            <li>
                              <ScrollLink
                                to="service_kaam"
                                className="cursor_pointer"
                                smooth={true}
                                duration={200}
                                offset={-window.innerHeight / 18}
                                onClick={() => handleclick("item1")}
                              >
                                <p>
                                  Artificial Intelligence & Machine Learning
                                  Solutions
                                </p>
                              </ScrollLink>
                            </li>
                            <li>
                              <ScrollLink
                                to="service_kaam"
                                className="cursor_pointer"
                                smooth={true}
                                duration={200}
                                offset={-window.innerHeight / 18}
                                onClick={() => handleclick("item2")}
                              >
                                <p>Metaverse Development</p>
                              </ScrollLink>
                            </li>
                            <li>
                              <ScrollLink
                                to="service_kaam"
                                className="cursor_pointer"
                                smooth={true}
                                duration={200}
                                offset={-window.innerHeight / 18}
                                onClick={() => handleclick("item3")}
                              >
                                <p>Smart AI Assistant (Voice & Chat Bot)</p>
                              </ScrollLink>
                            </li>
                            <li>
                              <ScrollLink
                                to="service_kaam"
                                className="cursor_pointer"
                                smooth={true}
                                duration={200}
                                offset={-window.innerHeight / 18}
                                onClick={() => handleclick("item4")}
                              >
                                <p>SaaS & PaaS Product Development</p>
                              </ScrollLink>
                            </li>
                            <li>
                              <ScrollLink
                                to="service_kaam"
                                className="cursor_pointer"
                                smooth={true}
                                duration={200}
                                offset={-window.innerHeight / 18}
                                onClick={() => handleclick("item5")}
                              >
                                <p>Custom Software Development</p>
                              </ScrollLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footerIconsSocial">
                  <div className="col-lg-8 col-sm-10 col-11">
                    <ul>
                      <li>
                        <a href={dataloop.youtube_link} target="_blank">
                          <img src={Youtube} alt="Youtube" />
                        </a>
                      </li>
                      <li>
                        <a href={dataloop.instagram_link} target="_blank">
                          <img src={Insta} alt="Instagram" />
                        </a>
                      </li>
                      <li>
                        <a href={dataloop.linkdin_link} target="_blank">
                          <img src={LinkedIn} alt="LinkedIn" />
                        </a>
                      </li>
                      <li>
                        <a href={dataloop.facebook_link} target="_blank">
                          <img src={facebook} alt="facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            sendMessageToWhatsApp(dataloop.mobile_link)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img src={Whatsapp} alt="Whatsapp" />
                        </a>
                      </li>
                      <li>
                        <a href={dataloop.twiter_link} target="_blank">
                          <img src={Twitter} alt="Twitter" />
                        </a>
                      </li>
                      <li>
                        <a href={dataloop.skype_link}>
                          <img src={Skype} alt="Skype" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-1"></div>
              <div className="col-sm-5">
                <div className="footerRight">
                  <div className="newsLetterDiv">
                    <h5>Stay In The Know</h5>
                    <form id="updatesucribemail">
                      <div className="newsLttrInput">
                        <input
                          type="text"
                          placeholder="Email Address"
                          className="trio_mendate trio_email"
                          id="subscribe_email_address"
                          name="subscribe_email_address"
                          onInput={(e) => {
                            if (
                              !/^[a-zA-Z0-9@.]*$/.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              e.target.value = "";
                            }
                          }}
                        />
                        <img
                          className="enterImg"
                          src={Enter}
                          alt="Icon"
                          onClick={handleSubmit}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="FooterCheckBxx ffttrBoxx">
                        <label>
                          <input
                            type="checkbox"
                            onChange={handleCheckboxChange}
                          />
                          <span>
                            By subscribing, you're agreeing to our
                            <Link
                              onClick={() => handleLinkClick("/Privacy_Policy")}
                              style={{ margin: "0 3px" }}
                            >
                              Privacy Policy.
                            </Link>
                          </span>
                        </label>
                      </div>

                      {errorMsg && (
                        <p
                          style={{
                            color: "red",
                            paddingLeft: "1rem",
                            fontSize: "15px",
                          }}
                        >
                          {errorMsg}
                        </p>
                      )}
                    </form>
                  </div>
                  <div className="certifications">
                    <div className="certificationsContianer">
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.glassdoor.co.in/Overview/Working-at-Triosoft-Technologies-EI_IE2507624.11,32.htm"
                          >
                            <img
                              className="certiimgTag "
                              src={CertiLogo2}
                              alt="Certificates"
                            />
                            <div className="ratingsDic ">
                              <div className="container m-0 p-0">
                                <div class="rating">
                                  <input 
                                    type="radio"
                                    name="rating"
                                    value="5"
                                    id="5"
                                  ></input>
                                  <label for="5">☆</label>
                                  <input
                                    type="radio"
                                    name="rating"
                                    value="4"
                                    id="4"
                                  ></input>
                                  <label for="4">☆</label>
                                  <input
                                    type="radio"
                                    name="rating"
                                    value="3"
                                    id="3"
                                  ></input>
                                  <label for="3">☆</label>
                                  <input
                                    type="radio"
                                    name="rating"
                                    value="2"
                                    id="2"
                                  ></input>
                                  <label for="2">☆</label>
                                  <input
                                    type="radio"
                                    name="rating"
                                    value="1"
                                    id="1"
                                  ></input>
                                  <label className="half" for="1">☆</label>
                                  <p style={{ color: "#ffffff" }}>
                                    {dataloop.glassdoor_rating}
                                  </p>
                                </div>
                              </div>
                              {/* <p style={{ color: "#ffffff" }}>
                                {dataloop.google_rating}
                              </p> */}
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.ambitionbox.com/reviews/triosoft-technologies-reviews"
                          >
                            <div className="abmiBoxImg">
                              <img
                                className="certiimgTag "
                                src={CertiLogo1}
                                alt="Certificates"
                              />
                            </div>

                            <div className="ratingsDic  ">
                              <div className="container m-0 p-0">
                                <div class="rating abmiBoxImg2">
                                  <input
                                    type="radio"
                                    name="rating"
                                    value="5"
                                    id="5"
                                  ></input>
                                  <label for="5">☆</label>
                                  <input
                                    type="radio"
                                    name="rating"
                                    value="4"
                                    id="4"
                                  ></input>
                                  <label for="4">☆</label>
                                  <input
                                    type="radio"
                                    name="rating"
                                    value="3"
                                    id="3"
                                  ></input>
                                  <label for="3">☆</label>
                                  <input
                                    type="radio"
                                    name="rating"
                                    value="2"
                                    id="2"
                                  ></input>
                                  <label for="2">☆</label>
                                  <input
                                    type="radio"
                                    name="rating"
                                    value="1"
                                    id="1"
                                  ></input>
                                  <label className="half" for="1">☆</label>
                                  <p style={{ color: "#ffffff" }}>
                                    {dataloop.ambitionbox_rating}
                                  </p>
                                </div>
                              </div>
                              {/* <p style={{ color: "#ffffff" }}>
                                {dataloop.google_rating}
                              </p> */}
                            </div>
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.google.com/search?gs_ssp=eJzj4tVP1zc0TDOuykhOLzE2YLRSNagwtjRPNjFKSU1KMrBMS01LsjKoSEtJMzAyskg2NEk2Tks1NfbiKCnKzC_OTysBAGGoE5M&q=triosoft&oq=trio&gs_lcrp=EgZjaHJvbWUqDQgDEC4YrwEYxwEYgAQyBggAEEUYOTIGCAEQRRg8MgYIAhBFGDwyDQgDEC4YrwEYxwEYgAQyBggEEEUYPDIGCAUQRRg9MgYIBhBFGD0yBggHEEUYPNIBCTEwNzY3ajBqNKgCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x397c42debb09fefb:0xfdf0228c14c3fe53,1,,,,"
                          >
                            <img
                              className="certiimgTag ggle"
                              src={CertiLogo3}
                              alt="Certificates"
                            />
                            <div className="ratingsDic ">
                              <div className="container m-0 p-0">
                                <div class="rating">
                                  <input
                                    type="radio"
                                    name="rating"
                                    value="5"
                                    id="5"
                                  ></input>
                                  <label for="5">☆</label>
                                  <input
                                    type="radio"
                                    name="rating"
                                    value="4"
                                    id="4"
                                  ></input>
                                  <label for="4">☆</label>
                                  <input
                                    type="radio"
                                    name="rating"
                                    value="3"
                                    id="3"
                                  ></input>
                                  <label for="3">☆</label>
                                  <input
                                    type="radio"
                                    name="rating"
                                    value="2"
                                    id="2"
                                  ></input>
                                  <label for="2">☆</label>
                                  <input
                                    type="radio"
                                    name="rating"
                                    value="1"
                                    id="1"
                                  ></input>
                                  <label className="half" for="1">☆</label>
                                  <p style={{ color: "#ffffff" }}>
                                    {dataloop.google_rating}
                                  </p>
                                </div>
                              </div>
                              {/* <p style={{ color: "#ffffff" }}>
                                {dataloop.google_rating}
                              </p> */}
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="contACTcONTAIENR">
                      <h5>Contact us</h5>
                      <ul>
                        <li>
                          <div className="contactDetail">
                            <img src={Email} alt="triosoft ai" />
                            <p>{dataloop.email_link}</p>
                          </div>
                        </li>
                        <li>
                          <div className="contactDetail">
                            <img src={Phone} alt="triosoft ai" />
                            <p>{dataloop.mobile_link}</p>
                          </div>
                        </li>
                        <li>
                          <div className="contactDetail">
                            <img
                              style={{ height: "1.6rem", width: "1.8rem" }}
                              src={Adress}
                              alt="triosoft ai"
                            />
                            <p>{dataloop.office_address}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showModel && <ModelPopUp title={title} dis={description} />}
        <div className="cache_tag_fttr">
          <p>
            {" "}
            <a>
              Copyright &copy; 2024. Triosoft Technologies All rights reserved.{" "}
            </a>
            {/* <a href="/terms-of-use">Terms of Use</a> |{" "}
          <a href="/cookie-policy">Cookie Policy</a> |{" "}
          <a href="/privacy-policy">Privacy Policy</a> |{" "}
          <a href="/sitemap">Sitemap</a> */}
          </p>
        </div>

        <div
          className="scrollDown scrollUp"
          id="scrollDown"
          onClick={ScrollToTop}
        >
          <img src={ScrollUp} alt="icon" />
        </div>
      </div>
      
      {!isPrivacyPolicyPage && (
      <Modal
        className="HomeeModel custom-modal"
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>We value your Privacy</Modal.Title>
          <div className="d-flex">
            <Button className="cookie_btn" variant="primary openBttn" onClick={acceptCookies}>
              Accept
            </Button>
            <Button className="cookie_btn ms-2" variant="secondary mngeBttn" onClick={handleManage}>
              Manage
            </Button>
          </div>
        </Modal.Header>
        <div className="beforrrIcn">
          
          {" "}
          <Modal.Body>
            <p>
              We and our trusted partners use cookies to improve your browsing
              experience, enhance site security, and tailor ads to your
              interests across other sites. See our{" "}
              
              <span  onClick={() => handleLinkClick("/Privacy_Policy")} style={{ cursor: "pointer" }}>
                Privacy and Cookies Statement for details.
              </span>
            </p>
          </Modal.Body>
        </div>

        <Modal.Footer>
          <div className="">
            <Button variant="secondary" onClick={handleClose}>
              Continue Without Accepting
              <div className="RightArrww">
                {" "}
                {/* <img className="" src={crossIcn} alt="img"></img> */}
              </div>
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
       )}

      <Modal
        className="HomeeModel2"
        centered
        show={showSecondModal}
        onHide={handleClose}
      >
        <div className="container modal_2">
          <Modal.Header>
            <Modal.Title className="modal2_text">Manage Your Privacy Settings</Modal.Title>
            <div className="d-flex justify-content-between align-items-center openClssBtn">
              <p 
                // onClick={handlePrivacy}
                onClick={() => handleLinkClick("/Privacy_Policy")}
                style={{ 
                  backgroundImage: "linear-gradient(90deg, #eb6a30 2.27%, #e547e7 94.51%)",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  color: "transparent",
                  cursor: "pointer"
                }}
              >
                Privacy Policy
              </p>
            </div>
          </Modal.Header>
          <Modal.Body>
            <p>
              We want to be transparent about the data we and our partners
              collect and how we use it, so you can best exercise control over
              your personal data. For more information, please see our
              <span
                onClick={() => handleLinkClick("/Privacy_Policy")}

                style={{ color: "#163b8a", cursor: "pointer" }}
              >
                Privacy Policy
              </span>
            </p>
            <p>
              We use this information to improve the performance and experience
              of our site visitors. This includes improving search results,
              showing more relevant content and promotional materials, better
              communication, and improved site performance
            </p>
            <div className="cook">
              <div className="cookieesss">
                <button className="cookbbuttn" onClick={handleClick}>
                  Cookies
                  <img className="drppIcnn" src={imgdropdown} alt="imgDrp"></img>
                </button>

                <p>
                  <p>{calculateEnabledTogglesCount()} of 1 Allowed</p>
                </p>
              </div>
              {showCookies && (
                <div className="cookboxx">
                  {/* <div className="cookieCont">
                    <div className="cook1">
                      <button
                        className={`accordion-button ${
                          isOpen ? "rotate180" : ""
                        }`}
                        onClick={toggleAccordion}
                      >
                        {isOpen ? (
                          <DownArrow className="rotated rttedImgRight" />
                        ) : (
                          <UpArrow className="rotated rttedImgRight" />
                        )}
                        Strictly Necessary Cookies
                      </button>
                    </div>
                    <div className="OnOFtoggl">
                      <div className="tggle">
                        <div className="crssImgClss">
                          <img src={righIcn} alt="img2"></img>
                        </div>
                        <div className="righImgClss">
                          <img src={crossIcn} alt="img"></img>
                        </div>
                        <input
                          type="checkbox"
                          id="switch1"
                          checked={cook1Enabled}
                          onChange={handleCook1Toggle}
                        />
                        <label htmlFor="switch1">Toggle</label>
                      </div>
                    </div>
                  </div> */}

                  {/* {isOpen && (
                    <div className="cook1-content">
                      <p>We use these cookies to help us understand how the website is being used and how we can improve your experience of it. These cookies can provide us with information to help us understand which parts of the website interest our visitors and if they experience any errors. We use these cookies to test different designs and features for our websites and we also use them to help us monitor how visitors reach the website.</p>
                    </div>
                  )} */}
                  {/* <div className="cookieCont">
                    <div className="cook2">
                      <button
                        className={`accordion-button ${
                          isOpen ? "rotate180" : ""
                        }`}
                        onClick={toggleAccordion2}
                      >
                        {isOpen2 ? (
                          <DownArrow className="rotated rttedImgRight" />
                        ) : (
                          <UpArrow className="rotated rttedImgRight" />
                        )}
                        Functional Cookies
                      </button>
                    </div>
                    <div className="OnOFtoggl">
                      <div className="tggle">
                        <div className="crssImgClss">
                          <img src={righIcn} alt="img2"></img>
                        </div>
                        <div className="righImgClss">
                          <img src={crossIcn} alt="img"></img>
                        </div>
                        <input
                          type="checkbox"
                          id="switch2"
                          checked={cook2Enabled}
                          onChange={handleCook2Toggle}
                        />
                        <label htmlFor="switch2">Toggle</label>
                      </div>
                    </div>
                  </div>

                  {isOpen2 && (
                    <div className="cook1-content">
                      <p>We use these cookies to help us understand how the website is being used and how we can improve your experience of it. These cookies can provide us with information to help us understand which parts of the website interest our visitors and if they experience any errors. We use these cookies to test different designs and features for our websites and we also use them to help us monitor how visitors reach the website.</p>
                    </div>
                  )} */}
                  <div className="cookieCont">
                    <div className="cook3">
                      <button
                        className={`accordion-button ${
                          isOpen ? "rotate180" : ""
                        }`}
                        onClick={toggleAccordion3}
                      >
                        {isOpen3 ? (
                          <DownArrow className="rotated rttedImgRight" />
                        ) : (
                          <UpArrow className="rotated rttedImgRight" />
                        )}
                        Analytics Cookies
                      </button>
                    </div>
                    <div className="OnOFtoggl">
                      <div className="tggle">
                        <div className="crssImgClss">
                          <img src={righIcn} alt="img2"></img>
                        </div>
                        <div className="righImgClss">
                          <img src={crossIcn} alt="img"></img>
                        </div>

                        <input
                          type="checkbox"
                          id="switch3"
                          checked={cook3Enabled}
                          onChange={handleCook3Toggle}
                        />
                        <label htmlFor="switch3">Toggle</label>
                      </div>
                    </div>
                  </div>

                  {isOpen3 && (
                    <div className="cook1-content">
                      <p>We use these cookies to help us understand how the website is being used and how we can improve your experience of it. These cookies can provide us with information to help us understand which parts of the website interest our visitors and if they experience any errors. We use these cookies to test different designs and features for our websites and marketing to specific groups of people. We also use them to help us monitor how visitors reach the website.
                   For more details please visit our
                   <span
                onClick={() => handleLinkClick("/Privacy_Policy")}

                style={{ color: "#163b8a", cursor: "pointer" }}
              >
                Privacy Policy
              </span>
                   </p>
                  

                    </div>
                    
                  )}
                  {/* <div className="cookieCont">
                    <div className="cook4">
                      <button
                        className={`accordion-button ${
                          isOpen ? "rotate180" : ""
                        }`}
                        onClick={toggleAccordion4}
                      >
                        {isOpen4 ? (
                          <DownArrow className="rotated rttedImgRight" />
                        ) : (
                          <UpArrow className="rotated rttedImgRight" />
                        )}
                        Marketing Cookies
                      </button>
                    </div>
                    <div className="OnOFtoggl">
                      {" "}
                      <div className="tggle">
                        <div className="crssImgClss">
                          <img src={righIcn} alt="img2"></img>
                        </div>
                        <div className="righImgClss">
                          <img src={crossIcn} alt="img"></img>
                        </div>

                        <input
                          type="checkbox"
                          id="switch4"
                          checked={cook4Enabled}
                          onChange={handleCook4Toggle}
                        />

                        <label htmlFor="switch4">Toggle</label>
                      </div>
                    </div>
                  </div>

                  {isOpen4 && (
                    <div className="cook1-content">
                      <p>We use these cookies to help us understand how the website is being used and how we can improve your experience of it. These cookies can provide us with information to help us understand which parts of the website interest our visitors and if they experience any errors. We use these cookies to test different designs and features for our websites and we also use them to help us monitor how visitors reach the website.</p>
                    </div>
                  )} */}
                </div>
              )}
            </div>
          </Modal.Body>
          <div className="fottrBtnModl">
            <Modal.Footer>
              {showBanner && (
                <div className="leftFottrBtn">
                  <div className="cookie_btn">
                    <button className="modal_2" onClick={acceptCookies}> Accept All</button>
                  </div>

                  <div className="cookie_btn">
                    {" "}
                    <button className="modal_2" onClick={declineCookies}> Reject All</button>
                  </div>
                </div>
              )}

              <Button className="primary_btn"
                variant="secondary sveSetting"
                onClick={handlesavesetting}
              >
                Save Settings
              </Button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
    
    </>
  );
}

export default Footer;
