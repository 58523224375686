import React, { useState, useEffect, useRef } from "react";
import UploadCVImg from "../assets/images/uplaodCV.svg";
import { Link } from "react-router-dom";
import "../Css/Career.css";
import {
  server_post_data,
  vancancy_details_save_url,
} from "../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  getRandomSixLetterString,
  empty_form,
  handleLinkClick,
} from "../CommonJquery/CommonJquery.js";
import ModelPopUp from "./ModelPopUp";
import Loader from "./Loader.js";
let captch_check = "";
let final_v_id = 0;
function CareerForm(data_for_id) {
  final_v_id = data_for_id.dynamic_id;
  const imageInputRef = useRef(null);
  const [showModel, setShowModel] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dynaicimage, setDynaicimage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [inputFilled, setInputFilled] = useState(false);
  const [isExperienced, setIsExperienced] = useState(false);
  const [file, setfile] = useState('');

  useEffect(() => {
    if (captch_check === "") {
      captch_check = getRandomSixLetterString();
    }
  }, []);

  const [userInputCpt, setUserInputCpt] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      if (userInputCpt === captch_check) {
        setShowLoader(true);
        let fd_from = combiled_form_data(form_data, dynaicimage);
        fd_from.append("final_v_id", final_v_id);
        await server_post_data(url_for_save, fd_from)
          .then((responsessss) => responsessss.json())
          .then((Response) => {
            console.log(Response);
            setShowLoader(false);
            const stringArray = Response.split("~@~");
            if (stringArray[0] === "1") {
              alert(stringArray[1]);
            } else {
              empty_form(form_data);
              captch_check = getRandomSixLetterString();
              call_model_function("title", stringArray[1]);
            }
          })
          .catch((error) => {
            setShowLoader(false);
            console.log(error.message);
          });
      } else {
        setIsCaptchaValid(false);
      }
    }
  };
  const call_model_function = async (title, description) => {
    setTitle(title);
    setDescription(description);
    setShowModel(true);
  };

  const [fileError, setFileError] = useState("");

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
          [keyname + "_name"]: file.name, // Add this line to store the file name
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: null,
        [new_file_name]: null,
        [keyname + "_name"]: null, // Add this line to reset the file name
      }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file.size)
    if (file && file.size > 1048576) {
      setFileError("File size exceeds 1MB limit.");
    } else {
      setFileError("");
      handleFileChangedynamic("profile_picture")(e);
    }
  };
  const handleCheckboxChange = (event) => {
    setInputFilled(event.target.checked);
  };

  const handleSubmit = () => {
    if (!inputFilled) {
      alert("Please agree to the Privacy Policy.");
    } else {
      handleSaveChangesdynamic("CarrerformData", vancancy_details_save_url);
    }
  };

  const handleExperienced = (e) => {
    console.log(e.target.defaultValue);
    setIsExperienced(e.target.value === "experienced");
  };

  return (
    <div className="careerFormInputs container-lg mt-5">
      {showLoader && <Loader />}
      <form id="CarrerformData" className="col-lg-10 m-auto">
        <div className="row">
          <div className="inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="name">
                <b>Full Name</b>
              </label>
              <input
                type="text"
                className="trio_mendate trio_name form-control "
                id="name"
                name="name"
                maxLength="50"
                placeholder="Enter Your First and Last Name"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(
                    /^\s+|[^A-Za-z\s]/g,
                    ""
                  );
                }}
              />
              <span className="condition_error"></span>
            </div>
          </div>
          <div className=" inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="email">
                <b>Email Address</b>
              </label>
              <input
                type="test"
                className="trio_mendate trio_email form-control "
                id="email"
                name="email"
                maxLength="100"
                placeholder="Enter Your Email Address"
                onInput={(e) => {
                  if (!/^\s+|[a-zA-Z0-9@.]*$/.test(e.target.value)) {
                    e.target.value = ""; // Clear the input if it contains any other character
                  }
                }}
              />
              <span className="condition_error"></span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="mobile">
                <b>Phone Number</b>
              </label>
              <input
                type="text"
                className="trio_mendate trio_no form-control "
                id="mobile"
                name="mobile"
                maxLength="10"
                placeholder="Enter Your Phone Number"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
              />
              <span className="condition_error"></span>
            </div>
          </div>
          <div className="inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="Experience">
                <b>Experience in Years</b>
              </label>
              <input
                type="text"
                className="trio_mendate form-control "
                id="experience"
                name="experience"
                maxLength="5"
                step={2}
                placeholder="Enter Your Work Experience in Years"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
                  e.target.value = e.target.value.replace(
                    /(\d{0,2}(?:\.\d{0,2})?).*$/g,
                    "$1"
                  ); // Limit to 3 digits before the decimal and 2 digits after
                }}
              />
              <span className="condition_error"></span>
            </div>
          </div>
        </div>

        <div className="inputFormCareer CVuPLOAD col-md-12">
          <div className="uploadTextImg">
            <img src={UploadCVImg} alt="upload photo" />
            <div className="uploadText">
              {!dynaicimage && (
                <>
                  <h6>UPload CV</h6>
                  <p>(Pdf, doc, PPT upto 1MB)</p>
                </>
              )}
              {dynaicimage && dynaicimage.profile_picture_show && (
                <h6>{dynaicimage.profile_picture_name} Uploaded</h6>
              )}
            </div>
          </div>
          <label htmlFor="profile_picture" style={{ cursor: "pointer" }}>
            Browse
          </label>
          <input
            className="d-none "
            type="file"
            name="profile_picture"
            id="profile_picture"
            accept=".pdf, .doc, .ppt, .pptx"
            ref={imageInputRef}
            onChange={handleFileChange}
          />
          {fileError && (
            <span className="fileUpload_error text-danger">{fileError}</span>
          )}
        </div>


        <div className="row">
          <div className="inputFormCareer col-md-6">
            <div className="mb-3 d-flex">
            <div className="d-flex align-items-center check-box-gap">
                            <input
                              type="radio"
                              class="ajax_save"
                              name="experienced"
                              defaultChecked
                              onInput={(e) => {
                                e.target.value = e.target.value;
                              }}
                              id="fresher"
                              value="fresher"
                                onClick={handleExperienced}
                            />{" "}
                            I'm a Fresher
                            <input
                              type="radio"
                              class="ajax_save"
                              onInput={(e) => {
                                e.target.value = e.target.value;
                              }}
                              name="experienced"
                              id="experienced"
                              value="experienced"
                              onClick={handleExperienced}
                            />{" "}
                            I'm Experienced
                          </div>
              <span className="condition_error"></span>
            </div>
          </div>
        {isExperienced && ( 
         <div className="row">
         <div className="row">
          <div className="inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="company_name">
                <b>Company Name</b>
              </label>
              <input
                type="text"
                className=" form-control "
                id="company_name"
                name="company_name"
                maxLength="50"
                placeholder="Enter Your Company Name"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(
                    /^\s+|[^A-Za-z0-9\s]/g,
                    ""
                  );
                }}
              />
              <span className="condition_error"></span>
            </div>
          </div>
          <div className="inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="current_ctc">
                <b>Current CTC (Annual)</b>
              </label>
              <input
                type="text"
                className=" form-control "
                id="current_ctc"
                name="current_ctc"
                placeholder="Enter CTC in LPA ( ex. 1.2)"
                maxLength="10"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
                  e.target.value = e.target.value.replace(
                    /(\d{0,9}(?:\.\d{0,2})?).*$/g,
                    "$1"
                  ); // Limit to 3 digits before the decimal and 2 digits after
                }}
              />
              <span className="condition_error"></span>
            </div>
          </div>
        </div>
        

        <div className="row">
        <div className="inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="expctd_CTC">
                <b>Expected CTC (Annual)</b>
              </label>
              <input
                type="text"
                className="  form-control "
                id="expected_ctc"
                name="expected_ctc"
                placeholder="Enter CTC in LPA ( ex. 1.2)"
                maxLength="10"
                // onInput={(e) => {
                //   e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
                //   e.target.value = e.target.value.replace(
                //     /(\d{0,9}(?:\.\d{0,2})?).*$/g,
                //     "$1"
                //   ); // Limit to 3 digits before the decimal and 2 digits after
                // }}
                onChange={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only digits and decimal point
                  e.target.value = e.target.value.replace(
                    /(\d{0,9}(?:\.\d{0,2})?).*$/g,
                    "$1"
                  );
                  const currentCTC =
                    document.getElementById("current_ctc").value;
                  console.log(currentCTC);
                  if (e.target.value < currentCTC) {
                    document.querySelector(".condition_error2").innerText =
                      "Expected CTC should not be less than Current CTC";
                  } else if (e.target.value > currentCTC) {
                    document.querySelector(".condition_error2").innerText = "";
                  }
                }}
              />
              <span className="condition_error"></span>
              <span className="condition_error2"></span>
            </div>
          </div>
          <div className="inputFormCareer col-md-6">
            <div className="mb-3">
              <label htmlFor="Notice_Period">
                <b>Notice Period</b>
              </label>
              <select
                id="notice_period"
                name="notice_period"
                className="form-select form-control trio_mendate  "
                placeholder="Select Days"
              >
                <option value="0" className="form-select">
                  Immediate Joiner
                </option>
                <option value="15" className="form-select">
                  15 Days
                </option>
                <option value="30" className="form-select" selected>
                  30 Days
                </option>
                <option value="45" className="form-select">
                  45 Days
                </option>
                <option value="60" className="form-select">
                  2 Months
                </option>
                <option value="90" className="form-select">
                  3 Months
                </option>
              </select>
              <span className="condition_error"></span>
            </div>
          </div>
        </div>
         </div>
          )}
          </div>

        <div className="row">
          <div className="inputFormCareer mb-3">
            <label htmlFor="message" className="form-label">
              <b>Your Message to Hiring Manager (Cover Letter)</b>{" "}
            </label>
            <textarea
              className=" form-control "
              id="message"
              name="message"
              maxLength="300"
              rows="4"
              placeholder="Please Type Your Message Here..."
            ></textarea>
            <span className="condition_error"></span>
          </div>
          <div className="inputFormCareer captchadIV col-md-12">
            <div>
              <div className="captchaText">
                <label htmlFor="captcha">Enter Captcha</label>

                <h6 className="captch_select">{captch_check}</h6>
              </div>
              <input
                type="text"
                className="form-control trio_mendate"
                id="captcha"
                name="captcha"
                onChange={(e) => {
                  setUserInputCpt(e.target.value);
                }}
                required
              />
              {isCaptchaValid ? (
                <p></p>
              ) : (
                <p className="text-danger mt-1 text-center">
                  Invalid CAPTCHA .
                </p>
              )}
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col">
                <div className="FooterCheckBxx">
                  <label
                    style={{ display: "inline-block", verticalAlign: "middle" }}
                  >
                    <input
                      type="checkbox"
                      onChange={handleCheckboxChange}
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                      }}
                    />
                    <span
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                      }}
                    >
                      I agree to the
                      <Link
                        onClick={() => handleLinkClick("/Terms_Of_Use")}
                        style={{ margin: "0 3px" }}
                      >
                        Terms Of Use
                      </Link>
                      <Link onClick={() => handleLinkClick("/Privacy_Policy")}>
                        Privacy Policy
                      </Link>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="sbmtbtnDiv">
            <button
              className="primary_btn "
              type="button"
              onClick={handleSubmit}
            >
              <p>Submit</p>
              <span className="spancheckmaker">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.293 4.293a1 1 0 0 1 1.414 1.414l-7 7a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 10.586l6.293-6.293a1 1 0 0 1 1.414 0z" />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </form>

      {showModel && <ModelPopUp title={title} dis={description} />}
    </div>
  );
}

export default CareerForm;
