import React, { useRef,useState } from "react";
import "../Css/PrivacyPolicy.css";
import Footer from "./Footer";
import Header from "./Header";

function PrivacyPolicy() {
  const footerRef = useRef(null);
  const topRef = useRef(null);
  return (
    <div>
      <div className="navStickyTop">
        <Header footerRef={footerRef} />
      </div>
      <div className="privacy_policy">
        <div className="container privacy_policy_wrapper">
          <h4>Privacy Policy</h4>
          <div className="privacy_policy_container">
            <div>
              <strong>
                <h6 style={{ marginTop: "1rem" }}>1. Introduction</h6>
              </strong>
              <p>
                We know that sharing your personal information with us is based
                on trust. We take this seriously and are committed to ensuring
                that we respect your privacy when you visit our website or use
                our services. Please review this Statement carefully to learn
                about our privacy practices
                <br />
              </p>
            </div>
            <div>
              <strong>
                <h6>2. Topics:</h6>
              </strong>
              {/* <p>
                <strong style={{ color: "white" }}>2.1.</strong>&nbsp;We only
                collect necessary cookies which are essential for the operation
                of the website. These cookies do not contain any personal
                information and are used solely for technical purposes..
              </p> */}
              <ul>
                <li>• What data do we collect?</li>
                <li>• How do we collect your data?</li>
                <li>• How will we use your data?</li>
                <li>• How do we store your data?</li>

                <li>• Newsletter</li>
                <li>• What are your data protection rights?</li>
                <li>• What are cookies?</li>
                <li>• How do we use cookies?</li>
                <li>• Privacy policies of other websites</li>
                <li>• Changes to our privacy policy</li>
                <li>• How to contact us?</li>
                <li>• How to contact the appropriate authorities?</li>
              </ul>
            </div>
            <div>
              <strong>
                <h6>3. What data do we collect?</h6>
              </strong>
              <p>
                <strong style={{ color: "white" }}></strong>&nbsp;Triosoft.ai
                collects certain information from you when you decide to use or
                access our services. This includes:
              </p>
              <ul>
                <li>• Name</li>
                <li>• Phone Number</li>
                <li>• Email address</li>
                <li>• Resume or Curriculum Vitae [For Career opportunities]</li>

                <li>• Details of previous employment.</li>
                <li>• Email[For Newsletter]</li>
              </ul>
              <p>
                <strong style={{ color: "white" }}></strong>&nbsp; Legal basis
                for collection of the above mentioned data:
              </p>
            </div>
            <div>
              <table>
                <thead>
                  <th>Data</th>

                  <th>Legal Basis</th>

                  <th>Explanation</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Name,Contact Details,Previous Employment Details,
                      Curriculum Vitae[Careers Page]
                    </td>
                    <td className="tblDataMargin">
                      Pre-contractual Measures (GDPR Article 6(1)(b))
                    </td>
                    <td>
                      These details are necessary to assess job applications and
                      progress through the recruitment process.
                    </td>
                  </tr>
                  <tr className="tblDataMargin">
                    <td>Email[Newsletter]</td>
                    <td className="tblDataMargin">Consent (Article 6(1)(a))</td>
                    <td>
                      We collect your email address only if you voluntarily
                      subscribe to our newsletter. By subscribing, you give us
                      your explicit consent (Article 6(1)(a) GDPR) to receive
                      periodic email updates about [mention the content of your
                      newsletter, e.g., news, promotions, upcoming events]. You
                      can withdraw your consent to receive these emails at any
                      time. We will include an "unsubscribe" link in every email
                      communication you receive from us.
                    </td>
                  </tr>
                  <tr className="TopMarginTBl">
                    <td>Contact Details[Contact Us Page]</td>
                    <td className="TopMarginTBl">
                      Necessity for the Purposes of the Legitimate Interests
                      Pursued by the Controller (Article 6(1)(f))
                    </td>
                    <td>
                      We collect your contact details (which may include your
                      name, email address, and phone number) when you submit a
                      request through our Contact Us page. This information is
                      necessary to respond to your inquiry and fulfill our
                      legitimate interest in providing customer service and
                      support. We will only use your contact details for this
                      purpose and will not share them with third parties for
                      marketing purposes without your consent.
                    </td>
                  </tr>
                  <tr className="TopMarginTBl">
                    <td>Website Cookies </td>
                    <td className="TopMarginTBl">
                      Consent (GDPR Article 6(1)(a)), Legitimate Interests (GDPR
                      Article 6(1)(f))
                    </td>
                    <td>
                      Essential Cookies are required for proper functioning of
                      the site and its features and are justified under
                      legitimate interests while Analytical cookies are
                      collected only after gaining user’s consent.
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>
                Note: The website currently only gathers analytical cookies,
                with no collection of essential cookies at this time. Any
                updates or modifications to this will be communicated to you via
                our website.
              </p>

              <p>
                <strong>
                  <h6>4. How do we collect your data?</h6>
                </strong>
                <strong style={{ color: "white" }}></strong>&nbsp;You directly
                provide Triosoft.ai with all of the data we collect. We collect
                data and process it when you:
              </p>

              <ul>
                <li> • Subscribe to our newsletter.</li>
                <li>
                  {" "}
                  • Voluntarily provide your details for career opportunities on
                  our careers page.
                </li>
              </ul>
            </div>
            <div>
              <div>
                <strong>
                  <h6>5.How will we use your data?</h6>
                </strong>
              </div>
              <p>
                <strong style={{ color: "white" }}>5.1.</strong>&nbsp;Our Site
                collects your data so that we can:
              </p>
              <ul>
                <li> • Communicate with you.</li>
                <li>
                  {" "}
                  • Manage and process employment opportunities, including
                  reviewing submitted details for career opportunities on our
                  careers page.
                </li>
              </ul>
            </div>

            <div>
              <strong>
                <h6>6. How do we store your data?</h6>
              </strong>
              <p>
                <strong style={{ color: "white" }}></strong>&nbsp;Triosoft.ai
                securely stores your data at Amazon data centers present in
                India while maintaining secured protocols and encryption
                techniques.
              </p>
              <p>
                <strong style={{ color: "white" }}></strong>&nbsp;We will retain
                your personal data for the period necessary to fulfill the
                purposes for which it was collected, and in accordance with
                applicable laws.
              </p>
              <p>
                {" "}
                •<strong style={{ color: "white" }}></strong>&nbsp;Contact Us
                Page: We will retain your contact information (such as name,
                email address, and phone number) for a reasonable period to
                respond to your inquiry and ensure any follow-up is addressed.
                Once your inquiry is resolved, we will delete your data unless
                you become a customer or request to be included in our marketing
                communications.
              </p>
              <p>
                <strong style={{ color: "white" }}></strong>&nbsp;Newsletter: We
                will retain your email address for as long as you remain
                subscribed to our newsletter. You can unsubscribe at any time,
                and upon unsubscribing, we will delete your email address from
                our mailing list.
              </p>
              <p>
                <strong>
                  <h6>7. Email Address for Newsletter</h6>
                </strong>
                <strong style={{ color: "white" }}></strong>&nbsp;We collect
                your email address only if you voluntarily subscribe to our
                newsletter. By subscribing, you give us your explicit consent
                (Article 6(1)(a) GDPR) to receive periodic email updates about
                [mention the content of your newsletter, e.g., news, promotions,
                upcoming events].
              </p>
            </div>

            <div>
              <p>
                <strong style={{ color: "white" }}></strong>&nbsp;You can
                withdraw your consent to receive these emails at any time by
                mailing us at support@triosoft.ai. We will include an "unsubscribe"
                link in every email communication you receive from us.
              </p>
            </div>
            <div>
              <strong>
                <h6>8. What are your data protection rights?</h6>
              </strong>
              <p>
                <strong style={{ color: "white" }}></strong>&nbsp; Right to
                Access:
                <ul>
                  <li>
                    • You can request a copy of the personal data we hold about
                    you. This helps you verify what data we have and ensure its
                    accuracy.
                  </li>
                  <li>
                    • To request access, simply contact us using the information
                    provided in the "How to Contact Us" section. We may charge a
                    small fee for excessive or repetitive requests.
                  </li>
                </ul>
                <strong style={{ color: "white" }}></strong>&nbsp; Right to
                Rectification:
                <ul>
                  <li>
                    • If you believe any of your personal data is inaccurate or
                    incomplete, you have the right to request corrections or
                    updates.
                  </li>
                  <li>
                    • You can submit a request to rectify your data through our
                    contact information. We will respond to your request within
                    one month.
                  </li>
                </ul>
                <strong style={{ color: "white" }}></strong>&nbsp; Right to
                Erasure (Right to be Forgotten):
                <ul>
                  <li>
                    • Under certain circumstances, you can request us to erase
                    your personal data. This right applies if:
                  </li>
                  <ol>
                    Your data is no longer necessary for the purposes we
                    collected it for.
                  </ol>
                  <ol>
                    You withdraw your consent for data processing (if the
                    processing relied on consent).
                  </ol>
                  <ol>
                    You object to the processing of your data and we don't have
                    overriding legitimate grounds to continue processing it.
                  </ol>
                  <ol>Your data has been unlawfully processed.</ol>
                  <ol>
                    Your data needs to be erased to comply with a legal
                    obligation.
                  </ol>
                  <li>
                    • To submit an erasure request, please contact us using the
                    provided information. We will respond within one month to
                    confirm whether your request has been granted.
                  </li>
                </ul>
                <strong style={{ color: "white" }}></strong>&nbsp; Right to
                Restriction of Processing:
                <ul>
                  <li>
                    •You have the right to request that we restrict the
                    processing of your personal data in certain situations. This
                    means we can store your data but not use it further.
                  </li>
                  <li>• Restriction applies if:</li>
                  <ol>
                    You contest the accuracy of your data, and we need time to
                    verify its accuracy.
                  </ol>
                  <ol>
                    The processing is unlawful, but you don't want us to erase
                    your data.
                  </ol>
                  <ol>Your data has been unlawfully processed.</ol>
                  <ol>
                    We no longer need your data, but you require it for legal
                    purposes.
                  </ol>
                  <ol>
                    You have objected to the processing, and we are considering
                    the validity of your objection.
                  </ol>
                  <li>
                    • To request restriction of processing, contact us through
                    our provided channels. We will assess your request and
                    respond within one month.
                  </li>
                </ul>
                <strong style={{ color: "white" }}></strong>&nbsp; Right to
                Object to Processing:
                <li>
                  • You have the right to object to the processing of your
                  personal data, particularly when it's based on our legitimate
                  interests. We will stop processing your data unless we can
                  demonstrate compelling legitimate grounds that override your
                  interests, rights, and freedoms, or for the establishment,
                  exercise, or defense of legal claims.
                </li>
                <li>
                  • To object to processing, contact us using the methods
                  mentioned below in the “Exercising Your Rights” section. We
                  will respond within one month to inform you of the outcome.
                </li>
                <strong style={{ color: "white" }}></strong>&nbsp; Right to Data
                Portability:
                <li>
                  • This right allows you to request that we transfer the
                  personal data you provided to us to another organization, in a
                  structured, commonly used, and machine-readable format. This
                  right applies to data you have given us consent to process, or
                  data necessary for the performance of a contract with you.
                </li>
                <li>
                  • To request data portability, contact us using the provided
                  information. We will assess your request and respond within
                  one month.
                </li>
                <strong style={{ color: "white" }}></strong>&nbsp; Exercising
                Your Rights:
                <p>
                  We aim to respond to all data protection requests within one
                  month. This timeframe may be extended for complex requests or
                  if we receive a high volume of requests. We will inform you if
                  an extension is necessary.
                </p>
                <p>
                  If you would like to exercise any of these rights, please
                  contact us at our email:{" "}
                  <a
                    href="support@triosoft.ai
"
                  >
                    {" "}
                    support@triosoft.ai
                  </a>
                </p>
              </p>
            </div>
            <div>
              <strong>
                <h6>9. What are cookies?</h6>
              </strong>
              <div></div>
              <p>
                Note: The website currently only gathers analytical cookies,
                with no collection of essential cookies at this time. Any
                updates or modifications to this will be communicated to you via
                our website.
              </p>
              <p>Cookies used by our site:</p>
              <strong>
                <table>
                  <thead>
                    <th>Cookie</th>

                    <th>Name & Expiration time</th>
                    <th>First party or third party </th>
                    <th>Purpose</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Google Analytics </td>
                      <td className="tblDataMargin">_ga (2 years) </td>
                      <td className="tblDataMargin">First party</td>
                      <td className="tblDataMargin">
                        These cookies are used to throttle the request rate and
                        to distinguishes unique users by assigning a randomly
                        generated number as a client identifier. It is included
                        in each page request in a site and used to calculate
                        visitor, session and campaign data for the sites
                        analytics reports. For more information, please visit
                        www.google.com/policies/privacy/partners/ If you wish to
                        disable Google Analytics on your browser please visit
                        http://tools.google.com/dlpage/gaoptout.
                      </td>
                    </tr>
                    <tr className="tblDataMargin">
                      <td>Google SSO</td>
                      <td className="tblDataMargin">G_ENABLED_IDPS(Session)</td>
                      <td className="tblDataMargin">First Party</td>
                      <td className="tblDataMargin">
                        This cookie is used to enable and manage the Google
                        Identity Platform's authentication services, allowing
                        users to sign in to websites using their Google
                        accounts.
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  Note: The website currently only gathers analytical cookies,
                  with no collection of essential cookies at this time. Any
                  updates or modifications to this will be communicated to you
                  via our website.
                </p>
                <strong style={{ color: "white" }}></strong>&nbsp; Cookies used
                by our site:
                <table>
                  <thead>
                    <th>Cookies</th>

                    <th>Details</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Essential Cookies</td>
                      <td className="tblDataMargin">
                        These are typically cookies strictly necessary for basic
                        website functionality, such as remembering your login
                        information or shopping cart contents.
                      </td>
                    </tr>
                    <tr className="tblDataMargin">
                      <td>Analytical Cookies</td>
                      <td className="tblDataMargin">
                        We use these cookies to help us understand how the
                        website is being used and how we can improve your
                        experience of it. These cookies can provide us with
                        information to help us understand which parts of the
                        website interest our visitors and if they experience any
                        errors. We use these cookies to test different designs
                        and features for our websites and marketing to specific
                        groups of people. We also use them to help us monitor
                        how visitors reach the website. You can turn off
                        analytics tools through the Cookie Consent Tool
                      </td>
                    </tr>
                    <tr className="TopMarginTBl">
                      <td>Functional Cookies</td>
                      <td className="TopMarginTBl">
                        These cookies are used to enable and manage the Google
                        Identity Platform's authentication services, allowing
                        users to sign in to websites using their Google
                        accounts.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </strong>
              <strong style={{ color: "white" }}></strong>&nbsp; How to manage
              cookies?
              <p>
                To give you control, we provide you with a Cookie Consent Tool,
                which allows you to review the first party and third-party
                cookies placed through our website and adjust your cookie
                settings, including whether to accept or decline such cookies.
              </p>
              <p>
                You can access the Cookie Consent Tool by clicking on **Link to
                be inserted here** or at any time through the “Cookie Consent”
                link at the bottom of every page or by modifying your
                preferences in our cookie banner. In addition, you can manage
                cookies through the settings of your internet browser. You can
                have the browser notify you when you receive a new cookie,
                delete individual cookies or delete all cookies.
              </p>
              <strong style={{ color: "white" }}></strong>&nbsp; Privacy
              policies of other websites:
              <p>
                The website “triosoft.ai” contains links to other websites. Our
                privacy policy applies only to our website, so if you click on a
                link to another website, you should read their privacy policy.
              </p>
              <strong style={{ color: "white" }}></strong>&nbsp;Careers:
              <p>Application Consent</p>
              <p>Who We Are and What We Collect?</p>
              <p>
                Triosoft Technologies Pvt. Ltd. (Triosoft) is the data
                controller for your personal information collected during the
                application process. We collect your data to manage recruitment
                activities and for organizational planning purposes. This
                information may be used to evaluate your candidacy, including
                scheduling interviews and assessments, and making hiring
                decisions.
              </p>
              <p>Legal Basis for Data Collection</p>
              <p>
                The information you provide is necessary for us to consider your
                application for employment. This collection falls under the
                category of pre-contractual measures taken at your request.
              </p>
              <p>What Happens to Your Application?</p>
              <ul>
                <li>
                  {" "}
                  Successful Applications: If you are hired, your application
                  information will become part of your employee file.
                </li>
                <li>
                  Unsuccessful Applications: If your application is
                  unsuccessful, we may retain your data in our candidate pool
                  for future opportunities at Triosoft or its affiliates, unless
                  you explicitly request otherwise reach out to use at{" "}
                  <a href="into@triosoft.ai">into@triosoft.ai</a>.
                </li>
              </ul>
              <strong style={{ color: "white" }}></strong>&nbsp;Data Retention
              <p>
                We will retain your application data for a reasonable period
                based on the following factors:
              </p>
              <li>• The duration of the recruitment process.</li>
              <li>• Our ongoing relationship with you (if any).</li>
              <li>• Legal requirements.</li>
              <li>
                • Our need to protect our legitimate interests (e.g., legal
                disputes).
              </li>
              <strong style={{ color: "white" }}></strong>&nbsp;Data Disclosure
              <p>
                We will not disclose your information to unauthorized third
                parties. We may share your data with references you provide, or
                for reasons related to fraud prevention, security, legal
                obligations, or protecting the rights of Triosoft, our users, or
                the public. We maintain appropriate safeguards to protect your
                privacy and the information you provide.
              </p>
              <strong style={{ color: "white" }}></strong>&nbsp;Your Rights
              <p>
                You have the right to request access to, review, and update your
                personal information. You can also request to delete your data,
                restrict its processing, or object to its use. To exercise these
                rights or request a copy of your data (where applicable), please
                contact us<a href="into@triosoft.ai">into@triosoft.ai</a>.
              </p>
              <strong style={{ color: "white" }}></strong>&nbsp;Filing a
              Complaint
              <p>
                If you believe we have not handled your data in accordance with
                applicable laws.Data privacy regulations differ by country and
                region. If you have any concerns about how your data is handled,
                you can file a complaint with the relevant data protection
                authority in your area.
              </p>
              <strong style={{ color: "white" }}></strong>&nbsp;Changes to this
              Policy
              <p>
                We reserve the right to modify this policy at any time. Any
                changes will be posted here and will apply to your continued use
                of our site and your application materials.
              </p>
              <strong style={{ color: "white" }}></strong>&nbsp;Changes to our
              privacy policy
              <p>
                We keep our privacy policy under regular review and place any
                updates on this web page. This privacy policy was last updated
                on 13 April 2024.
              </p>
              <strong style={{ color: "white" }}></strong>&nbsp;How to contact
              us ?
              <p>
                If you have any questions about our privacy policy, the data we
                hold on you, or you would like to exercise one of your data
                protection rights, please do not hesitate to contact us.
                <p>Email us at: info@triosft.ai</p>
                <p>
                  Or write to us at: Plot No. 96, 2nd Floor, Gargi Rani Complex,
                  Opposite Indian Overseas Bank, M.P. Nagar, Zone-II, Bhopal,
                  Madhya Pradesh India- 462011
                </p>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer servicesRef={null} topRef={topRef} updateHomeData={null} />
    </div>
  );
}

export default PrivacyPolicy;
