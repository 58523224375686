import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contactus from "./Components/Contactus";
import CareerDetails from "./Components/Careerdeatails";
import Career from "./Components/Career1";
import Casestudy from "./Components/Casestudy";
import Insights from "./Components/Insights";
import About from "./Components/About";
import Blogdetail from "./Components/Blogdetail";
import TRASH from "./Components/TRASH";
import AOS from "aos";
import "aos/dist/aos.css";
import Home from "./Components/Home";
import Portfolio from "./Components/Portfolio";
import EmployResgistration from "./Components/EmployRegistration.js";
import {
  server_post_data,
  seo_data_url,
} from "./ServiceConnection/serviceconnection.js";
import Testimonials from "./Components/Testimonials.js";
import ScrollToTop from "./Components/ScrollToTop.js";
import PrivacyPolicy from "./Components/PrivacyPolicy.js";
import TermsOfUse from "./Components/TermsOfUse.js";
import Page404 from "./Components/Page404.js";
function App() {
  AOS.init();
  const [SEOloop, setSEOloop] = useState([]);
  const match_and_return_seo_link_and_page = (
    call_function_name,
    pretty_function_name,
    dynamic_title_ss,
    favicon_sss
  ) => {
    let data_seo_link_final = "";

    if (call_function_name === "/") {
      data_seo_link_final = pretty_function_name + "~@~1";
    } else if (call_function_name === "/contact-us") {
      data_seo_link_final = pretty_function_name + "~@~2";
    } else if (call_function_name.includes("careers/vacancy_details")) {
      data_seo_link_final = pretty_function_name + "~@~3";
    } else if (call_function_name === "/Career") {
      data_seo_link_final = pretty_function_name + "~@~4";
    } else if (call_function_name === "/Casestudy") {
      data_seo_link_final = pretty_function_name + "~@~5";
    } else if (call_function_name === "/Insights") {
      data_seo_link_final = pretty_function_name + "~@~6";
    } else if (call_function_name === "/About") {
      data_seo_link_final = pretty_function_name + "~@~7";
    } else if (call_function_name.includes("Blogdetails/blog")) {
      data_seo_link_final = pretty_function_name + "~@~8";
    } else if (call_function_name === "/Testimonials") {
      data_seo_link_final = pretty_function_name + "~@~9";
    } else if (call_function_name.includes("Casestudydetails/case")) {
      data_seo_link_final = pretty_function_name + "~@~10";
    } else if (call_function_name === "/EmployResgistration") {
      data_seo_link_final = pretty_function_name + "~@~11";
    }

    return data_seo_link_final;
  };

  const get_page_name = (page_number) => {
    let data_seo_link_final = "";

    if (page_number === "1") {
      data_seo_link_final = <Home />;
    } else if (page_number === "2") {
      data_seo_link_final = <Contactus />;
    } else if (page_number === "3") {
      data_seo_link_final = <CareerDetails />;
    } else if (page_number === "4") {
      data_seo_link_final = <Career />;
    } else if (page_number === "5") {
      data_seo_link_final = <Casestudy />;
    } else if (page_number === "6") {
      data_seo_link_final = <Insights />;
    } else if (page_number === "7") {
      data_seo_link_final = <About />;
    } else if (page_number === "8") {
      data_seo_link_final = <Blogdetail />;
    } else if (page_number === "9") {
      data_seo_link_final = <Testimonials />;
    } else if (page_number === "10") {
      data_seo_link_final = <Portfolio />;
    } else if (page_number === "11") {
      data_seo_link_final = <EmployResgistration />;
    }

    return data_seo_link_final;
  };

  const handleFetchData = async () => {
    await server_post_data(seo_data_url, null)
      .then((response) => response.json())
      .then((data_data) => {
        setSEOloop(data_data.seo_loop);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/Contact_Us" element={<Contactus />} />
          <Route
            path="/careers/vacancy_details/:id"
            element={<CareerDetails />}
          />
          <Route path="/Blogdetails/blog/:id" element={<Blogdetail />} />
          <Route path="/Casestudydetails/case/:id" element={<Portfolio />} />
          <Route path="/Career" element={<Career />} />
          <Route path="/Casestudy" element={<Casestudy />} />
          <Route path="/Insights" element={<Insights />} />
          <Route path="/About" element={<About />} />
          <Route
            path="/EmployResgistration/:id"
            element={<EmployResgistration />}
          />

          <Route path="/Testimonials" element={<Testimonials />} />
          <Route path="/Privacy_Policy" element={<PrivacyPolicy />} />
          <Route path="/Terms_Of_Use" element={<TermsOfUse />} />
          <Route path="/*" element={<Page404 />} />

          <Route path="/trash" element={<TRASH />} />
          {SEOloop.map((data, index) => {
            const for_loop_come = match_and_return_seo_link_and_page(
              data.call_function_name,
              data.pretty_function_name,
              data.title,
              data.favicon
            );
            const stringArray = for_loop_come.split("~@~");

            return (
              <Route
                key={index}
                path={stringArray[0]}
                element={get_page_name(stringArray[1])}
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
