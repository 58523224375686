import React, { useRef, useState, useEffect } from "react";
import "../Css/About.css";
import Header from "./Header";
import blg from "../assets/images/blg.png";
import alg from "../assets/images/alg.png";
import hlg from "../assets/images/hlg.png";
import slg from "../assets/images/slg.png";
import sslg from "../assets/images/sslg.png";
import ab_end from "../assets/images/ab_end.png";
import ab_end1 from "../assets/images/ab_end1.png";
import ab_end2 from "../assets/images/ab_end2.png";
import history from "../assets/images/history.png";
import box from "../assets/images/box.png";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
import {
  footer_data,
  server_post_data,
} from "../ServiceConnection/serviceconnection.js";
function About() {
  const [dataloop, setData] = useState([]);
  const footerRef = useRef(null);
  const topRef = useRef(null);

  const handleFetchData = async () => {
    await server_post_data(footer_data, null)
      .then((response) => response.json())
      .then((data_data) => {
        setData(data_data.data_loop[0]);
        console.log(data_data.data_loop[0]);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    handleFetchData();
  }, []);
  return (
    <div ref={topRef}>
      <div className="navStickyTop">
        <Header footerRef={footerRef} />
      </div>
      <div>
        <div className="aboutbckgrd .hue-img ">
          <div className="top_txt_bg container-lg">
            <h1>
              <span
              // style={{
              //   backgroundColor: " rgb(135, 206, 235,0.7)",
              //   width: "fit-content",
              //   padding:"0.5rem"
              // }}
              >
                Transforming Businesses
              </span>{" "}
              <br />
              <span
              // style={{
              //   backgroundColor: " rgb(135, 206, 235,0.7)",
              //   width: "fit-content",
              // }}
              >
                With Innovation
              </span>
            </h1>

            <p>
              <span
              // style={{
              //   backgroundColor: " rgb(135, 206, 235,0.7)",
              //   width: "fit-content",
              // }}
              >
                We are believers of change! A change driven by technology and
                innovation.
              </span>{" "}
              <br />
              <span
              // style={{
              //   backgroundColor: " rgb(135, 206, 235,0.7)",
              //   width: "fit-content",
              // }}
              >
                We help businesses and individuals in adapting as well as
                adopting digital transformation.
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="container-lg who_we">
        <div className="row m-0 who_we ">
          <div className="col-md-6">
            <div className="h-100 w-100 d-flex align-items-center">
              <h2>Who We Are?</h2>
            </div>
          </div>
          <div className="col-md-6">
            <p>
              We are believers in technology-driven change! We believe that
              innovation can bring about a drastic change in the world of
              technology. We provide assistance to businesses and individuals
              who are ready to adopt digital transformation. Our goal is to
              improve businesses and the lives of people with our progressive
              and Cutting-edge technology solutions.
            </p>
          </div>
        </div>
        <div className="row m-0 who_we">
          <div className="col-md-6">
            <div className="h-100 w-100 d-flex align-items-center">
              <h2>What We Do Best?</h2>
            </div>
          </div>
          <div className="col-md-6">
            <p>
              We listen to the requirements, issues, and objectives of our users
              regarding the projects and develop projects based on the
              information collected from the market sector, competitors and
              businesses. We put our best technical knowledge, expertise and
              research into the work to provide the best solution at the lowest
              and most affordable prices.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="hist_prt">
          <div className="container-lg">
            <div className="row  m-0">
              <div className=" col-md-6">
                <div className="hist_txt">
                  <div>
                    <div className="hist_">
                      <h3>Our Journey</h3>
                      <p>
                        Triosoft was established in 2017 with a dedicated team
                        of professionals committed to laying a robust foundation
                        for future success. From humble beginnings, we have
                        grown steadily, driven by a passion to excel and achieve
                        significant milestones. Through our commitment to
                        excellence, Triosoft secured its inaugural brand project
                        shortly after inception, marking a pivotal moment in our
                        journey.
                      </p>
                      <br />
                      <p className="d-none d-lg-block">
                        Today, Triosoft serves a diverse clientele and boasts a
                        talented workforce dedicated to delivering exceptional
                        results. We remain steadfast in our pursuit of setting
                        new benchmarks for success and embracing unparalleled
                        opportunities in the years ahead.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-md-6">
                <div className="hist_img">
                  <img
                    src={history}
                    alt="Dynamic Image"
                    className="box_hist1"
                  />
                  <img src={box} alt="Dynamic Image" className="box_hist" />
                </div>
              </div>
            </div>
            <div className="hist_txt">
              <div>
                <div className="hist_">
                  <p className="d-lg-none px-2 mt-5">
                    Today, Triosoft serves a diverse clientele and boasts a
                    talented workforce dedicated to delivering exceptional
                    results. We remain steadfast in our pursuit of setting new
                    benchmarks for success and embracing unparalleled
                    opportunities in the years ahead.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-lg">
        <div className="ourCoreValuesCOntainer">
          <div className="ocr_cc">
            <div className="cr_v">
              <h5>Our Core Values</h5>
              <p>
                We are passionate learners and experienced inventors, creating
                solutions that will not
                <br />
                only propagate the technology but also serve the humans behind
                it.
              </p>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-md-4  o_core_v">
              <h3>01</h3>
              <h5>Ensure Impactful Innovations </h5>
              <p>
                We believe in innovation driven by passion, creativity, and
                integrity along with the aim of achieving maximum customer
                satisfaction.
              </p>
            </div>
            <div className="col-md-4  o_core_v">
              <h3>02</h3>
              <h5>Support Work-Life Balance </h5>

              <p>
                Along with flexible working hours, the latest infrastructure and
                many other privileges are provided to our employees to encourage
                healthy lifestyle choices.
              </p>
            </div>
            <div className="col-md-4  o_core_v">
              <h3>03</h3>
              <h5>Failure is the Way to Success</h5>

              <p>
                We embrace quick testing and learn from failures. Failing fast
                allows us to adjust swiftly, fostering innovation and achieving
                goals efficiently.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg">
        {/* <div className="my-5">
          <div className="ach_txt">
            <h3>Our Achievements</h3>
            <p>
              Innovation and work go hand in hand when you get to work on some
              of the coolest projects with the help of the latest technologies!
            </p>
          </div>
          <div className="row m-0 ">
            <div className="col-md-2 col-sm-4 col-6 log_grd">
              <img className="caselogo" src={sslg} alt="My logo" />
            </div>
            <div className="col-md-2 col-sm-4 col-6 log_grd">
              <img className="caselogo" src={slg} alt="My logo" />
            </div>
            <div className="col-md-2 col-sm-4 col-6 log_grd">
              <img className="caselogo" src={alg} alt="My logo" />
            </div>
            <div className="col-md-2 col-sm-4 col-6 log_grd">
              <img className="caselogo" src={blg} alt="My logo" />
            </div>
            <div className="col-md-2 col-sm-4 col-6 log_grd ">
              <img className="caselogo" src={hlg} alt="My logo" />
            </div>
          </div>
        </div> */}
        <div className="about_pg my-5">
          <div className="row txt_about">
            <div className="col-md-4 my-3">
              <div>
                <p className="text-center">Projects</p>
                <h1 className="text-center">{dataloop.project_done}+</h1>
              </div>
            </div>
            <div className="col-md-4 my-3">
              <div>
                <p className="text-center">Lines of Code </p>
                <h1 className="text-center">{dataloop.line_of_code}+</h1>
              </div>
            </div>
            <div className="col-md-4 my-3">
              <div>
                <p className="text-center">Repeated Clients</p>
                <h1 className="text-center">{dataloop.repeated_client}%</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container-lg jttmCOntianaer">
          <div className="row">
            <div className=" col-md-6">
              <div className="head_txt_btm">
                <div>
                  <h4>Join Our Team!</h4>
                </div>
                <div>
                  <p>
                    Incorporating cutting-edge technologies not only nurtures
                    innovation but also encourages working on the most
                    captivating projects one can ever imagine.{" "}
                  </p>
                </div>
                <div>
                  <Link onClick={() => handleLinkClick("/Career")}>
                    <button className="primary_btn ">
                      <p>Apply Now</p>
                      <span className="right_arrow">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className=" col-md-6">
              <div className="row m-0">
                <div className="col-md-6 hideimgafterSm jttmCOntianaerImg">
                  <img className="btm_lg" src={ab_end} alt="My logo" />
                </div>
                <div className="col-md-6 jttmCOntianaerImg">
                  <div>
                    <img className="btm_lg" src={ab_end1} alt="My logo" />
                  </div>
                  <br></br>
                  <div>
                    <img className="btm_lg" src={ab_end2} alt="My logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={footerRef}>
        <Footer servicesRef={null} topRef={topRef} updateHomeData={null} />
      </div>
    </div>
  );
}

export default About;
