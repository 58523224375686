import $ from "jquery";
const validateEmail = (email) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
};
const handleEmailChange = (e) => {
  // Check if the input contains leading spaces or exceeds 72 characters
  if (/^\s/.test(e.target.value) || e.target.value.length > 72) {
    e.target.value = ""; // Clear the input
    return;
  }

  // Check if the input contains any characters other than letters, digits, '@', '.', and '_'
  if (!/^[a-zA-Z0-9@._]*$/.test(e.target.value)) {
    e.target.value = ""; // Clear the input
  }
};

const handleIaphabetnumberChange = (e) => {
  e.target.value = e.target.value.replace(/[^A-Za-z0-9\s]/g, "");
};

const handleAphabetsChange = (e) => {
  // Check if the input contains leading spaces
  if (/^\s/.test(e.target.value)) {
    e.target.value = ""; // Clear the input
    return;
  }

  // Replace the password input based on the regex pattern
  e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, "");
};

const handleNumbersChange = (e) => {
  // e.target.value = e.target.value.replace(/[^0-9]/g, "");
  e.target.value = e.target.value.slice(0, 13).replace(/[^0-9]/g, "");

  // Also, allow up to 10 digits after the country code for India and 9 digits for Sweden
};

const validateName = (name) => {
  const nameRegex = /^[A-Za-z\s]+$/;
  return nameRegex.test(name);
};

const validateMobile = (mobile) => {
  // Remove leading and trailing whitespaces
  const trimmedMobile = mobile.trim();

  // Check if the trimmed mobile number is either a 10-digit numeric string or a "+91" followed by 10 digits
  const mobileRegex = /^(?:\+91)?[0-9]{10}$/;
  //  const mobileRegex = /^(?:(?:\+46\d{1,9})|(?:\+91\d{10}))$/;

  return mobileRegex.test(trimmedMobile);
};

const validateMobileSweden = (mobile) => {
  // Remove leading and trailing whitespaces
  const trimmedMobile = mobile.trim();

  // Check if the trimmed mobile number is either a 10-digit numeric string or a "+91" followed by 10 digits
  const mobileRegex = /^(?:\+91)?[0-9]{10}$/;
  //const mobileRegex = /^(?:(?:\+46\d{1,9})|(?:\+91\d{10}))$/;

  return mobileRegex.test(trimmedMobile);
};

const validateZip = (zip) => {
  const zipRegex = /^\d{6}$/;
  return zipRegex.test(zip);
};

const validatePassword = (value_send) => {
  const mobileRegex = /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[A-Z]).{8,}$/g;
  return mobileRegex.test(value_send);
};

const check_vaild_save = (class_name) => {
  var isValid = true;

  $("#" + class_name + " .trio_mendate").each(function () {
    let input_type = $(this).attr("type");
    let triotrio_mandatory = $(this).attr("class").includes("trio_mendate");
    let trioEmailElement = $(this).attr("class").includes("trio_email");

    let trioMobnolElement = $(this).attr("class").includes("trio_no");
    let triopasswordlElement = $(this).attr("class").includes("trio_password");
    let triocountlElement = $(this).attr("class").includes("trio_count");
    let trioNamelElement = $(this).attr("class").includes("trio_name");
    let trioZiplElement = $(this).attr("class").includes("trio_zip");
    let minlength = $(this).attr("minlength");
    if (input_type !== "file") {
      $(this).val($(this).val().trim());
    }

    let value_show = $(this).val();
    let minlength_data = minlength;
    if ($(this).is("select")) {
      minlength_data = 1;
    }

    $(this).css({
      border: "",
      background: "",
    });
    $(this).nextAll(".condition_error:first").hide();
    if (triotrio_mandatory) {
      if (
        value_show === "" ||
        value_show === null ||
        value_show.length < minlength_data
      ) {
        isValid = false;
        $(this).css({
          border: "1px solid red",
          width: "50px !important",
        });
        $(this)
          .nextAll(".condition_error:first")
          .html("Please Fill The Mandatory Information")
          .show();
      } else {
        if (trioEmailElement) {
          if (!validateEmail(value_show)) {
            $(this).css({
              border: "1px solid red",
              width: "50px !important",
            });
            isValid = false;
            $(this)
              .nextAll(".condition_error:first")
              .html("Please Fill Valid Email Id")
              .show();
          }
        }
        if (trioZiplElement) {
          if (!validateZip(value_show)) {
            $(this).css({
              border: "1px solid red",
              width: "50px !important",
            });
            isValid = false;
            $(this)
              .nextAll(".condition_error:first")
              .html("Please Enter Valid Zip Code")
              .show();
          }
        }
        if (trioNamelElement) {
          if (!validateName(value_show)) {
            isValid = false;
            $(this).css({
              border: "1px solid red",
              width: "50px !important",
            });
            $(this)
              .nextAll(".condition_error:first")
              .html("Please Fill Valid Name")
              .show();
          }
        }
        if (trioNamelElement) {
          if (value_show.length < 3) {
            isValid = false;
            $(this).css({
              border: "1px solid red",
              width: "50px !important",
            });
            $(this)
              .nextAll(".condition_error:first")
              .html("Please Fill Valid Name")
              .show();
          }
        }
        if (trioMobnolElement) {
          if (!validateMobileSweden(value_show)) {
            isValid = false;
            $(this).css({
              border: "1px solid red",
              width: "50px !important",
            });
            $(this)
              .nextAll(".condition_error:first")
              .html("Please Enter Valid Mobile No")
              .show();
          }
        }
        if (triopasswordlElement) {
          if (!validatePassword(value_show)) {
            $(this).css({
              border: "1px solid red",
              width: "50px !important",
            });
            $(this)
              .nextAll(".condition_error:first")
              .html(
                "Password length must be 8 characters or longer .Include A-Z, a-z,0-9 and spacial characters."
              )
              .show();
            isValid = false;
          }
        }
        if (triocountlElement) {
          if (Number(value_show) === 0) {
            alert("Please Add Items");
            isValid = false;
          }
        }
      }
    } else {
      if (value_show !== "") {
        if (trioEmailElement) {
          if (!validateEmail(value_show)) {
            $(this).css({
              border: "1px solid red",
              width: "50px !important",
            });
            isValid = false;
            $(this)
              .nextAll(".condition_error:first")
              .html("Please Fill Valid Email Id")
              .show();
          }
        }
        if (trioZiplElement) {
          if (!validateZip(value_show)) {
            $(this).css({
              border: "1px solid red",
              width: "50px !important",
            });
            isValid = false;
            $(this)
              .nextAll(".condition_error:first")
              .html("Please Enter Valid Zip Code")
              .show();
          }
        }
        if (trioNamelElement) {
          if (!validateName(value_show)) {
            isValid = false;
            $(this).css({
              border: "1px solid red",
              width: "50px !important",
            });
            $(this)
              .nextAll(".condition_error:first")
              .html("Please Fill Valid Name")
              .show();
          }
        }
        if (trioNamelElement) {
          if (value_show.length < 3) {
            isValid = false;
            $(this).css({
              border: "1px solid red",
              width: "50px !important",
            });
            $(this)
              .nextAll(".condition_error:first")
              .html("Please Fill Valid Name")
              .show();
          }
        }
        if (trioMobnolElement) {
          if (!validateMobile(value_show)) {
            isValid = false;
            $(this).css({
              border: "1px solid red",
              width: "50px !important",
            });
            $(this)
              .nextAll(".condition_error:first")
              .html("Please Enter Valid Mobile No")
              .show();
          }
        }
        if (triopasswordlElement) {
          if (!validatePassword(value_show)) {
            $(this).css({
              border: "1px solid red",
              width: "50px !important",
            });
            $(this)
              .nextAll(".condition_error:first")
              .html(
                "Password length must be 8 characters or longer .Include A-Z, a-z,0-9 and symbol like @,#$%..etc."
              )
              .show();
            isValid = false;
          }
        }
        if (triocountlElement) {
          if (Number(value_show) === 0) {
            alert("Please Add Items");
            isValid = false;
          }
        }
      }
    }
  });

  return isValid;
};

const combiled_form_data = (form_name, dynaicimage) => {
  const fd = new FormData(document.getElementById(form_name));
  for (const [key, value] of fd.entries()) {
    if (value.type && dynaicimage != null) {
      fd.append(key + "_new", dynaicimage[key]);
    }
  }
  return fd;
};

const empty_form = (class_name) => {
  if (class_name !== "form_data_profile") {
    $("#" + class_name + " .trio_mendate").each(function () {
      $(this).val("");
      $(this).css({
        border: "",
        background: "",
      });
    });
  }
};

const getRandomSixLetterString = () => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  let randomString = "";
  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * alphabet.length);
    randomString += alphabet[randomIndex];
  }
  return randomString;
};

const handleLinkClick = (link) => {
  // Reload the page when the link is clicked
  window.location.href = link;
};

export {
  empty_form,
  check_vaild_save,
  combiled_form_data,
  getRandomSixLetterString,
  handleLinkClick,
  handleEmailChange,
  handleIaphabetnumberChange,
  handleAphabetsChange,
  handleNumbersChange,
};
