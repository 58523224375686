import { useState, useEffect } from "react";
import SpaceShooter from "./SpaceShooter";
import Header from "./Header";
import "../Css/pagenotfound.css";
import { handlePlayClick } from "./SpaceShooter";
import LetPlayBtn from "../assets/images/GamePlaybttn.png";
import upKeyicon from "../assets/images/up-arrow.svg";
import spaceKeyicon from "../assets/images/spaceBarKey.svg";

function Page404() {
  const [gameStarted, setGameStarted] = useState(false);
  const [upKeyPressed, setUpKeyPressed] = useState(false);
  const [leftKeyPressed, setLeftKeyPressed] = useState(false);
  const [rightKeyPressed, setRightKeyPressed] = useState(false);
  const [spaceBarPressed, setSpaceBarPressed] = useState(false);

  const startGame = () => {
    setGameStarted(true);
  };
  useEffect(() => {
    const handleKeyUp = (event) => {
      switch (event.key) {
        case "ArrowUp":
          setUpKeyPressed(true);
          setTimeout(() => setUpKeyPressed(false), 300);
          break;
        case "ArrowLeft":
          setLeftKeyPressed(true);
          setTimeout(() => setLeftKeyPressed(false), 300);
          break;
        case "ArrowRight":
          setRightKeyPressed(true);
          setTimeout(() => setRightKeyPressed(false), 300);
          break;
        case " ":
          setSpaceBarPressed(true);
          setTimeout(() => setSpaceBarPressed(false), 300);
          break;
        default:
          break;
      }
    };

    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <div>
      <Header />
      <div className="notFoundCont container-lg">
        <div className="container">
          <div className="intstructionnn">
            <div className="IntrUctionCont">
              <ul>
                <li>
                  <img
                    src={upKeyicon}
                    style={{
                      filter: upKeyPressed
                        ? "brightness(0) saturate(100%) invert(81%) sepia(100%) saturate(7500%) hue-rotate(354deg) brightness(117%) contrast(106%)"
                        : "none",
                      fill: "white",
                    }}
                    alt="Up Arrow"
                  />
                  Up Arrow: To Boost
                </li>

                <li>
                  <img
                    className="leftKeYY"
                    src={upKeyicon}
                    style={{
                      filter: leftKeyPressed
                        ? "brightness(0) saturate(100%) invert(81%) sepia(100%) saturate(7500%) hue-rotate(354deg) brightness(117%) contrast(106%)"
                        : "none",
                    }}
                    alt="Left Arrow"
                  />
                  Left Arrow: Move left
                </li>
                <li>
                  <img
                    className="rightKeYY"
                    src={upKeyicon}
                    style={{
                      filter: rightKeyPressed
                        ? "brightness(0) saturate(100%) invert(81%) sepia(100%) saturate(7500%) hue-rotate(354deg) brightness(117%) contrast(106%)"
                        : "none",
                    }}
                    alt="Right Arrow"
                  />
                  Right Arrow: Move Right
                </li>
                <li>
                  <img
                    className="spaceBarKey"
                    src={spaceKeyicon}
                    style={{
                      filter: spaceBarPressed
                        ? "brightness(0) saturate(100%) invert(81%) sepia(100%) saturate(7500%) hue-rotate(354deg) brightness(117%) contrast(106%)"
                        : "none",
                    }}
                    alt="Space Bar"
                  />
                  Space Bar: To Shoot
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="gameDiv col-md-10 m-auto">
          <div
            style={{
              border: "1px solid #ffffff",
              height: "100%",
              width: "100%",
            }}
          >
            <SpaceShooter />
          </div>
          <div
            className="pagenotfoundtxt"
            style={{ display: gameStarted ? "none" : "block" }}
          >
            <h1>
              404
              <br /> <h5>Not Found</h5>
            </h1>
            <p>The Resource requested could not be found on this server! </p>
            <div className="GamePlayBtn">
              <button onClick={startGame}>
                <img src={LetPlayBtn} alt="triosoft ai"></img>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page404;
