import React, { useRef, useEffect, useState } from "react";
import "../Css/Career.css";
import Header from "./Header";
import Email1 from "../assets/images/Email1.png";
import phone from "../assets/images/phone.png";
import CareerImg1 from "../assets/images/careerImg1.svg";
import PK1 from "../assets/images/PK1.png";
import PK2 from "../assets/images/PK2.png";
import PK3 from "../assets/images/PK3.png";
import PK4 from "../assets/images/PK4.png";
import PK5 from "../assets/images/PK5.png";
import PK6 from "../assets/images/PK6.png";
import PK7 from "../assets/images/PK7.png";
import PK8 from "../assets/images/PK8.png";
import PK9 from "../assets/images/PK9.png";
import PK10 from "../assets/images/PK10.png";
import PK11 from "../assets/images/PK11.png";
import PK12 from "../assets/images/teamwork.png";
import rightArrow from "../assets/images/rightImage.svg";
import CareerForm from "./Careerform";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import {
  server_post_data,
  job_vancancy_url,
} from "../ServiceConnection/serviceconnection.js";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";

function Career() {
  const [VacancytData, setVacancytData] = useState([]);
  const [Centerloop, setCenterloop] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const handleFetchData = async () => {
    setShowLoader(true);
    await server_post_data(job_vancancy_url, null)
      .then((response) => response.json())
      .then((data_data) => {
        setShowLoader(false);
        setCenterloop(data_data.center_loop[0]);
        setVacancytData(data_data.vancancy_loop);
        setSEOloop(data_data.seo_loop);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/careers/vacancy_details/" + v_id;
    let data_seo_link = data_seo_link_final;

    const matchedItem = SEOloop.find(
      (data) => data_seo_link === data.call_function_name
    );

    if (matchedItem) {
      data_seo_link_final = matchedItem.pretty_function_name;
    }

    return data_seo_link_final;
  };

  const careerFormRef = useRef(null);
  const scrollToForm = () => {
    if (careerFormRef.current) {
      const testimonialsElement = careerFormRef.current;
      const testimonialsOffset = testimonialsElement.offsetTop;
      const scrollToPosition =
        testimonialsOffset -
        6 * parseFloat(getComputedStyle(document.documentElement).fontSize);

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  };

  const footerRef = useRef(null);
  const topRef = useRef(null);

  return (
    <div className="career" ref={topRef}>
      <div className={showLoader ? "loading" : ""}></div>

      <div className="navStickyTop">
        <Header footerRef={footerRef} />
      </div>

      <section className="careerCnct container-lg">
        <div className="careerCnct_container">
          <div className="careerCnctHead">
            <div className="careerCnctHeadLeft">
              <p>
                WE HAVE A WIDE ARRAY OF FANTASTIC OPPORTUNITIES AWAITING YOU
              </p>
              <h1>Join Our Team</h1>
            </div>
          </div>
          <div className="emailPhoneD">
            <div className="emailD">
              <img src={Email1} alt="icon" />
              <p>{Centerloop.career_email}</p>
            </div>
            <div className="emailD">
              <img src={phone} alt="icon" />
              <p>{Centerloop.career_mobile}</p>
            </div>
          </div>
        </div>

        <div className="careerCards_container">
          <div className="row">
            {VacancytData.map((data, index) => (
              <div className="careerCard col-md-3 col-sm-6" key={index}>
                <Link
                  onClick={() =>
                    handleLinkClick(match_and_return_seo_link(data.v_id))
                  }
                >
                  <div className="careerCardDiv">
                    <div className="careerCardHead">
                      <div className="d-flex">
                        <div className="careerCardHeadBorder"></div>
                        <div className="careerCardHeadText">
                          <p style={{ fontWeight: "500" }}>
                            {data.designation}
                          </p>
                        </div>
                      </div>
                      <img src={CareerImg1} alt="icon" />
                    </div>
                    <div className="careerCardFooter ">
                      <p style={{ fontWeight: "400" }}>
                        Work Exp:- {data.min_experience}-{data.max_experience}{" "}
                        Years{" "}
                      </p>
                      <div className="goBtn">
                        <img src={rightArrow} alt="icon" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="careerForm container-lg">
        <div className="careerForm_container">
          <div className="careerFormBtn">
            <button onClick={scrollToForm}>
              <h6>Can't Find Your Area Of Expertise?</h6>
              <p>Apply Now</p>
            </button>
          </div>

          <div className="careerFormHead col-md-10 mx-auto">
            <p>Discover your niche?</p>
          </div>
        </div>

        <div ref={careerFormRef} className="formDetailsContainer">
          
          <CareerForm dynamic_id={0} />
          
        </div>
  
      </section>

      <section className="perksBenefits container-lg">
        <div className="perksBenefits-container">
          <div className="perksBenefitsHead">
            <h3>This is How We Work</h3>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard">
                <div className="perksBenefitsimage col-4">
                  <img src={PK1} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Friendly Environment </b>
                  </p>
                  <p>
                    The workspace should be a place where one can work
                    stress-free and give their maximum effort while at it. We
                    offer a friendly, joyful environment for our employees for
                    their personal growth and betterment so that they can give
                    their best towards the success of the company in return.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard">
                <div className="perksBenefitsimage col-4">
                  <img src={PK2} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Continuous Learning </b>
                  </p>
                  <p>
                    We learn throughout our lives and the company looks after
                    that by providing every individual who joins our company the
                    privilege of gaining knowledge through its various
                    programmes to enhance their knowledge and experience in the
                    field.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard">
                <div className="perksBenefitsimage col-4">
                  <img src={PK12} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Team Support </b>
                  </p>
                  <p>
                    Collaborative approaches have been shown to give better
                    results. We extend our enormous team support for taking care
                    of your queries and solving the problems on your way.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard">
                <div className="perksBenefitsimage col-4">
                  <img src={PK3} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Flexible Timing </b>
                  </p>
                  <p>
                    We look after the work-life balance of our employees by
                    providing them with flexible timing and pre-scheduling the
                    tasks. This helps the person reach their full potential and
                    complete the task at hand on time with ease.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard">
                <div className="perksBenefitsimage col-4">
                  <img src={PK4} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Yearly Appraisals </b>
                  </p>
                  <p>
                    We know how much hard and consistent work our employees have
                    to do every day. So, we provide them with yearly appraisals,
                    promotions, and awards to encourage them and appreciate
                    their efforts and hard work.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard">
                <div className="perksBenefitsimage col-4">
                  <img src={PK5} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Diversified Sources </b>
                  </p>
                  <p>
                    We provide our employees with a huge number of resources to
                    work on and accomplish their goals as a company. We possess
                    a big asset of resources that is concerned with the growth
                    and profitability of the company.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="perksBenefits container-lg">
        <div className="perksBenefits-container">
          <div className="perksBenefitsHead">
            <h3>Perks & Benefits</h3>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard2">
                <div className="perksBenefitsimage col-4">
                  <img src={PK6} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Professional Development</b>
                  </p>
                  <p>
                    It is very important to have professional growth for
                    everyone no matter what the field is. You will be provided
                    with ample amount of time to polish your professional skills
                    which will add some value to your career opportunities as
                    well as to your profile.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard2">
                <div className="perksBenefitsimage col-4">
                  <img src={PK7} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Healthcare Activities</b>
                  </p>
                  <p>
                    By keeping the famous saying, ‘Health is Wealth’, in mind,
                    Triosoft organizes its own Healthcare Activities to make
                    sure that you are fit and in good health. The company also
                    finances medical packages for their employees from reputable
                    healthcare groups.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard2">
                <div className="perksBenefitsimage col-4">
                  <img src={PK8} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Take a Moment For Self-Care</b>
                  </p>
                  <p>
                    It is important to take a break in between work so that the
                    work can be done efficiently. The company looks after that
                    by giving off paid vacation leaves and providing fun
                    activities to take part in around the office to boost
                    productivity and create a work-life balance.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard2">
                <div className="perksBenefitsimage col-4">
                  <img src={PK9} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Above Average Salaries</b>
                  </p>
                  <p>
                    We offer salaries and annual packages based on the position
                    of your work by evaluation and recognition of skills within
                    you. We offer the best salary in the industry because the
                    employees of our company work the best out of themselves and
                    make their effort count.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard2">
                <div className="perksBenefitsimage col-4">
                  <img src={PK10} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Soft Skills Development</b>
                  </p>
                  <p>
                    Along with the technical skills, we also motivate our
                    employees to work on their communication skills so that they
                    get comfortable when interacting with the International
                    clients as well as at the company's premises.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-3 pt-5">
              <div className="perksBenefitscard2">
                <div className="perksBenefitsimage col-4">
                  <img src={PK11} alt="Card 1" />
                </div>
                <div className="text">
                  <p>
                    <b>Co-Finance Conference</b>
                  </p>
                  <p>
                    We encourage the growth and development of our employees and
                    hence, we manage and partially finance the budget of your
                    conference so that there is no difficulty for you to learn
                    from the best.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div ref={footerRef}>
        <Footer servicesRef={null} topRef={topRef} updateHomeData={null} />
      </div>
    </div>
  );
}

export default Career;
