import React, { useState, useEffect, useRef } from "react";
import ModalImg from "../assets/images/doneAnimaion.gif";
import "../Css/Modelpopup.css";
const ModelPopUp = ({ title, dis }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    openPopup();
  }, []);

  const openPopup = () => {
    if (popupRef.current) {
      popupRef.current.style.opacity = "1";
      popupRef.current.style.transform = "translate(-50%, -50%) scale(1)";
    }
    setIsOpen(true);
  };

  const closePopup = () => {
    if (popupRef.current) {
      popupRef.current.style.opacity = "0";
      popupRef.current.style.transform = "translate(-50%, -50%) scale(0.8)";
    }
    setIsOpen(false);
  };
  return (
    <div>
      <div className={`popup-overlay ${isOpen ? "open" : ""}`}>
        <div
          ref={popupRef}
          className={`pup-cntnt pup-cntnt-width ${isOpen ? "open" : ""}`}
        >
          <div className="modal-content">
            <div className="modal-header" style={{ paddingBottom: "0" }}>
              <button
                type="button"
                className="close closeModay"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closePopup}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="modal-body modal-body-color text-center"
              style={{ paddingTop: "0", paddingBottom: "0" }}
            >
              <div className="text-center">
                <img src={ModalImg} alt="icon" className="model-image" />
              </div>
              {"We’re thrilled to have received your application and wanted to take a moment to say thanks for showing interest in joining our team!"}
              {/* {"Thank you for subscribing to our newsletter! Get ready for exclusive updates straight to your inbox!"} */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary closeFooterModal"
                data-dismiss="modal"
                onClick={closePopup}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModelPopUp;
