// import React, { useState } from 'react';
// import "../Css/TRASH.css";
// import dummy from "../assets/images/dummy.png";

// function Random() {
//     const [currentSlide, setCurrentSlide] = useState(1);

//     const handleButtonClick = (slideNumber) => {
//         setCurrentSlide(slideNumber);
//     };

//     const handleNextButtonClick = () => {
//         const nextSlide = (currentSlide % 4) + 1;
//         setCurrentSlide(nextSlide);
//     };

//     const handlePrevButtonClick = () => {
//         const prevSlide = (currentSlide - 2 + 4) % 4 + 1;
//         setCurrentSlide(prevSlide);
//     };

//     return (
//         <div className='bg_port'>
//             <div>
//                 <div className="btn_sldr">
//                     <button className="arrow-button" onClick={handlePrevButtonClick}>&#8249;</button>
//                     <button onClick={() => handleButtonClick(1)}>slide 1</button>
//                     <button onClick={() => handleButtonClick(2)}>slide 2</button>
//                     <button onClick={() => handleButtonClick(3)}>slide 3</button>
//                     <button onClick={() => handleButtonClick(4)}>slide 4</button>
//                     <button className="arrow-button" onClick={handleNextButtonClick}>&#8250;</button>
//                 </div>
//                 <div className="section full-height over-hide px-4 px-sm-0">
//                     <div className="container-lg">
//                         <div className="row full-height justify-content-center">
//                             <div className="align-self-center padding-tb">
//                                 <div className="section mx-auto text-center slider-height-padding">
//                                     <input className="checkbox frst" type="radio" id="slide-1" name="slider" checked={currentSlide === 1} />
//                                     <label htmlFor="slide-1"></label>
//                                     <input className="checkbox scnd" type="radio" id="slider-2" name="slider" checked={currentSlide === 2} />
//                                     <label htmlFor="slider-2"></label>
//                                     <input className="checkbox thrd" type="radio" id="slider-3" name="slider" checked={currentSlide === 3} />
//                                     <label htmlFor="slider-3"></label>
//                                     <input className="checkbox foth" type="radio" id="slider-4" name="slider" checked={currentSlide === 4} />
//                                     <label htmlFor="slider-4"></label>
//                                     <ul>
//                                         <li>
//                                             <span>
//                                                 <div>
//                                                     <div className={`slide slide--${currentSlide}`}>
//                                                         <div>
//                                                             <div className="slider_prt">
//                                                                 <div className="container-lg">
//                                                                     <div className="row rowReverse1 m-0">
//                                                                         <div className="col-sm-3">
//                                                                             <div className="tst_img">
//                                                                                 <img src={dummy} alt="My logo" />
//                                                                             </div>
//                                                                         </div>
//                                                                         <div className="col-sm-8">
//                                                                             <div className="sldr_txt">
//                                                                                 <div>
//                                                                                     <div className="sldr_dt">
//                                                                                         <h3>testimony{currentSlide}</h3>
//                                                                                         <p>
//                                                                                             {2015 + currentSlide}
//                                                                                         </p>
//                                                                                         <p>
//                                                                                             We started with just 10 employees working passionately with a vision to grow. With utmost dedication, we bagged our first brand project within a month of our incorporation. Set the grounds for success and unprecedented opportunities.
//                                                                                         </p>
//                                                                                     </div>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </span>
//                                         </li>
//                                         <li>
//                                             <span>
//                                                 <div>
//                                                     <div className={`slide slide--${currentSlide}`}>
//                                                         <div>
//                                                             <div className="slider_prt">
//                                                                 <div className="container-lg">
//                                                                     <div className="row rowReverse1 m-0">
//                                                                         <div className="col-sm-3">
//                                                                             <div className="tst_img">
//                                                                                 <img src={dummy} alt="My logo" />
//                                                                             </div>
//                                                                         </div>
//                                                                         <div className="col-sm-8">
//                                                                             <div className="sldr_txt">
//                                                                                 <div>
//                                                                                     <div className="sldr_dt">
//                                                                                         <h3>testimony{currentSlide}</h3>
//                                                                                         <p>
//                                                                                             {2015 + currentSlide}
//                                                                                         </p>
//                                                                                         <p>
//                                                                                             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque hendrerit semper neque, ut feugiat tellus sagittis nec. Fusce eleifend aliquam urna, non varius odio convallis eget. Proin eget tincidunt leo. Sed vel mauris at justo ultrices gravida. Suspendisse potenti. Vivamus tincidunt purus id massa sagittis, id cursus libero interdum. Curabitur auctor, elit ut convallis commodo, nisi velit tincidunt justo, vel scelerisque nulla dui id orci. Maecenas vehicula, odio at feugiat vestibulum, quam dolor laoreet elit, in ultricies purus velit a felis.
//                                                                                         </p>
//                                                                                     </div>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </span>
//                                         </li>
//                                         <li>
//                                             <span>
//                                                 <div>
//                                                     <div className={`slide slide--${currentSlide}`}>
//                                                         <div>
//                                                             <div className="slider_prt">
//                                                                 <div className="container-lg">
//                                                                     <div className="row rowReverse1 m-0">
//                                                                         <div className="col-sm-3">
//                                                                             <div className="tst_img">
//                                                                                 <img src={dummy} alt="My logo" />
//                                                                             </div>
//                                                                         </div>
//                                                                         <div className="col-sm-8">
//                                                                             <div className="sldr_txt">
//                                                                                 <div>
//                                                                                     <div className="sldr_dt">
//                                                                                         <h3>testimony{currentSlide}</h3>
//                                                                                         <p>
//                                                                                             {2015 + currentSlide}
//                                                                                         </p>
//                                                                                         <p>
//                                                                                             Vivamus tempus tortor eget elit efficitur cursus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Integer fringilla leo et leo volutpat, id fermentum justo fermentum. Nunc gravida, mi quis ultricies tincidunt, sapien ligula hendrerit orci, in tincidunt ligula felis in mi. Duis consequat massa ac orci hendrerit, vitae mattis sapien scelerisque. Nulla facilisi. Praesent eget risus nec lectus scelerisque tincidunt vel vitae nunc. Suspendisse potenti. Integer consequat justo vel orci feugiat, nec egestas metus vulputate.</p>
//                                                                                     </div>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </span>
//                                         </li>
//                                         <li>
//                                             <span>
//                                                 <div>
//                                                     <div className={`slide slide--${currentSlide}`}>
//                                                         <div>
//                                                             <div className="slider_prt">
//                                                                 <div className="container-lg">
//                                                                     <div className="row rowReverse1 m-0">
//                                                                         <div className="col-sm-3">
//                                                                             <div className="tst_img">
//                                                                                 <img src={dummy} alt="My logo" />
//                                                                             </div>
//                                                                         </div>
//                                                                         <div className="col-sm-8">
//                                                                             <div className="sldr_txt">
//                                                                                 <div>
//                                                                                     <div className="sldr_dt">
//                                                                                         <h3>testimony{currentSlide}</h3>
//                                                                                         <p>
//                                                                                             {2015 + currentSlide}
//                                                                                         </p>
//                                                                                         <p>
//                                                                                             We started with just 10 employees working passionately with a vision to grow. With utmost dedication, we bagged our first brand project within a month of our incorporation. Set the grounds for success and unprecedented opportunities.
//                                                                                         </p>
//                                                                                     </div>
//                                                                                 </div>
//                                                                             </div>
//                                                                         </div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </span>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Random;

// import React, { useState } from 'react';
// import "../Css/TRASH.css";
// import landing_img1 from "../assets/images/landing_img1.png";
// import landing_img2 from "../assets/images/landing_img2.png";
// import landing_img3 from "../assets/images/landing_img3.png";
// import landing_img4 from "../assets/images/landing_img4.png";
// import landing_img5 from "../assets/images/landing_img5.png";
// import landing_img6 from "../assets/images/landing_img6.png";


// function TRASH() {
//     const Popup = () => {
//         const [isOpen, setIsOpen] = useState(false);

//         const openPopup = () => {
//             setIsOpen(true);
//         };

//         const closePopup = () => {
//             setIsOpen(false);
//         };

//         const openCookieSettings = () => {
//             // Open the cookie settings page here.
//         };

//         return (
//             <div>
//                 <div className='dp_text'>
//                     <h4>
//                         Our Development<br />
//                         Process
//                     </h4>
//                 </div>
//                 <div className="home_dp">
//                     <div className="row rowReverse1 m-0">
//                         <div className="col-sm-6">
//                             <div className="dp_txt">
//                                 <div>
//                                     <div className="col-xl-3 col-md-4 col-6 mb-3">
//                                     </div>
//                                     <div className="dp_txtCOnt">
//                                         <h3>priyansh sharma</h3>
//                                         <p>
//                                             Deploy ezeseed is an online platform that gives angel
//                                             investors a medium to discover, research and make
//                                             investments in startups.
//                                         </p>
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>
//                         <div className=" col-sm-6">
//                             <div className="home_img">
//                                 <img
//                                     src={landing_img1}

//                                     alt="My logo"
//                                     className="hme_img"
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="home_dp">
//                     <div className="row rowReverse2 m-0">
//                         <div className=" col-sm-6">
//                             <div className="Casestudyimg">
//                                 <img
//                                     src={landing_img2}
//                                     alt="My logo"
//                                     className="hme_img"
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-6">
//                             <div className="dp_txt">
//                                 <div>
//                                     <div className="col-xl-3 col-md-4 col-6 mb-3">
//                                     </div>
//                                     <div className="dp_txtCOnt">
//                                         <h3>priyansh sharma</h3>
//                                         <p>
//                                             Lorem Ipsum is simply dummy text of the printing and
//                                             typesetting industry. Lorem Ipsum has been the
//                                             industry's standard dummy text ever since the 1500s,
//                                             when an unknown printer
//                                         </p>
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="home_dp">
//                     <div className="row rowReverse1 m-0">
//                         <div className="col-sm-6">
//                             <div className="dp_txt">
//                                 <div>
//                                     <div className="col-xl-3 col-md-4 col-6 mb-3">
//                                     </div>
//                                     <div className="dp_txtCOnt">
//                                         <h3>priyansh sharma</h3>
//                                         <p>
//                                             Deploy ezeseed is an online platform that gives angel
//                                             investors a medium to discover, research and make
//                                             investments in startups.
//                                         </p>
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>
//                         <div className=" col-sm-6">
//                             <div className="home_img">
//                                 <img
//                                     src={landing_img3}

//                                     alt="My logo"
//                                     className="hme_img"
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="home_dp">
//                     <div className="row rowReverse2 m-0">
//                         <div className=" col-sm-6">
//                             <div className="Casestudyimg">
//                                 <img
//                                     src={landing_img4}
//                                     alt="My logo"
//                                     className="hme_img"
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-6">
//                             <div className="dp_txt">
//                                 <div>
//                                     <div className="col-xl-3 col-md-4 col-6 mb-3">
//                                     </div>
//                                     <div className="dp_txtCOnt">
//                                         <h3>priyansh sharma</h3>
//                                         <p>
//                                             Lorem Ipsum is simply dummy text of the printing and
//                                             typesetting industry. Lorem Ipsum has been the
//                                             industry's standard dummy text ever since the 1500s,
//                                             when an unknown printer
//                                         </p>
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="home_dp">
//                     <div className="row rowReverse1 m-0">
//                         <div className="col-sm-6">
//                             <div className="dp_txt">
//                                 <div>
//                                     <div className="col-xl-3 col-md-4 col-6 mb-3">
//                                     </div>
//                                     <div className="dp_txtCOnt">
//                                         <h3>priyansh sharma</h3>
//                                         <p>
//                                             Deploy ezeseed is an online platform that gives angel
//                                             investors a medium to discover, research and make
//                                             investments in startups.
//                                         </p>
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>
//                         <div className=" col-sm-6">
//                             <div className="home_img">
//                                 <img
//                                     src={landing_img5}

//                                     alt="My logo"
//                                     className="hme_img"
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="home_dp">
//                     <div className="row rowReverse2 m-0">
//                         <div className=" col-sm-6">
//                             <div className="Casestudyimg">
//                                 <img
//                                     src={landing_img6}
//                                     alt="My logo"
//                                     className="hme_img"
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-6">
//                             <div className="dp_txt">
//                                 <div>
//                                     <div className="col-xl-3 col-md-4 col-6 mb-3">
//                                     </div>
//                                     <div className="dp_txtCOnt">
//                                         <h3>priyansh sharma</h3>
//                                         <p>
//                                             Lorem Ipsum is simply dummy text of the printing and
//                                             typesetting industry. Lorem Ipsum has been the
//                                             industry's standard dummy text ever since the 1500s,
//                                             when an unknown printer
//                                         </p>
//                                     </div>

//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
                // <div className="cache_tag_fttr">
                //     <p>Copyright &copy; 2023. All rights reserved.</p>
                //     <p><a href="/terms-of-use">Terms of Use</a> | <a href="/cookie-policy">Cookie Policy</a> | <a href="/privacy-policy">Privacy Policy</a> | <a href="/sitemap">Sitemap</a></p>
                // </div>
//                 <div className={`popup ${isOpen ? 'show' : ''}`}>
//                     <div className="popup-content">
//                         <h2>Cookie Policy</h2>
//                         <p>
//                             We use cookies to personalize content and ads, to provide social media
//                             features, and to analyze our website traffic. We also share information
//                             about your use of our site with our trusted social media, advertising,
//                             and analytics partners. You can learn more about our Cookie Policy at
//                             [link to Cookie Policy page].
//                         </p>
//                         <div className="popup-buttons">
//                             <button className="btn btn-primary" onClick={closePopup}>
//                                 Accept All Cookies
//                             </button>
//                             <button className="btn btn-secondary" onClick={openCookieSettings}>
//                                 Cookies Settings
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }
// export default TRASH

// import React, { useState, useRef } from 'react';

// const Popup = () => {
//     const [isOpen, setIsOpen] = useState(false);
//     const popupRef = useRef(null);

//     const openPopup = () => {
//         if (popupRef.current) {
//             popupRef.current.style.display = 'block';
//         }
//         setIsOpen(true);
//     };

//     const closePopup = () => {
//         if (popupRef.current) {
//             popupRef.current.style.display = 'none';
//         }
//         setIsOpen(false);
//     };

//     const handleOverlayClick = (event) => {
//         if (event.target !== popupRef.current) {
//             closePopup();
//         }
//     };

//     return (
//         <>
//             <div className="popup-overlay" onClick={handleOverlayClick}>
//                 {isOpen && (
//                     <div ref={popupRef} className="popup-content">
//                         <h2>Cookie Policy</h2>
//                         <p>
//                             We use cookies to personalize content and ads, to provide social
//                             media features, and to analyze our website traffic. We also share
//                             information about your use of our site with our trusted social media,
//                             advertising, and analytics partners. You can learn more about our
//                             Cookie Policy at <a href="#">[link to Cookie Policy page]</a>.
//                         </p>
//                         <div className="popup-buttons">
//                             <button className="btn btn-primary" onClick={closePopup}>
//                                 Accept All Cookies
//                             </button>
//                             <button className="btn btn-secondary" onClick={closePopup}>
//                                 Cookies Settings
//                             </button>
//                         </div>
//                     </div>
//                 )}
//             </div>
//             <button className="open-popup-button" onClick={openPopup}>
//                 Open Popup
//             </button>
//         </>
//     );
// };

// export default Popup;

// import React, { useState, useRef } from 'react';
// import "../Css/TRASH.css";

// const Popup = () => {
//     const [isOpen, setIsOpen] = useState(false);
//     const popupRef = useRef(null);

//     const openPopup = () => {
//         if (popupRef.current) {
//             popupRef.current.style.opacity = '1';
//             popupRef.current.style.transform = 'translate(-50%, -50%) scale(1)';
//         }
//         setIsOpen(true);
//     };

//     const closePopup = () => {
//         if (popupRef.current) {
//             popupRef.current.style.opacity = '0';
//             popupRef.current.style.transform = 'translate(-50%, -50%) scale(0.8)';
//         }
//         setIsOpen(false);
//     };

//     const handleOverlayClick = (event) => {
//         if (event.target !== popupRef.current) {
//             closePopup();
//         }
//     };

//     return (
//         <>
//             <div className={`popup-overlay ${isOpen ? 'open' : ''}`} onClick={handleOverlayClick}>
//                 <div ref={popupRef} className={`popup-content ${isOpen ? 'open' : ''}`}>
//                     <h2>Cookie Policy</h2>
//                     <p>
//                         We use cookies to personalize content and ads, to provide social
//                         media features, and to analyze our website traffic. We also share
//                         information about your use of our site with our trusted social media,
//                         advertising, and analytics partners. You can learn more about our
//                         Cookie Policy at <a href="#">[link to Cookie Policy page]</a>.
//                     </p>
//                     <div className="popup-buttons">
//                         <button className="btn btn-primary" onClick={closePopup}>
//                             Accept All Cookies
//                         </button>
//                         <button className="btn btn-secondary" onClick={closePopup}>
//                             Cookies Settings
//                         </button>
//                     </div>
//                 </div>
//             </div>
//             <button className="open-popup-button" onClick={openPopup}>
//                 Open Popup
//             </button>
//         </>
//     );
// };

// export default Popup;

// import React, { useState, useRef, useEffect } from 'react';
// import "../Css/TRASH.css";

// const Popup = () => {
//     const [isOpen, setIsOpen] = useState(false);
//     const popupRef = useRef(null);

//     useEffect(() => {
//         const acceptedCookies = localStorage.getItem('acceptedCookies');

//         if (!acceptedCookies) {
//             // Open the popup after 4 seconds
//             const timer = setTimeout(() => {
//                 openPopup();
//             }, 4000);

//             return () => clearTimeout(timer);
//         }
//     }, []);

//     const openPopup = () => {
//         if (popupRef.current) {
//             popupRef.current.style.opacity = '1';
//             popupRef.current.style.transform = 'translate(-50%, -50%) scale(1)';
//         }
//         setIsOpen(true);
//     };

//     const closePopup = () => {
//         if (popupRef.current) {
//             popupRef.current.style.opacity = '0';
//             popupRef.current.style.transform = 'translate(-50%, -50%) scale(0.8)';
//         }
//         setIsOpen(false);

//         // Save in localStorage that the user has accepted cookies
//         localStorage.setItem('acceptedCookies', 'true');
//     };

//     const handleOverlayClick = (event) => {
//         if (event.target !== popupRef.current) {
//             closePopup();
//         }
//     };

//     return (
//         <>
//             <div className={`popup-overlay ${isOpen ? 'open' : ''}`} onClick={handleOverlayClick}>
//                 <div ref={popupRef} className={`pup-cntnt ${isOpen ? 'open' : ''}`}>
//                         {/* <h2>Cookie Policy</h2> */}
//                     <p>
//                         We use cookies to personalize content and ads, to provide social
//                         media features, and to analyze our website traffic. We also share
//                         information about your use of our site with our trusted social media,
//                         advertising, and analytics partners. You can learn more about our
//                         Cookie Policy at <a href="#">[link to Cookie Policy page]</a>.
//                     </p>
//                     <div className="popup-buttons">
//                         <button className="bttn bttn-prmry" onClick={closePopup}>
//                             Accept All Cookies
//                         </button>
//                         <button className="bttn bttn-scndry " onClick={closePopup}>
//                             Cookies Settings
//                         </button>
//                     </div>
//                 </div>
//             </div>
//             <button className="opn-pup-bttn" onClick={openPopup}>
//                 cache
//             </button>
//         </>
//     );
// };

// export default Popup;
// import React, { useState } from 'react';
// import UploadCVImg from "../assets/images/uplaodCV.svg";


// const YourComponent = () => {
//   const [dynaicimage, setDynamicImage] = useState({
//     profile_picture_show: false,
//     documentName: '',
//   });

//   const imageInputRef1 = React.createRef();

//   const handleFileChangedynamic = (fieldName) => (event) => {
//     const file = event.target.files[0];

//     // Assuming you want to display the document name
//     setDynamicImage({
//       ...dynaicimage,
//       profile_picture_show: true,
//       documentName: file.name,
//     });
//   };

//   return (
//     <div className="inputFormCareer CVuPLOAD col-md-12">
//       <div className="uploadTextImg">
//         <img src={UploadCVImg} alt="upload photo" />
//         <div className="uploadText">
//           {!dynaicimage.profile_picture_show && (
//             <>
//               <h6>UPload CV</h6>
//               <p>(Pdf. doc. PPT upto 1mb)</p>
//             </>
//           )}
//           {dynaicimage.profile_picture_show && (
//             <h6>{dynaicimage.documentName} Uploaded</h6>
//           )}
//         </div>
//       </div>
//       <label htmlFor="profile_picture">Browse</label>
//       <input
//         className="d-none"
//         type="file"
//         name="profile_picture"
//         id="profile_picture"
//         ref={imageInputRef1}
//         onChange={handleFileChangedynamic('profile_picture')}
//       />
//       <span className="condition_error"></span>
//     </div>
//   );
// };

// export default YourComponent;
// import React, { useState } from 'react';

// const YourComponent = () => {
//   const [showMore, setShowMore] = useState(false);

//   const toggleReadMore = () => {
//     setShowMore(!showMore);
//   };

//   return (
//     <div>
//       {/* Your initial content */}
//       <p>This is some content...</p>
//       <p>More content...</p>

//       {/* Additional content with conditional rendering based on showMore state */}
//       {showMore && (
//         <div>
//           <p>Additional content...</p>
//           <p>More additional content...</p>
//         </div>
//       )}

//       {/* Read more button */}
//       <button onClick={toggleReadMore}>
//         {showMore ? 'Read Less' : 'Read More'}
//       </button>
//     </div>
//   );
// };

// export default YourComponent;
import React, { useEffect, useState } from "react";
import "../Css/Portfolio.css";
import Header from "./Header";
import Footer from "./Footer";
import Slider from "react-slick";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import {
  server_post_data,
  case_studies_details_url,
} from "../ServiceConnection/serviceconnection.js";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";

function Portfolio() {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [data_front_image, setdata_front_image] = useState([]);
  const [data_test_image, setdata_test_image] = useState([]);
  const [VacancytData, setVacancytData] = useState([]);
  const [Testimonial, setTestimonial] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showMoreOne, setShowMoreOne] = useState(false);
  const [showMoreTwo, setShowMoreTwo] = useState(false);

  const handleFetchData = async () => {
    setShowLoader(true);

    var form_data = new FormData();
    form_data.append("current_url", "/"+currentUrl);
    await server_post_data(case_studies_details_url, form_data)
      .then((response) => response.json())
      .then((data_data) => {
        console.log(data_data);
        setShowLoader(false);
        setVacancytData(data_data.case_loop[0]);
        setdata_front_image(data_data.front_image);
        setdata_test_image(data_data.testimonial_image);
        setTestimonial(data_data.testimonial);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const toggleSeeMoreOne = () => {
    setShowMoreOne(!showMoreOne);
  };

  const toggleSeeMoreTwo = () => {
    setShowMoreTwo(!showMoreTwo);
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="company">
      <div className={showLoader ? "loading" : ""}></div>
      <div className="navStickyTop">
        <Header />
      </div>
      <div className="companyContainer">
        <div>
          {/* ... (existing code) */}
          <div className="container-lg">
            <div className="row">
              <div className="col-md-6">
                <div className="head_txt_btm">
                  <div>
                    <h4>About {VacancytData.title_name}</h4>
                  </div>
                  <div>
                    <p>{VacancytData.short_description}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 ct_bot">
                <div className="row ">
                  {/* ... (existing code) */}
                </div>
              </div>
            </div>
          </div>
          <div className="container-lg">
            <div className="row ">
              <div className="col-md-6">
                <p className="color_heading_p">
                  {showMoreOne
                    ? VacancytData.short_description_one
                    : VacancytData.short_description_one.substring(0, 100)}
                  {!showMoreOne &&
                    VacancytData.short_description_one.length > 100 && (
                      <span onClick={toggleSeeMoreOne} className="see-more-link">
                        ... See More
                      </span>
                    )}
                  {showMoreOne && (
                    <span onClick={toggleSeeMoreOne} className="see-more-link">
                      See Less
                    </span>
                  )}
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  {showMoreTwo
                    ? VacancytData.short_description_two
                    : VacancytData.short_description_two.substring(0, 100)}
                  {!showMoreTwo &&
                    VacancytData.short_description_two.length > 100 && (
                      <span onClick={toggleSeeMoreTwo} className="see-more-link">
                        ... See More
                      </span>
                    )}
                  {showMoreTwo && (
                    <span onClick={toggleSeeMoreTwo} className="see-more-link">
                      See Less
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div
            className="port_img_bg hue-img"
            style={{
              backgroundImage: `url(${data_front_image}${VacancytData.center_image})`,
            }}
          ></div>

          <div className="cht_bot_txt">
            <div className="txti_m_worxds">
              <div
                className="row"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(VacancytData.long_description),
                }}
              />
            </div>
          </div>
        </div>
        <div className="txti_m">
          <div className="frontBannner_container">
            <Slider {...settings}>
              {Testimonial.map((data, index) => (
                <div className={`slide slide--${index}`}>
                  {/* ... (existing code) */}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="show_back_color">
        <Footer />
      </div>
    </div>
  );
}

export default Portfolio;
