import React, { useState, useEffect, useRef } from "react";
import UploadCVImg from "../assets/images/uplaodCV.svg";
import "../Css/Career.css";
import scroll from "../assets/images/arrow.png";
// import '../Css/EmployResgistrationform.css'
import {
  server_post_data,
  save_emp_reg,
  view_partial_emp_reg,
} from "../ServiceConnection/serviceconnection.js";
import { Form } from "react-router-dom";
import {
  check_vaild_save,
  combiled_form_data,
} from "../CommonJquery/CommonJquery.js";

import Loader from "./Loader.js";
import ModelPopUp from "./ModelPopUp.js";

let final_v_id = 0;
function EmployResgistrationform(data_for_id) {
  final_v_id = data_for_id.dynamic_id;
  console.log(final_v_id);
  

  const [isPersonalDetailsOpen, setIsPersonalDetailsOpen] = useState(false);
  const [isPayrollOpen, setIsPayrollOpen] = useState(false);
  const [isBankDetails, setIsBankDetails] = useState(false);
  const [isDeclaration, setIsDeclartion] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [dynaicimage, setDynaicimage] = useState(null);
  const [details, setDetails] = useState(false);
  const imageInputRef = useRef(null);

  const handlePersonalDetailsClick = () => {
    setIsPersonalDetailsOpen(!isPersonalDetailsOpen);
  };
  const handlePayrollOpenClick = () => {
    setIsPayrollOpen(!isPayrollOpen);
  };
  const handleBankDetailsClick = () => {
    setIsBankDetails(!isBankDetails);
  };
  const handleDeclarationClick = () => {
    setIsDeclartion(!isDeclaration);
  };
  const [hasPF, setHasPF] = useState(false);

  const handlePFSelection = (e) => {
    console.log(e.target.defaultValue);
    setHasPF(e.target.value === "yes");
  };

  const footerRef = useRef(null);

  const handleSaveChangesdynamic = async (form_data, save_emp_reg) => {
    console.log(form_data);
    let vaild_data = check_vaild_save(form_data);

    setShowLoader(true);
    let fd_from = combiled_form_data(form_data, dynaicimage);
    // fd_from.append("final_v_id", final_v_id);
    fd_from.append("v_id", final_v_id);
    await server_post_data(save_emp_reg, fd_from)
      .then((responsessss) => responsessss.json())
      .then((Response) => {
        alert(Response)
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
        console.log(error);
      });
  };

  const [fileError, setFileError] = useState("");

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
          [keyname + "_name"]: file.name, // Add this line to store the file name
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: null,
        [new_file_name]: null,
        [keyname + "_name"]: null, // Add this line to reset the file name
      }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 1048576) {
      setFileError("File size exceeds 1MB limit.");
    } else {
      setFileError("");
      handleFileChangedynamic("pass_pic")(e);
    }
  };

  return (
    <div className="careerFormInputs container-lg mt-5">
      {showLoader && <Loader />}
      <form id="EmployfromData" className="col-lg-10 m-auto">
        <div className="container">
          <div className="row">
            <div className="panel-group" id="accordion">
              <div className="panel panel-default details-form ">
                <div className="panel-heading">
                  <h4 className="panel-title d-flex align-items-center justify-content-between">
                    <button
                      className="accordion-toggle btn btn-link details-inp accordionBtn"
                      onClick={handlePersonalDetailsClick}
                      type="button"
                      aria-expanded={isPersonalDetailsOpen ? "true" : "false"}
                    >
                      Personal Details
                    </button>
                    <img
                      src={scroll}
                      onClick={handlePersonalDetailsClick}
                      aria-expanded={isPersonalDetailsOpen ? "true" : "false"}
                      className="scroll-icon"
                      alt="triosoft ai"
                    />
                  </h4>
                </div>
                <div
                  className={`panel-collapse collapse ${
                    isPersonalDetailsOpen ? "show" : ""
                  }`}
                >
                  <div className="panel-body details-from-input">
                    <div className="row">
                      <div className="inputFormCareer col-md-6 mt-4">
                        <div className="mb-2">
                          <label htmlFor="name">
                            <b>Name</b>
                          </label>
                          <input
                            type="text"
                            className="trio_mendate trio_name form-control"
                            id="e_name"
                            name="name"
                            maxLength="50"
                            placeholder="Name"
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                          />
                          <span className="condition_error"></span>
                        </div>
                      </div>
                      <div className=" inputFormCareer col-md-6 mt-md-4">
                        <div className="mb-2">
                          <label htmlFor="text">
                            <b>Address</b>
                          </label>
                          <input
                            type="text"
                            className="trio_mendate  form-control "
                            id="addr"
                            name="addr"
                            maxLength="100"
                            placeholder="Address"
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                          />
                          <span className="condition_error"></span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="inputFormCareer col-md-6 mt-0">
                        <div className="mb-2">
                          <label
                            className="registration-input"
                            htmlFor="mobile"
                          >
                            <b>Mobile Number</b>
                          </label>
                          <input
                            type="text"
                            className="trio_mendate trio_no form-control "
                            id="mobile"
                            name="mobile"
                            maxLength="10"
                            placeholder="Mobile Number"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                          />
                          <span className="condition_error"></span>
                        </div>
                      </div>
                      <div className="inputFormCareer col-md-6 mt-0 ">
                        <div className="mb-2">
                          <label className="registration-input" htmlFor="Email">
                            <b>Email Address</b>
                          </label>
                          <input
                            type="text"
                            className="trio_mendate form-control "
                            id="email"
                            name="email"
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                            placeholder="Email Address"
                          />
                          <span className="condition_error"></span>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="inputFormCareer col-md-6 mt-0">
                        <div className="mb-2">
                          <label
                            className="registration-input"
                            htmlFor="date of birth"
                          >
                            <b>Date of Birth</b>
                          </label>
                          <input
                            type="date"
                            className="trio_mendate form-control "
                            id="dob"
                            name="dob"
                            // defaultValue={details.dob}
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                            placeholder="DOB"
                          />
                          <span className="condition_error"></span>
                        </div>
                      </div>
                      <div className="inputFormCareer col-md-6 mt-2 registration-input">
                        <div className="mb-3 align-items-center">
                          <label for="gender">
                            <b>Gender</b>
                          </label>
                          <br></br>
                          <div className="d-flex align-items-center check-box-gap">
                            <input
                              type="radio"
                              class="ajax_save"
                              name="gender"
                              onInput={(e) => {
                                e.target.value = e.target.value;
                              }}
                              id="gender"
                              value="male"
                            />{" "}
                            Male
                            <input
                              type="radio"
                              class="ajax_save"
                              onInput={(e) => {
                                e.target.value = e.target.value;
                              }}
                              name="gender"
                              id="gender"
                              value="female"
                            />{" "}
                            Female
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="inputFormCareer CVuPLOAD col-md-12">
                      <div className="uploadTextImg d-flex flex-column">
                        <img src={UploadCVImg} alt="upload photo" />
                        <div className="uploadText">
                          {!dynaicimage && (
                            <>
                              <h6>Passport Picture</h6>
                              {/* <h6>UPload CV</h6>
                              <p>(Pdf, doc, PPT upto 1MB)</p> */}
                            </>
                          )}
                          {dynaicimage && dynaicimage.pass_pic_show && (
                            <h6>{dynaicimage.pass_pic_name} Uploaded</h6>
                          )}
                        </div>
                      </div>
                      <label
                        htmlFor="profile_picture"
                        style={{ cursor: "pointer" }}
                      >
                        Browse
                      </label>

                      <input
                        className="d-none "
                        type="file"
                        name="profile_picture"
                        ref={imageInputRef}
                        onChange={handleFileChange}
                        id="profile_picture"
                        accept=".pdf, .doc, .ppt, .pptx, .png, .jpg, .webp, .jpeg"
                      />

                      {fileError && (
                        <span className="fileUpload_error text-danger">
                          {fileError}
                        </span>
                      )}
                    </div>

                    <div className="row">
                      <div className="inputFormCareer col-md-6 mt-2 registration-input">
                        <div className="mb-3">
                          <label htmlFor="blood group">
                            <b>Blood Group</b>
                          </label>
                          <select
                            class="form-control ajax_save trio_mandatory_image"
                            name="bg_group"
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                          >
                            <option value="">Select Blood Group </option>
                            <option value="A+">A-positive (A+)</option>
                            <option value="A-">A-negative (A-)</option>
                            <option value="B+">B-positive (B+)</option>
                            <option value="B-">B-negative (B-)</option>
                            <option value="AB+">AB-positive (AB+)</option>
                            <option value="AB-">AB-negative (AB-)</option>
                            <option value="O+">O-positive (O+)</option>
                            <option value="O-">O-negative (O-)</option>
                          </select>
                          <span className="condition_error"></span>
                        </div>
                      </div>
                      <div className="inputFormCareer col-md-6 mt-2 registration-input">
                        <div className="mb-3">
                          <label htmlFor="shirt size">
                            <b>Shirt Size</b>
                          </label>
                          <select
                            class="form-control ajax_save trio_mandatory_image"
                            name="shirt_size"
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                          >
                            <option value="">Select Shirt Size </option>
                            <option value="S">S</option>
                            <option value="M">M</option>
                            <option value="L">L</option>
                            <option value="XL">XL</option>
                            <option value="XXL">XXL</option>
                            <option value="XXXL">XXXL</option>
                          </select>
                          <span className="condition_error"></span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <h5 className="em-text"> Emergency Contact Details</h5>
                      <div className="inputFormCareer col-md-6 mt-2 registration-input">
                        <div className="mb-3">
                          <label htmlFor="expctd_CTC">
                            <b>Contact Number</b>
                          </label>
                          <input
                            type="text"
                            className=" trio_mendate form-control "
                            id="r_contact_no"
                            name="r_contact_no"
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                            placeholder=" Enter Contact Number"
                            maxLength="10"
                          />
                        </div>
                      </div>

                      <div className="inputFormCareer col-md-6 mt-2 registration-input">
                        <div className="mb-3">
                          <label htmlFor="Relationship">
                            <b>Relationship To You</b>
                          </label>
                          <input
                            id="relationship"
                            name="relationship"
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                            className="form-select form-control trio_mendate  "
                            placeholder="Enter Relationship To You"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel panel-default details-form mt-2">
                <div className="panel-heading">
                  <h4 className="panel-title d-flex align-items-center justify-content-between">
                    <button
                      className="accordion-toggle btn btn-link details-inp accordionBtn"
                      onClick={handlePayrollOpenClick}
                      type="button"
                      aria-expanded={handlePayrollOpenClick ? "true" : "false"}
                    >
                      Payroll Details
                    </button>
                    <img
                      src={scroll}
                      onClick={handlePayrollOpenClick}
                      aria-expanded={handlePayrollOpenClick ? "true" : "false"}
                      className="scroll-icon"
                      alt="triosoft ai"
                    />
                  </h4>
                </div>
                <div
                  className={`panel-collapse collapse ${
                    isPayrollOpen ? "show" : ""
                  }`}
                >
                  <div className="panel-body details-from-input">
                    <div class="panel-body">
                      <div class="panel-body pt-4">
                        <div class="row">
                          <div class="col-md-12">
                            <label>Do you have a PF account?</label>
                            <br />
                            <div className="d-flex align-items-center check-box-gap">
                              <input
                                type="radio"
                                class="ajax_save"
                                name="p45"
                                onInput={(e) => {
                                  e.target.value = e.target.value;
                                }}
                                id="pEyes"
                                value="yes"
                                onClick={handlePFSelection}
                              />{" "}
                              Yes <br />
                            </div>
                            <div className="d-flex align-items-center check-box-gap">
                              <input
                                type="radio"
                                class="ajax_save"
                                name="p45"
                                onInput={(e) => {
                                  e.target.value = e.target.value;
                                }}
                                id="chkNo"
                                value="no"
                                onClick={handlePFSelection}
                              />{" "}
                              No
                            </div>
                            {hasPF && (
                              <div id="dvtext">
                                Account Number:
                                <br />
                                <div class="row">
                                  <div class="col-md-6 pfacc-inp">
                                    <input
                                      type="text"
                                      class="form-control"
                                      palceholader="Enter Your PE Account detail"
                                      id="pe_detail"
                                      name="pe_detail"
                                      onInput={(e) => {
                                        e.target.value = e.target.value;
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel panel-default details-form">
                <div className="panel-heading">
                  <h4 className="panel-title d-flex align-items-center justify-content-between">
                    <button
                      className="accordion-toggle btn btn-link details-inp accordionBtn"
                      onClick={handleBankDetailsClick}
                      type="button"
                      aria-expanded={handleBankDetailsClick ? "true" : "false"}
                    >
                      Bank Details
                    </button>
                    <img
                      src={scroll}
                      onClick={handleBankDetailsClick}
                      aria-expanded={handleBankDetailsClick ? "true" : "false"}
                      className="scroll-icon"
                      alt="triosoft ai"
                    />
                  </h4>
                </div>
                <div
                  className={`panel-collapse collapse ${
                    isBankDetails ? "show" : ""
                  }`}
                >
                  <div className="panel-body details-from-input">
                    <div class="panel-body">
                      <div class="row">
                        <div class="col-md-6 mt-4">
                          <label>Bank Name</label>{" "}
                          <input
                            class="form-control ajax_save trio_mandatory_image"
                            placeholder="Enter Bank Name"
                            name="bank_name"
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                            id="bank_name"
                          />
                        </div>
                        <div class="col-md-6 mt-md-4 mt-4">
                          <label>Account Holder's Name</label>
                          <input
                            class="form-control ajax_save trio_mandatory_image"
                            placeholder="Enter Account Holder Name"
                            name="acc_name"
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                            id="acc_name"
                          />
                        </div>
                        <div class="col-md-6 mt-4">
                          <label>Bank Branch</label>
                          <input
                            class="form-control ajax_save trio_mandatory_image"
                            placeholder=" Enter Bank Branch"
                            name="bank_adderss"
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                            id="bank_adderss"
                          />
                        </div>
                        <div class="col-md-6 mt-4">
                          <label>IFSC Code</label>
                          <input
                            class="form-control ajax_save trio_mandatory_image"
                            placeholder="Enter IFSC Code"
                            name="short_code"
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                            id="short_code"
                          />
                        </div>
                        <div class="col-md-6 mt-4">
                          <label>Account Number</label>
                          <input
                            class="form-control ajax_save trio_mandatory_image mobile acc_no"
                            placeholder=" Enter Account number"
                            name="acc_no"
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                            id="acc_no"
                          />
                        </div>
                        <div class="col-md-6 mt-4">
                          <label>Confirm Account Number</label>
                          <input
                            class="form-control ajax_save trio_mandatory_image mobile"
                            placeholder="Enter Confirm Account number"
                            name="confirm_acc_no"
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                            id="confirm_acc_no"
                          />
                        </div>
                        <div className="pfacc-inp mt-4">
                          <span class="messageshow" id="nnot_match">
                            Account and Confirm Account number should be same.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel panel-default details-form">
                <div className="panel-heading">
                  <h4 className="panel-title d-flex align-items-center justify-content-between">
                    <button
                      className="accordion-toggle btn btn-link details-inp accordionBtn"
                      type="button"
                      onClick={handleDeclarationClick}
                      aria-expanded={handleDeclarationClick ? "true" : "false"}
                    >
                      Declaration
                    </button>
                    <img
                      src={scroll}
                      onClick={handleDeclarationClick}
                      aria-expanded={handleDeclarationClick ? "true" : "false"}
                      className="scroll-icon"
                      alt="triosoft ai"
                    />
                  </h4>
                </div>
                <div
                  className={`panel-collapse collapse ${
                    isDeclaration ? "show" : ""
                  }`}
                >
                  <div className="panel-body details-from-input">
                    <div class="panel-body pt-4">
                      <h4>Declaration</h4>
                      <label>I declare that:</label>
                      <br />

                      <div className="check-box-gap d-flex align-items-center">
                        <input type="checkbox" /> I hereby affirm the accuracy
                        and completeness of the information provided in this
                        form.
                      </div>
                      <br />
                      <div className="check-box-gap d-flex align-items-center">
                        <input type="checkbox" /> Additionally, I commit to
                        adhering strictly to the terms and conditions outlined
                        in my offer letter.
                      </div>
                      <br />

                      <div className="row">
                        <input type="hidden" name="designatin" value="60" />
                        <div className="col-md-6 ">
                          <br />
                          <input
                            class="form-control ajax_save trio_mandatory_image"
                            type="date"
                            // value="2024-04-18"
                            name="d_date"
                            onInput={(e) => {
                              e.target.value = e.target.value;
                            }}
                            id="d_date"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sbmtbtnDiv mt-4">
          <button
            className="primary_btn "
            type="button"
            onClick={() =>
              handleSaveChangesdynamic("EmployfromData", save_emp_reg)
            }
          >
            <p>Submit</p>
            <span className="spancheckmaker">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M12.293 4.293a1 1 0 0 1 1.414 1.414l-7 7a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 10.586l6.293-6.293a1 1 0 0 1 1.414 0z" />
              </svg>
            </span>
          </button>
        </div>
      </form>
    </div>
  );
}

export default EmployResgistrationform;
