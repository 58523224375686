import React, { useEffect, useRef } from "react";
import p5, { RIGHT_ARROW, LEFT_ARROW, UP_ARROW, ENTER } from "p5"; // Importing constants

const SpaceShooter = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const sketch = (p) => {
      let ship;
      let asteroids = [];
      let energy = [];
      let debris = [];
      let gameLevel = 0;
      let message = "";

      p.setup = () => {
        const canvasParent = document.getElementById("sketch-container");
        const canvasWidth = canvasParent.offsetWidth;
        const canvasHeight = canvasParent.offsetHeight;
        const canvas = p.createCanvas(canvasWidth, canvasHeight);
        canvas.parent("sketch-container");
        p.textFont("Courier");
        ship = new Ship();
        initialize("", 2);
      };

      p.draw = () => {
        p.background(0);
        for (let i = debris.length - 1; i >= 0; i--) {
          debris[i].update();
          debris[i].render();
          if (debris[i].transparency <= 0) {
            debris.splice(i, 1);
          }
        }
        for (let i = energy.length - 1; i >= 0; i--) {
          energy[i].update();
          energy[i].render();
          energy[i].edges();
          if (ship.hit(energy[i]) && !ship.safe) {
            ship.safe = true;
            setTimeout(function () {
              ship.safe = !ship.safe;
            }, 2000);
            ship.getBonus();
            energy[i].alive = false;
          }
          if (energy[i].life <= 20) {
            energy[i].alive = false;
          }
          if (!energy[i].alive) {
            energy.splice(i, 1);
          }
        }
        if (asteroids.length === 0) {
          astnum += 3;
          initialize("You Win! Level up!", astnum);
        }
        for (let i = asteroids.length - 1; i >= 0; i--) {
          asteroids[i].render();
          asteroids[i].update();
          asteroids[i].edges();
          if (ship.hit(asteroids[i]) && !ship.safe) {
            ship.danger = true;
            setTimeout(function () {
              ship.danger = !ship.danger;
            }, 100);
            ship.getDamage(asteroids[i]);
            console.log("Damaging the shield " + ship.shieldLevel);
            asteroids[i].explode();
            asteroids.splice(i, 1);
          }
        }
        ship.update(); // Add this line to render the ship
        ship.render(); // Add this line to render the ship
        ship.edges(); // Add this line to render the ship
        ship.interface();
      };

      function initialize(messageText, newastnum) {
        message = messageText;
        gameLevel += 1;
        astnum = newastnum;
        basicinit();
      }

      function restart(messageText, newastnum) {
        ship.init();
        gameLevel = 1;
        asteroids = [];
        energy = [];
        message = messageText;
        astnum = newastnum;
        basicinit();
      }

      function basicinit() {
        for (let i = 0; i < astnum; i++) {
          asteroids.push(new Asteroid());
        }
        ship.shieldLevel = 100;
        ship.safe = true;
        setTimeout(function () {
          ship.safe = false;
          message = "";
        }, 4000);
      }

      function keyPressed(event) {
        if (ship) {
          if (event.key === " ") {
            ship.lasers.push(new Laser(ship.pos, ship.heading));
          } else if (event.code === "ArrowRight") {
            ship.setRotation(0.1);
          } else if (event.code === "ArrowLeft") {
            ship.setRotation(-0.1);
          } else if (event.code === "ArrowUp") {
            ship.boosting = true;
          } else if (event.code === "Enter" && message === "Game Over") {
            console.log("DAMN!!");
            restart("let's play again!", initastnum);
          }
        }
      }

      function keyReleased(event) {
        if (ship) {
          if (event.code === "ArrowRight" || event.code === "ArrowLeft") {
            ship.setRotation(0);
          } else if (event.code === "ArrowUp") {
            ship.boosting = false;
          }
        }
      }

      class Ship {
        constructor() {
          this.pos = p.createVector(p.width / 2, p.height / 2 + 50);
          this.vel = p.createVector(0, 0);
          this.r = 10;
          this.heading = 0;
          this.rotation = 0;
          this.boosting = false;
          this.lasers = [];
          this.shieldLevel = 100;
          this.shieldMax = 100;
          this.alive = true;
          this.danger = false;
          this.safe = true;
          this.score = 0;
        }

        interface() {
          p.textSize(14);
          p.fill(255);
          p.noStroke();
          p.text("Score = " + this.score, 50, 50);
          if (this.shieldLevel >= this.shieldMax) {
            p.text("Shield = " + this.shieldLevel, 50, 65);
          } else {
            p.text(
              "Shield = " +
                p.constrain(
                  p.round(this.shieldLevel),
                  0,
                  p.round(this.shieldLevel)
                ),
              50,
              65
            );
          }
          p.text("Level = " + gameLevel, 50, 80);
          if (message) {
            p.textSize(32);
            p.text(message, p.width / 2 - message.length * 10, p.height / 2);
          }
        }

        init() {
          this.pos = p.createVector(p.width / 2, p.height / 2 + 50);
          this.vel = p.createVector(0, 0);
          ship.alive = true;
          ship.score = 0;
          ship.shieldLevel = 100;
        }

        hit(obj) {
          let d = p.dist(this.pos.x, this.pos.y, obj.pos.x, obj.pos.y);
          if (d < this.r + obj.r) {
            return true;
          } else {
            return false;
          }
        }

        getDamage(obj) {
          let damount = obj.r;
          this.shieldLevel -= damount;
          if (this.shieldLevel <= 0) {
            this.explode();
          }
        }

        getBonus() {
          this.shieldLevel += 20;
          this.score += 20;
          this.shieldLevel = p.constrain(this.shieldLevel, 0, this.shieldMax);
        }

        explode() {
          let debrisVel = p5.Vector.random2D().mult(p.random(0.5, 1.5));
          let debrisNum = 50;
          generateDebris(this.pos, debrisVel, debrisNum);
          this.alive = false;
        }

        update() {
          this.pos.add(this.vel);
          this.vel.mult(0.99);
          this.turn();
          if (this.boosting) {
            this.boost();
          }
          for (let i = this.lasers.length - 1; i >= 0; i--) {
            this.lasers[i].render();
            this.lasers[i].update();
            if (this.lasers[i].offscreen()) {
              this.lasers.splice(i, 1);
            } else {
              for (let j = asteroids.length - 1; j >= 0; j--) {
                if (this.lasers[i].hits(asteroids[j])) {
                  let debrisVel = p5.Vector.add(
                    this.lasers[i].vel.mult(0.2),
                    asteroids[j].vel
                  );
                  let debrisNum = asteroids[j].r * 5;
                  generateDebris(asteroids[j].pos, debrisVel, debrisNum);
                  let newAsteroids = asteroids[j].breakup();
                  if (newAsteroids.length > 0) {
                    let probability = p.random() * 100;
                    if (probability > 80) {
                      generateEnergy(asteroids[j].pos, debrisVel);
                    }
                    asteroids = asteroids.concat(newAsteroids);
                  } else {
                    this.score += 10;
                  }
                  asteroids.splice(j, 1);
                  this.lasers.splice(i, 1);
                  break;
                }
              }
            }
          }
        }

        boost() {
          let boostForce = p5.Vector.fromAngle(this.heading);
          boostForce.mult(0.1);
          this.vel.add(boostForce);
        }

        render() {
          p.push();
          p.translate(this.pos.x, this.pos.y);
          p.rotate(this.heading + p.PI / 2);
          p.fill(0);
          p.color(0, 255, 0);
          if (this.boosting) {
            p.stroke(255, 0, 0);
            p.line(-this.r + 3, this.r + 3, this.r - 3, this.r + 3);
          }
          if (this.danger) {
            p.stroke(255, 0, 0);
          } else if (this.safe) {
            p.stroke(0, 255, 0);
          } else {
            p.stroke(0, 255, 0);
          }
          p.triangle(-this.r, this.r, this.r, this.r, 0, -this.r);
          p.pop();
        }

        edges() {
          if (this.pos.x > p.width + this.r) {
            this.pos.x = -this.r;
          } else if (this.pos.x < -this.r) {
            this.pos.x = p.width + this.r;
          }
          if (this.pos.y > p.height + this.r) {
            this.pos.y = -this.r;
          } else if (this.pos.y < -this.r) {
            this.pos.y = p.height + this.r;
          }
        }

        setRotation(angle) {
          this.rotation = angle;
        }

        turn(angle) {
          this.heading += this.rotation;
        }
      }

      class Laser {
        constructor(spos, angle) {
          this.pos = p.createVector(spos.x, spos.y);
          this.vel = p5.Vector.fromAngle(angle);
          this.vel.mult(10);
          this.r = 1;
          this.color = p.color(255);
        }

        hits(target) {
          let d = p.dist(this.pos.x, this.pos.y, target.pos.x, target.pos.y);
          if (d < this.r + target.r) {
            return true;
          } else {
            return false;
          }
        }

        update() {
          this.pos.add(this.vel);
        }

        render() {
          p.push();
          p.strokeWeight(2);
          p.stroke(255, 0, 0);
          p.point(this.pos.x, this.pos.y);
          p.pop();
        }

        offscreen() {
          if (
            this.pos.x > p.width + this.r ||
            this.pos.x < -this.r ||
            this.pos.y > p.height + this.r ||
            this.pos.y < -this.r
          ) {
            return true;
          } else {
            return false;
          }
        }
      }

      class Energy {
        constructor(pos, vel) {
          this.pos = pos.copy();
          this.vel = vel.copy();
          this.vel.mult(-0.2);
          this.r = 10;
          this.life = p.random(100, 300);
          this.alive = true;
        }

        update() {
          this.pos.add(this.vel);
          this.life -= 0.2;
        }

        render() {
          if (this.life > 20) {
            p.noFill();
            p.stroke(0, 0, 255);
            p.ellipse(this.pos.x, this.pos.y, this.r, this.r);
          }
        }

        edges() {
          if (this.pos.x > p.width + this.r) {
            this.pos.x = -this.r;
          } else if (this.pos.x < -this.r) {
            this.pos.x = p.width + this.r;
          }
          if (this.pos.y > p.height + this.r) {
            this.pos.y = -this.r;
          } else if (this.pos.y < -this.r) {
            this.pos.y = p.height + this.r;
          }
        }
      }

      function generateEnergy(pos, vel) {
        energy.push(new Energy(pos, vel));
      }

      class Debris {
        constructor(pos, vel) {
          this.pos = pos.copy();
          this.vel = vel.copy();
          this.vel.add(p5.Vector.random2D().mult(p.random(0.5, 1.5)));
          this.transparency = p.random(200, 255);
        }

        update() {
          this.pos.add(this.vel);
          this.transparency -= 2;
        }

        render() {
          if (this.transparency > 0) {
            p.stroke(255, 0, 0);
            p.point(this.pos.x, this.pos.y);
          }
        }
      }

      function generateDebris(pos, vel, n) {
        for (let i = 0; i < n; i++) {
          debris.push(new Debris(pos, vel));
        }
      }

      class Asteroid {
        constructor(pos, s) {
          if (pos) {
            this.pos = pos.copy();
          } else {
            this.pos = p.createVector(p.random(p.width), p.random(p.height));
          }
          this.vel = p5.Vector.random2D();
          this.sides = p.floor(p.random(15, 30));
          if (s) {
            this.sides = p.floor(s * 0.5);
          } else {
            this.sides = p.floor(p.random(15, 30));
          }
          this.rmin = 20;
          this.rmax = 40;
          this.r = p.map(this.sides, 15, 30, this.rmin, this.rmax);
          this.offset = [];
          for (let i = 0; i < this.sides; i++) {
            this.offset[i] = p.random(-5, 5);
          }
          this.angle = 0;
          let increment = p.map(this.r, this.rmin, this.rmax, 0.1, 0.01);
          if (p.random() > 0.5) {
            this.increment = increment * -1;
          } else {
            this.increment = increment;
          }
        }

        explode() {
          let debrisVel = p5.Vector.random2D().mult(p.random(0.5, 1.5));
          let debrisNum = this.r * 5;
          generateDebris(this.pos, debrisVel, debrisNum);
        }

        breakup() {
          let newA = [];
          if (this.sides > 5) {
            newA[0] = new Asteroid(this.pos, this.sides);
            newA[1] = new Asteroid(this.pos, this.sides);
          }
          return newA;
        }

        update() {
          this.pos.add(this.vel);
          this.angle += this.increment;
        }

        render() {
          p.push();
          p.translate(this.pos.x, this.pos.y);
          p.rotate(this.angle);
          p.noFill();
          p.stroke(255);
          p.beginShape();
          for (let i = 0; i < this.sides; i++) {
            let angle = p.map(i, 0, this.sides, 0, p.TWO_PI);
            let r = this.r + this.offset[i];
            let x = r * p.cos(angle);
            let y = r * p.sin(angle);
            p.vertex(x, y);
          }
          p.endShape(p.CLOSE);
          p.pop();
        }

        edges() {
          if (this.pos.x > p.width + this.r) {
            this.pos.x = -this.r;
          } else if (this.pos.x < -this.r) {
            this.pos.x = p.width + this.r;
          }
          if (this.pos.y > p.height + this.r) {
            this.pos.y = -this.r;
          } else if (this.pos.y < -this.r) {
            this.pos.y = p.height + this.r;
          }
        }

        setRotation(angle) {
          this.rotation = angle;
        }

        turn(angle) {
          this.heading += this.rotation;
        }
      }

      let astnum;
      let initastnum = 2;

      function windowResized() {
        p.resizeCanvas(p.windowWidth, p.windowHeight);
      }

      // Add event listeners for key events
      window.addEventListener("keydown", keyPressed);
      window.addEventListener("keyup", keyReleased);

      // Remove event listeners when the component unmounts
      return () => {
        window.removeEventListener("keydown", keyPressed);
        window.removeEventListener("keyup", keyReleased);
      };

      return {
        keyPressed,
        keyReleased,
        windowResized,
      };
    };

    const game = new p5(sketch, canvasRef.current);

    const resizeCanvas = () => {
      const canvasParent = document.getElementById("sketch-container");
      const canvasWidth = canvasParent.offsetWidth;
      const canvasHeight = canvasParent.offsetHeight;

      game.resizeCanvas(canvasWidth, canvasHeight);
    };

    window.addEventListener("resize", resizeCanvas);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      game.remove();
    };
  }, []);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
      id="sketch-container"
      ref={canvasRef}
    ></div>
  );
};

export default SpaceShooter;
