import React, { useEffect, useState, useRef } from "react";
import Header from "./Header";
import "../Css/Home.css";
import "../Css/Blog.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import radision from "../assets/images/Radision.png"
import ummed from "../assets/images/ummed.png"
import jehannuma from "../assets/images/jehannuma.avif"
import swagstay from "../assets/images/swagstay-removebg-preview.png"
import lakme from "../assets/images/lakme-removebg-preview.png"
import barkat from "../assets/images/barkat.png"
import aiims from "../assets/images/aiims.png"
import icm from "../assets/images/icm-removebg-preview.png"
import kyriad from "../assets/images/ky-removebg-preview.png"
import citrus from "../assets/images/citrus-removebg-preview.png"
import iapid from "../assets/images/iapidd.png"
import jgf from "../assets/images/JGF.png"
import formImg3 from "../assets/images/aiimgnew.png";
import formImg2 from "../assets/images/vrimgNew.png";
import formImg1 from "../assets/images/metaImgnew.png";
import MetaVerseIcon from "../assets/images/Metavarse1.svg";
import RobotImg from "../assets/images/newRobot.png";
import ServicesIcon from "../assets/images/servicesIcon.svg";
import leftArrowBlg from "../assets/images/leftyArrow.svg";
import $ from "jquery";
import Python from "../assets/images/python.svg";
import Reacticon from "../assets/images/react.png";
import ML from "../assets/images/ml.png";
import MySQL from "../assets/images/mysql.svg";
import Mongo from "../assets/images/Mongo.png";
import Nodejs from "../assets/images/nodejs.png";
import AWS from "../assets/images/aws.svg";
import Tenser from "../assets/images/teserflow.svg";
import SQL from "../assets/images/sql.svg";
import Php from "../assets/images/php.png";
// import IOS from "../assets/images/app";
import IOS from "../assets/images/AppleLogo2.svg";
import Android from "../assets/images/android.svg";
import ClientImg2 from "../assets/images/clientstory2.png";
import ClientImg3 from "../assets/images/clientstory3.png";
import ECommerce from "../assets/images/eCommerce.svg";
import Agriculture from "../assets/images/agri.svg";
import Education from "../assets/images/education.svg";
import Finance from "../assets/images/restro2.svg";
import Government from "../assets/images/govt.svg";
import Tourism from "../assets/images/tourrism.svg";
import Hotels from "../assets/images/hotels.svg";
import Hospital from "../assets/images/hospitals.svg";
import Fintech from "../assets/images/fintech.svg";
import Logistic from "../assets/images/logi.svg";
import landing_img1 from "../assets/images/landing_img1.png";
import landing_img2 from "../assets/images/landing_img2.png";
import landing_img3 from "../assets/images/landing_img3.png";
import landing_img4 from "../assets/images/landing_img4.png";
import landing_img5 from "../assets/images/landing_img5.png";
import blg from "../assets/images/blg.png";
// import alg from "../assets/images/alg.png";
// import hlg from "../assets/images/hlg.png";
// import slg from "../assets/images/slg.png";
// import sslg from "../assets/images/sslg.png";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import {
  server_post_data,
  front_blog_url,
} from "../ServiceConnection/serviceconnection.js";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.css";
import "/node_modules/swiper/swiper";

import Loader from "./Loader.js";

function Home() {
  const sliderRef = useRef(null);
  const swiperRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  console.log(sliderRef);
  const servicesRef = useRef(null);
  const footerRef = useRef(null);
  const topRef = useRef(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [dataloop, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [SEOloop, setSEOloop] = useState([]);
  const handleFetchData = async () => {
    setShowLoader(true);
    await server_post_data(front_blog_url, null)
      .then((response) => response.json())
      .then((data_data) => {
        setData(data_data.blog_list);
        setSEOloop(data_data.seo_loop);
      })
      .catch((error) => {
        // Handle error
      })
      .finally(() => {
        const visitedBefore = sessionStorage.getItem("visitedBefore");
        if (visitedBefore) {
          setShowLoader(false);
        } else {
          const timeout = setTimeout(() => {
            setShowLoader(false);
            sessionStorage.setItem("visitedBefore", true);
          }, 5000);
          return () => clearTimeout(timeout);
        }
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/Blogdetails/blog/" + v_id;
    let data_seo_link = data_seo_link_final;

    const matchedItem = SEOloop.find(
      (data) => data_seo_link === data.call_function_name
    );

    if (matchedItem) {
      data_seo_link_final = matchedItem.pretty_function_name;
    }

    return data_seo_link_final;
  };

  useEffect(() => {
    $(".servicesList_items").on("click", function () {
      $(".servicesList_items").removeClass("slctdItem");
      $(this).addClass("slctdItem");
    });
    // handleFetchData();
    return () => {
      $(".servicesList_items").off("click");
    };
  }, []);

  const [activeItem, setActiveItem] = useState("item1");

  const handleClick = (item) => {
    setActiveItem(item);
  };
  const goToSlide = (slideIndex) => {
    sliderRef.current.slickGoTo(slideIndex);
  };

  const techStack = [
    { name: "Python", image: Python },
    { name: "React", image: Reacticon },
    { name: "Machine Learning", image: ML },
    { name: "My SQL", image: MySQL },
    { name: "Mongo DB", image: Mongo },
    { name: "Node js", image: Nodejs },
    { name: "AWS", image: AWS },
    { name: "Tensor Flow", image: Tenser },
    { name: "SQL", image: SQL },
    { name: "PHP", image: Php },
    { name: "IOS", image: IOS },
    { name: "Android", image: Android },
  ];

  const industries = [
    { name: "E-Commerce", image: ECommerce },
    { name: "Agriculture", image: Agriculture },
    { name: "Education", image: Education },
    { name: "Restaurant", image: Finance },
    { name: "Government", image: Government },
  ];

  const industries2 = [
    { name: "Tourism", image: Tourism },
    { name: "Hotels ", image: Hotels },
    { name: "Hospital", image: Hospital },
    { name: "Fintech", image: Fintech },
    { name: "Logistic", image: Logistic },
  ];

  // Function to update home data
  const updateHomeData = (data) => {
    console.log(data);
  };
  //fori mobile viewe blog will be carausll

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 700);
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
//marquee tag function 
const images = [ummed, jehannuma, citrus, kyriad, icm, swagstay, iapid, lakme, blg, aiims, barkat, radision, jgf ]
const MarqueeList = ({ images }) => {
  return (
    <div className="marquee-container">
      <div className="marquee-image">
        {images.map((image, index) => (
          <img className="marquee_image" key={index} src={image} alt="Marquee" />
        ))}  
      </div>
      <div className="marquee-image">
        {images.map((image, index) => (
          <img className="marquee_image" key={index} src={image} alt="Marquee" />
        ))}  
      </div>
    </div>
  );
};

  return (
    <div className="home" ref={topRef}>
      {showLoader && <Loader />}
      <div>
        <Header footerRef={footerRef} />
      </div>
      <div className="home_container">
        <div className="home_wrapper">
          <div className="frontBannner_container">
            <Slider ref={sliderRef} {...settings}>
              <div className="slide slide--1">
                <div className="frontBannerBg">
                  <img className="firstImg" src={formImg3} alt="Front Banner" />
                </div>
                <div className="frntBannerText_container">
                  <div className="frntBannerText container-lg">
                  <h1 className="forntBannerH1_hidden d-none">Artificial Intelligence</h1>
                    <h2 className="forntBannerH1">Artificial Intelligence</h2>
                    {/* <h1 className="forntBannerH12">&nbsp;</h1> */}
                    <p className="forntBannerP">  
                      Artificial Intelligence acts as a backbone for the
                      metaverse,
                      <br className="d-sm-block d-none" /> facilitating
                      realistic interactions, and helping to power virtual
                      entities
                      <br className="d-sm-block d-none" /> while it employs
                      machine learning algorithms for analysing personal
                      <br className="d-sm-block d-none" /> experience and
                      creating dynamic simulations.
                    </p>
                    <div className="buttonFrontGet">
                      <Link onClick={() => handleLinkClick("/contact-us")}>
                        <button className="primary_btn btnQuote btnQuotesmall">
                          <p>Get a Quote</p>
                          <span className="right_arrow">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path d="M0 0h24v24H0z" fill="none" />
                              <path
                                d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                                fill="white"
                              />
                            </svg>
                          </span>
                        </button>
                      </Link>
                    </div>
                    <div className="frontScreenBtns frontScreenBtnsdisplay">
                      <button
                        className="slickButtonSlider slickButtonSliderActive"
                        onClick={() => goToSlide(0)}
                      >
                        Artificial Intelligence{" "}
                      </button>
                      <button
                        className="slickButtonSlider"
                        onClick={() => goToSlide(1)}
                      >
                        Machine Learning{" "}
                      </button>
                      <button
                        className="slickButtonSlider"
                        onClick={() => goToSlide(2)}
                      >
                        Metaverse
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="slide slide--2">
                <div className="frontBannerBg">
                  <img
                    className="secondImg"
                    src={formImg1}
                    alt="Front Banner"
                  />
                </div>
                <div className="frntBannerText_container">
                  <div className="frntBannerText container-lg">
                    <h2 className="forntBannerH1">Machine Learning </h2>
                    {/* <h1 className="forntBannerH12">&nbsp;</h1> */}
                    <p className="forntBannerP">
                      Machine learning is a subset of Artificial intelligence
                      that enables a virtual{" "}
                      <br className="d-sm-block d-none" /> environment to adapt
                      dynamically and enhance the user experience without{" "}
                      <br className="d-sm-block d-none" /> explicit programming.
                      It works with algorithms that enable the computers{" "}
                      <br className="d-sm-block d-none" />
                      to identify patterns and data-driven decisions.
                    </p>
                    <div className="buttonFrontGet">
                      <Link onClick={() => handleLinkClick("/contact-us")}>
                        <button className="primary_btn btnQuote btnQuotesmall">
                          <p>Get a Quote</p>
                          <span className="right_arrow">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path d="M0 0h24v24H0z" fill="none" />
                              <path
                                d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                                fill="white"
                              />
                            </svg>
                          </span>
                        </button>
                      </Link>
                    </div>
                    <div className="frontScreenBtns frontScreenBtnsdisplay ">
                      <button onClick={() => goToSlide(0)}>
                        Artificial Intelligence{" "}
                      </button>
                      <button
                        className="slickButtonSliderActive"
                        onClick={() => goToSlide(1)}
                      >
                        Machine Learning{" "}
                      </button>
                      <button onClick={() => goToSlide(2)}>Metaverse</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="slide slide--3">
                <div className="frontBannerBg">
                  <img src={formImg2} alt="Front Banner" />
                </div>
                <div className="frntBannerText_container">
                  <div className="frntBannerText container-lg">
                    <h2 className="forntBannerH1">Metaverse </h2>
                    {/* <h1 className="forntBannerH12">&nbsp;</h1> */}
                    <p className="forntBannerP">
                      The metaverse connects users through interactive
                      experiences using <br className="d-sm-block d-none" />
                      augmented reality (AR) and virtual reality (VR), blurring
                      the lines <br className="d-sm-block d-none" />
                      between digital and real worlds. Game AI and Game AI
                      enhances
                      <br className="d-sm-block d-none" /> this synergy,
                      creating immersive, real-time interactions.
                      <br className="d-sm-block d-none" />{" "}
                    </p>
                    <div className="buttonFrontGet">
                      <Link onClick={() => handleLinkClick("/contact-us")}>
                        <button className="primary_btn btnQuote btnQuotesmall">
                          <p>Get a Quote</p>
                          <span className="right_arrow">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <path d="M0 0h24v24H0z" fill="none" />
                              <path
                                d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                                fill="white"
                              />
                            </svg>
                          </span>
                        </button>
                      </Link>
                    </div>
                    <div className="frontScreenBtns frontScreenBtnsdisplay">
                      <button onClick={() => goToSlide(0)}>
                        Artificial Intelligence{" "}
                      </button>
                      <button onClick={() => goToSlide(1)}>
                        Machine Learning{" "}
                      </button>
                      <button
                        className="slickButtonSliderActive"
                        onClick={() => goToSlide(2)}
                      >
                        Metaverse
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>

            <div className="frontScreenBtns forntbtnsShowdiv container-lg">
              <button onClick={() => goToSlide(0)}>
                Artificial Intelligence{" "}
              </button>
              <button onClick={() => goToSlide(1)}>Machine Learning </button>
              <button
                className="slickButtonSliderActive"
                onClick={() => goToSlide(2)}
              >
                Metaverse
              </button>
            </div>
          </div>

          <div className="mt-5 container-lg">
            <div className="whatIsAI_container">
              <div className="whatIsAI_bg">
                <div className="whatIsAI">
                  <div className="row m-0">
                    <div className="col-lg-6 col-md-5 p-0">
                      <div className="whatIsAI_text">
                        <img src={MetaVerseIcon} alt="icon" />
                        <div className="whatIsAI_textCOntainer">
                          <h2 className="whatIsAIH1">What</h2>
                          <h2 className="whatIsAIH1-2">
                            is Artificial Intelligence?
                          </h2>
                          <h6>
                            Artificial Intelligence (A.I.) is a branch of
                            computer science that uses algorithms and data
                            processes to create machines and software for
                            performing tasks that require human intelligence
                            like, problem-solving, decision-making and learning.
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="whatIsAIImgCont col-lg-6 col-md-7 col-sm-8 col-10 p-0">
                      <div className="whatIsAI_Img">
                        <div className="whatIsAI_ImgDiv">
                          <img src={RobotImg} alt="icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section className="services" id="service_kaam" ref={servicesRef}>
              <div className="services_contaINER">
                <h2>
                  Our range of offered services in the{" "}
                  <br className="d-sm-block d-none" /> field of development
                  includes:
                </h2>
                <div className="services_wrapper">
                  <div className="servicesDiv">
                    <div className="row m-0 rowReversehome">
                      <div className="servicesDiv_left col-sm-6">
                        <div className="servicesList">
                          <ul>
                            <li>
                              <div
                                className={`servicesList_items ${
                                  activeItem === "item1" ? "slctdItem" : ""
                                }`}
                                onClick={() => handleClick("item1")}
                              >
                                <p>01.</p>
                                <p>
                                  Artificial Intelligence & Machine Learning
                                  Solutions
                                </p>
                              </div>
                            </li>
                            <li>
                              <div
                                className={`servicesList_items ${
                                  activeItem === "item2" ? "slctdItem" : ""
                                }`}
                                onClick={() => handleClick("item2")}
                              >
                                <p>02.</p>
                                <p>Metaverse Development</p>
                              </div>
                            </li>
                            <li>
                              <div
                                className={`servicesList_items ${
                                  activeItem === "item3" ? "slctdItem" : ""
                                }`}
                                onClick={() => handleClick("item3")}
                              >
                                <p>03.</p>
                                <p>Smart AI Assistant (Voice & Chat Bot)</p>
                              </div>
                            </li>
                            <li>
                              <div
                                className={`servicesList_items ${
                                  activeItem === "item4" ? "slctdItem" : ""
                                }`}
                                onClick={() => handleClick("item4")}
                              >
                                <p>04.</p>
                                <p>SaaS & PaaS Product Development</p>
                              </div>
                            </li>
                            <li>
                              <div
                                className={`servicesList_items ${
                                  activeItem === "item5" ? "slctdItem" : ""
                                }`}
                                onClick={() => handleClick("item5")}
                              >
                                <p>05.</p>
                                <p> Custom Software Development </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="servicesDiv_right col-sm-6">
                        <div className="selectedServicesDiv">
                          {activeItem === "item1" && (
                            <>
                              <div className="p-3">
                                <img src={ServicesIcon} alt="icon" />
                              </div>
                              <h4>
                                Artificial Intelligence & Machine Learning
                                Solutions
                              </h4>
                              <p>
                                Enabling communication and interaction with the
                                user by incorporating Smart AI Assistance
                                enhances efficiency and reduces time for
                                development. Machine learning involves
                                algorithms that assist computers in identifying
                                patterns and refining their performance over
                                time. It helps immensely in enhancing automation
                                and decision-making processes in diverse
                                industries.
                              </p>
                            </>
                          )}
                          {activeItem === "item2" && (
                            <>
                              <div className="p-3">
                                <img src={ServicesIcon} alt="icon" />
                              </div>
                              <h4>Metaverse Development</h4>
                              <p>
                                The metaverse provides an immersive environment
                                to the users where they can engage with diverse
                                experiences. It provides an interconnected
                                digital space where virtual reality and
                                augmented reality merge, creating a landscape of
                                creativity and personalized experience for the
                                users and helping to represent the paradigm
                                shift in how we comprehend and interact in a
                                digital environment.
                              </p>
                            </>
                          )}
                          {activeItem === "item3" && (
                            <>
                              <div className="p-3">
                                <img src={ServicesIcon} alt="icon" />
                              </div>
                              <h4>Smart AI Assistant (Voice & Chat Bot)</h4>
                              <p>
                                Enabling communication and interaction with the
                                user by incorporating Smart AI Assistance and
                                Chatbot, enhances efficiency and reduces time
                                for development. A well-designed chatbot can
                                assist with collecting ideation and instant
                                feedback from the users which can be used to
                                elevate the productivity of the developmental
                                team and sophistication of the metaverse
                                avatars.
                              </p>
                            </>
                          )}
                          {activeItem === "item4" && (
                            <>
                              <div className="p-3">
                                <img src={ServicesIcon} alt="icon" />
                              </div>
                              <h4>SaaS & PaaS Product Development</h4>
                              <p>
                                Product development asks for expertise in the
                                field so that creativity can be intertwined with
                                technical adeptness and creating a user-friendly
                                design, resulting in customized products made
                                for you alone. Our team is an expert in
                                culturing the development of production
                                solutions with their advanced skills.
                              </p>
                            </>
                          )}
                          {activeItem === "item5" && (
                            <>
                              <div className="p-3">
                                <img src={ServicesIcon} alt="icon" />
                              </div>
                              <h4>Custom Software Development</h4>
                              <p>
                                Our skilled software engineers help in designing
                                life-like avatars with intricate animations
                                along with real-time customization options by
                                adopting emerging technologies. They collaborate
                                to render optimization to the software for
                                better performance and the ability to operate
                                across a wide range of virtual platforms.
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="techStack">
              <div className="techStack_CONTAINER">
                <h2>
                  Tech Stack used for <br className="d-sm-block d-none" /> Top
                  Developmental Services
                </h2>
                <div className="tectStack_list">
                  <div className="row m-0">
                    {techStack.map((tech, index) => (
                      <div className="col-md-2 col-sm-3 col-4 p-0" key={index}>
                        <div className="tectStack_listItem applLogo">
                          <img src={tech.image} alt={tech.name} />
                          <p>{tech.name}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>

            <section className="clientStory">
              <div className="clientStory_container">
                <h2>Client Stories</h2>
                <div className="clientStoryDiv">
                  <div className="row m-0">
                    <div className="col-md-5">
                      <div className="clientLeft">
                        <h4>
                          Taking initiatives and <br /> projects that harness AI{" "}
                          <br /> for humanitarian purposes.
                        </h4>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="clientRight">
                        <div className="clientStoryRight">
                          <div className="row m-0">
                            <div className="rightTextDiv col-8">
                              <h4>
                                Harnessing Generative AI, our tech company aims
                                to revolutionize customer engagement, streamline
                                product innovation, and amplify data analysis.
                              </h4>
                            </div>
                            <div className="rightImgDiv rightImgDiv1 col-4 p-0">
                              <img src={ClientImg2} alt="icon" />
                            </div>
                          </div>
                        </div>
                        <br className="d-sm-block d-none" />
                        <div className="clientStoryRight">
                          <div className="row m-0">
                            <div className="rightImgDiv rightImgDiv2 col-4 p-0">
                              <img src={ClientImg3} alt="icon" />
                            </div>
                            <div className="rightTextDiv1 col-8">
                              <h4>
                                Usage of AI-powered tools and techniques to
                                detect and enhance network security and
                                safeguard sensitive data.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="industries">
              <div className="industries_container">
                <div className="industries_head">
                  <h2>
                    Industries that our Development{" "}
                    <br className="d-sm-block d-none" /> Services Excel In
                  </h2>
                  <p > 
                    Our skilled developers excel at integrating cutting-edge
                    services into your business offerings,{" "}
                    <br className="d-sm-block d-none" /> processes, and growth
                    strategies, regardless of your industry.
                  </p>
                </div>
                <div className="industries_list">
                  <div className="industries_listcontainer">
                    {industries.map((tech, index) => (
                      <div className="industries_listItem" key={index}>
                        <img src={tech.image} alt={tech.name} />
                        <p> {tech.name}</p>
                      </div>
                    ))}
                  </div>
                  <div className="industries_listcontainer">
                    {industries2.map((tech, index) => (
                      <div className="industries_listItem itmmIcn" key={index}>
                        <img src={tech.image} alt={tech.name} />
                        <p>{tech.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>

            <section className="blog">
              <div className="blog_section container-lg">
                <h5 className="hd_hm">Our Project Stories</h5>
                <h4 className="col-md-8 col-11 mx-auto">
                  AI Innovations are Catalyzing a Communicational Revolution
                </h4>
                <div className="card_container">
                {isMobileView && (
            
                <div className="LeftRightButtnn">
                    <div className="LeftBtnn">
                      <button
                        type="button"
                        className="leftButtmnn"
                        onClick={() => sliderRef.current.slickPrev()}
                      >
                        <img
                          className="secondImg"
                          src={leftArrowBlg}
                          alt="imgLeftArrow"
                        />
                      </button>
                    </div>

                    <div className="rightBtnn">
                      {" "}
                      <button
                        type="button"
                        className="RightButtmnn"
                        onClick={() => sliderRef.current.slickNext()}
                      >
                        <img
                          className="secondImg"
                          src={leftArrowBlg}
                          alt="imgLeftArrow"
                        />
                      </button>
                    </div>
                  </div>
               
            
            
                  
                    )}
                  {isMobileView ? (
                    <Slider
                    className="sllddrrCarausll"
                      ref={sliderRef}
                      dots={true}
                      infinite={true}
                      speed={500}
                      slidesToShow={1}
                      slidesToScroll={1}
                      autoplay={false}
                      autoplaySpeed={3000}
                      arrows={true}
                    >
                      {dataloop.map((data, index) => (
                        <div key={index} className="image-item">
                          <Link
                            onClick={() =>
                              handleLinkClick(
                                match_and_return_seo_link(data.b_id)
                              )
                            }
                          >
                            <div className="card crimg">
                              <div className="image crimg ">
                                <img src={data.image_name} alt="Image1" />
                              </div>
                              <div className="blogtext_container">
                                <h6
                                  style={{ textAlign: "left" }}
                                  className="my-2"
                                >
                                  {data.title_name}
                                </h6>
                                <div className="blogtext">
                                  <p>{data.tag_line}</p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                      
                    </Slider>
                  ) : (
                    <div className="row m-0">
                      {dataloop.slice(0, 3).map((data, index) => (
                        <div
                          className="col-md-4 col-sm-6 mx-auto p-1"
                          key={index}
                        >
                          <div className="image-item">
                            <Link
                              onClick={() =>
                                handleLinkClick(
                                  match_and_return_seo_link(data.b_id)
                                )
                              }
                            >
                              <div className="card">
                                <div className="image">
                                  <img src={data.image_name} alt="Image1 " />
                                </div>
                                <div className="blogtext_container">
                                  <h6
                                    style={{ textAlign: "left" }}
                                    className="my-2"
                                  >
                                    {data.title_name}
                                  </h6>
                                  <div className="blogtext">
                                    <p>{data.tag_line}</p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </section>

            <section className="processAndPlanning">
              <div className="processAndPlanningContianer">
                <div className="dp_text">
                  <h2 className="text-center">Our Development Process</h2>
                </div>
                <div className="home_dp">
                  <div className="row  m-0 rowReverse1">
                    <div className="col-sm-6">
                      <div className="home_img">
                        <img
                          src={landing_img1}
                          alt="My logo"
                          className="hme_img"
                        />
                      </div>
                    </div>
                    <div className=" col-sm-6">
                      <div className="dp_txt">
                        <div>
                          <div className="col-xl-3 col-md-4 col-6 mb-3"></div>
                          <div className="dp_txtCOnt">
                            <h3>1. Discovery</h3>
                            <p>
                              We focus on understanding and meeting the needs of
                              our clients, examining the industry dynamics and
                              making sure that our services have a
                              client-centric approach.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="home_dp">
                  <div className="row  m-0 ">
                    <div className=" col-sm-6">
                      <div className="home_img">
                        <img
                          src={landing_img2}
                          alt="My logo"
                          className="hme_img"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="dp_txt">
                        <div>
                          <div className="col-xl-3 col-md-4 col-6 mb-3"></div>
                          <div className="dp_txtCOnt">
                            <h3>2. Strategic Planning </h3>
                            <p>
                              Before the immaculate execution of the plan, we go
                              through strategic planning so that the outcome is
                              absolute and flawless. In this stage, an
                              experimental system is developed to confirm
                              whether or not it meets the user requirements
                              before proceeding any further.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="home_dp">
                  <div className="row  m-0 rowReverse1  ">
                    <div className="col-sm-6">
                      <div className="home_img">
                        <img
                          src={landing_img3}
                          alt="My logo"
                          className="hme_img"
                        />
                      </div>
                    </div>
                    <div className=" col-sm-6">
                      <div className="dp_txt">
                        <div>
                          <div className="col-xl-3 col-md-4 col-6 mb-3"></div>
                          <div className="dp_txtCOnt">
                            <h3>3. Development</h3>
                            <p>
                              The latest tools and methods are used in the Core
                              Development process at this stage to develop a
                              stunning project.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="home_dp">
                  <div className="row  m-0">
                    <div className=" col-sm-6">
                      <div className="home_img">
                        <img
                          src={landing_img4}
                          alt="My logo"
                          className="hme_img"
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="dp_txt">
                        <div>
                          <div className="col-xl-3 col-md-4 col-6 mb-3"></div>
                          <div className="dp_txtCOnt">
                            <h3>4. Testing and Q/A </h3>
                            <p>
                              The product goes through our intensive testing
                              procedures and in-depth Q/A to ensure the quality
                              of the project before it gets delivered.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="home_dp">
                  <div className="row  m-0 rowReverse1">
                    <div className="col-sm-6">
                      <div className="home_img">
                        <img
                          src={landing_img5}
                          alt="My logo"
                          className="hme_img"
                        />
                      </div>
                    </div>
                    <div className=" col-sm-6">
                      <div className="dp_txt">
                        <div>
                          <div className="col-xl-3 col-md-4 col-6 mb-3"></div>
                          <div className="dp_txtCOnt">
                            <h3>5. Launch</h3>
                            <p>
                              The project is launched after vigorous testing and
                              development with the latest tools and technology
                              by the Core Development process.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="home_dp">
                  <div className="row rowReverse2 m-0">
                    <div className=" col-sm-6">
                      <div className="Casestudyimg">
                        <img
                          src={landing_img6}
                          alt="My logo"￼
React

￼
Machine Learning

￼
My SQL


                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="dp_txt">
                        <div>
                          <div className="col-xl-3 col-md-4 col-6 mb-3"></div>
                          <div className="dp_txtCOnt">
                            <h3>priyansh sharma</h3>
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </section>

            <section className="ourPartners">
              <div className="ourPartnersCOntianer">
                <div className="ach_txt">
                  <h2 style={{ color: "#33AFC6", textAlign: "center" }}>
                    Already Trust Us
                  </h2>
                  <h6 style={{ textAlign: "center" }}>
                    Unlock growth and innovation by partnering with us today!
                  </h6>
                </div>
                <div className="row m-0">
                  <MarqueeList images={images}/>
               
                </div>
              </div>
            </section>

            <section className="ttoesection">
              <div className="casescard text-center">
                <h5 className="casescard_text col-xl-9 col-lg-10 col-md-11">
                  Begin Your Digital Transformation Journey Today!
                </h5>
                <Link onClick={() => handleLinkClick("/contact-us")}>
                  <button className="btn_H">talk to our Expert</button>
                </Link>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div ref={footerRef}>
        <Footer
          servicesRef={servicesRef}
          topRef={topRef}
          updateHomeData={handleClick}
        />
      </div>
    </div>
  );
}

export default Home;
