import React, { useRef } from "react";
import "../Css/PrivacyPolicy.css";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import {

  handleLinkClick,
} from "../CommonJquery/CommonJquery.js";

function TermsOfUse() {
  const footerRef = useRef(null);
  const topRef = useRef(null);
  return (
    <div>
      <div className="navStickyTop">
        <Header footerRef={footerRef} />
      </div>
      <div className="privacy_policy">
        <div className="container privacy_policy_wrapper">
          <h4>Terms Of Use</h4>
          <div className="privacy_policy_container">
            <div>
              <strong>
                <h6 style={{ marginTop: "1rem" }}>1. Acceptance of Terms</h6>
              </strong>
              <p>
                By accessing and using Triosoft.ai ("the Service"), you agree to
                be bound by these Terms and Conditions, as well as all
                applicable laws and regulations. If you do not agree with any of
                these terms, you are prohibited from using or accessing this
                Service.
                <br />
              </p>
            </div>
            <div>
              <strong>
                <h6>2. Use of the Service</h6>
              </strong>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp; The services
                provided by Triosoft Technologies are designed to assist users
                in leveraging digital solutions for their business needs.
              </p>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp;. Users agree
                not to misuse or exploit the services for any unlawful purposes
                or activities that violate applicable laws, regulations, or
                ethical standards.
              </p>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp; Users are
                solely responsible for the content they input, manipulate, or
                create using Triosoft Technologies' services. Triosoft
                Technologies does not claim ownership of user-generated content
                but may use it for improving services unless otherwise agreed
                upon in writing.
              </p>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp; Triosoft
                Technologies reserves the right to suspend or terminate access
                to the services for users who violate these terms or engage in
                activities detrimental to the integrity of the services or other
                users' experience.
              </p>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp; By using
                Triosoft Technologies' services, users acknowledge and agree to
                abide by these terms and conditions, as well as any additional
                guidelines or policies provided by Triosoft Technologies.
              </p>
            </div>
            <div>
              <strong>
                <h6>3. Privacy Policy</h6>
              </strong>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp;  We are committed to protecting your privacy. Our Privacy Policy outlines how we collect, use, and protect your personal information in compliance with GDPR regulations.


              </p>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp;  By using the Service, you consent to the collection and use of information in accordance with our     <Link
                                onClick={() =>
                                  handleLinkClick("/Privacy_Policy")
                                }
                              >
                                Privacy Policy
                              </Link>



              </p>
      
            </div>
            <div>
              <strong>
                <h6>4. Data Security</h6>
              </strong>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp;  We employ industry-standard security measures to protect your data and ensure its confidentiality.

              </p>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp; However, we cannot guarantee absolute security, and users acknowledge and accept the inherent risks of transmitting data over the internet. For more details, please refer to our     <Link
                                onClick={() =>
                                  handleLinkClick("/Privacy_Policy")
                                }
                              >
                                Privacy Policy
                              </Link>.

              </p>
            </div>
            <div>
              <div>
                <strong>
                  <h6>5. Intellectual Property</h6>
                </strong>
              </div>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp;All content provided by Triosoft.ai, including but not limited to text, graphics, logos, and software, is the property of Triosoft.ai or its licensors and is protected by intellectual property laws.

              </p>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp;Users may not reproduce, distribute, or create derivative works from the content provided by the Service without prior written consent.

              </p>
            </div>

            <div>
              <strong>
                <h6>6. Limitation of Liability</h6>
              </strong>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp;To the fullest extent permitted by law, Triosoft.ai and its affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use the Service.


              </p>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp; Users agree to indemnify and hold harmless Triosoft.ai and its affiliates from any claims, damages, or losses resulting from their use of the Service.

              </p>
            </div>

            <div>
              <strong>
                <h6>7. Modifications</h6>
              </strong>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp;Triosoft.ai reserves the right to modify or update these Terms and Conditions at any time without prior notice.

              </p>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp;Continued use of the Service after any modifications constitute your acceptance of the revised Terms and Conditions.

              </p>
            </div>
            <div>
              <strong>
                <h6>8. Governing Law</h6>
              </strong>
              <p>
                <strong style={{ color: "white" }}>•</strong>&nbsp;These Terms and Conditions shall be governed by and construed in accordance with the laws of the States of Madhya Pradesh and Chhattisgarh, India, without regard to its conflict of law provisions.

              </p>
            </div>
            <div>
              <strong>
                <h6>9. Contact Information</h6>
              </strong>
              <p>
              If you have any questions or concerns about these Terms and Conditions, please contact our dedicated support team at  <a href="support@triosoft.ai"> support@triosoft.ai.</a>

              </p>
            </div>
            <div>
              <strong>
                <h6>10. Age Disclaimer</h6>
              </strong>
              <p>
              By using this Service, you affirm that you are at least 16 years old. The Service is not intended for individuals under 16 years of age.

              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer servicesRef={null} topRef={topRef} updateHomeData={null} />
    </div>
  );
}

export default TermsOfUse;
