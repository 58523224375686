import React, { useState, useEffect, useRef } from "react";
import "../Css/Insights.css";
import grp from "../assets/images/grp.png";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import {
  server_post_data,
  get_blog_list_url,
} from "../ServiceConnection/serviceconnection.js";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
function Insights() {
  const [dataloop, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [SEOloop, setSEOloop] = useState([]);
  const handleFetchData = async () => {
    setShowLoader(true);
    await server_post_data(get_blog_list_url, null)
      .then((response) => response.json())
      .then((data_data) => {
        setShowLoader(false);
        setData(data_data.blog_list);
        setSEOloop(data_data.seo_loop);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };
  useEffect(() => {
    handleFetchData();
  }, []);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/Blogdetails/blog/" + v_id;
    let data_seo_link = data_seo_link_final;

    const matchedItem = SEOloop.find(
      (data) => data_seo_link === data.call_function_name
    );

    if (matchedItem) {
      data_seo_link_final = matchedItem.pretty_function_name;
    }

    return data_seo_link_final;
  };

  const footerRef = useRef(null);
  const topRef = useRef(null);

  return (
    <div ref={topRef}>
      <div className={showLoader ? "loading" : ""}></div>
      <div className="navStickyTop">
        <Header footerRef={footerRef} />
      </div>
      <div className="insghtContainer container-lg">
        <div className="igts_heading  row m-0">
          <div className="col-xl-6 col-lg-5 col-md-4">
            <div className="igts_Lhead">
              <p>Insights</p>
            </div>
          </div>
          <div className="col-xl-6 col-lg-7 col-md-8">
            <div className="igts_Rhead">
              <p>
                With a rapidly evolving digital world, stay updated with the
                latest technology trends and digital transformations. Our blog
                will provide all the necessary knowledge and strategies to
                survive and flourish in this fast-paced digital world.
              </p>
            </div>
          </div>
        </div>
        <div className="grp_img">
          <img className="group_pic " src={grp} alt="my logo" />
        </div>
        <div className="image-grid">
          <div className="row m-0">
            {dataloop.map((data, index) => (
              <div className="col-md-3 col-sm-6 mb-5" key={index}>
                <div className="image-item">
                  <Link
                    onClick={() =>
                      handleLinkClick(match_and_return_seo_link(data.b_id))
                    }
                  >
                    <img
                      className="bttm_img"
                      src={data.image_name}
                      alt="Image1 "
                    />
                  </Link>
                  <div className="bttm_txt">
                    <p>{data.tag_line}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div ref={footerRef}>
      <Footer servicesRef={null} topRef={topRef} updateHomeData={null} />

      </div>
    </div>
  );
}

export default Insights;
