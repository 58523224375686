import React, { useEffect, useState } from "react";
import "../Css/Header.css";
import trilogonew from "../assets/images/revisedlogoooooo.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../Css/loading.css";
import Whatsapp from "../assets/images/whats2.svg";
import Instagram from "../assets/images/S2.png";
import Linked from "../assets/images/S3.png";
import Email from "../assets/images/mail21.svg";
import ChatIconNew from "../assets/images/chatInconnew.svg";
import OfficeIcon from "../assets/images/officeIcon.svg";
import FloatingIcnBot from "../assets/images/botimgfloat.svg";
import ScrollDown from "../assets/images/scrollDown.png";

import {
  server_post_data,
  footer_data,
} from "../ServiceConnection/serviceconnection.js";
import { handleLinkClick } from "../CommonJquery/CommonJquery.js";
function Header({ footerRef }) {
  const location = useLocation();
  const [dataloop, setData] = useState([]);
  const currentUrl = location.pathname;
  const handleFetchData = async () => {
    console.log(currentUrl);
    await server_post_data(footer_data, null)
      .then((response) => response.json())
      .then((data_data) => {
        setData(data_data.data_loop[0]);
      })
      .catch((error) => {});
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };
  //new page open on link the routing link
  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };
  const [scrolling, setScrolling] = useState(false);
  function sendMessageToWhatsApp(mobile_no) {
    const message = "Hello, How may i help You!"; // Replace with your desired message
    // Create a WhatsApp URL with the phone number and message
    const whatsappURL = `https://wa.me/${mobile_no}?text=${encodeURIComponent(
      message
    )}`;

    // Open the WhatsApp chat in a new tab or window
    window.open(whatsappURL, "_blank");
  }

  function sendEmail(email_id) {
    window.location.href = `mailto:${email_id}`;
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleFetchData();
    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      var scrollDown = document.getElementById("scrollDown");
      var footer = document.getElementById("footer");
      var scrollPosition = window.innerHeight + window.scrollY;

      if (scrollPosition >= footer.offsetTop) {
        scrollDown.style.display = "none"; // Hide the div when reaching footer
      } else {
        scrollDown.style.display = "block"; // Show the div otherwise
      }
    });
  }, []);

  const servicesDivRef = footerRef;
  const scrollToForm = () => {
    if (servicesDivRef.current) {
      const testimonialsElement = servicesDivRef.current;
      const testimonialsOffset = testimonialsElement.offsetTop;
      const scrollToPosition =
        testimonialsOffset -
        6 * parseFloat(getComputedStyle(document.documentElement).fontSize);

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  };

  const headerClass = scrolling ? "scrolled-header" : "";
  return (
    <div>
      <nav
        className={`navbar navStickyTop navbar-expand-lg navbar-light bg_lightblack ${headerClass}`}
      >
        <div className="navbarPAdding container-fluid">
          <a className="navbar-brand" href="/">
            <div className="brand_img">
              <img src={trilogonew} alt="logo" className="shining-logo" />
            </div>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="d-none navbar-toggler-icon"></span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="#ffffff"
              className="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/About" ? "navIemTextSelected" : ""
                  }`}
                  aria-current="page"
                  to="/About"
                >
                  Who we are
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className="nav-link"
                  onClick={() => handleLinkClick("/About")}
                >
                  What we do
                </Link>
                <Link
                  className={`nav-link ${
                    location.pathname === "/Insights"
                      ? "navIemTextSelected"
                      : ""
                  }`}
                  onClick={() => handleLinkClick("/Insights")}
                >
                  Our Blog
                </Link>
              </li> */}
              {/* <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/Career" ? "navIemTextSelected" : ""
                  }`}
                  aria-current="page"
                  onClick={() => handleLinkClick("/Career")}
                >
                  Career
                </Link>
              </li> */}
              <li className="nav-item navDropdownDIv">
                <div
                  className="dropdown "
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <p
                    className={`dropdown-toggle`}
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ color: "white", marginBottom: "0" }}
                  >
                    Services
                  </p>
                  <ul
                    className="dropdown-menu "
                    // className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                    aria-labelledby="dropdownMenuButton1"
                    // style={{
                    //   border: "1px solid #eee", // Border color is black
                    // }}
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        // onClick={() => handleLinkClick("/Testimonials")}
                        style={{ color: "#aaa" }}
                      >
                        Metaverse Development
                        {/* AI & ML */}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        // onClick={() => handleLinkClick("/Testimonials")}
                        style={{ color: "#aaa" }}
                      >
                        Custom Software Development
                        {/* XR Development */}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        // onClick={() => handleLinkClick("/Testimonials")}
                        style={{ color: "#aaa" }}
                      >
                        Saas and Paas Product Development
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        // onClick={() => handleLinkClick("/Insights")}
                        style={{ color: "#aaa" }}
                      >
                        Artificial Intelligence & Machine Learning Solutions
                      </Link>
                    </li>
                    <li
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1,
                        height: "3px",
                        border: "1px solid",
                        backgroundImage:
                          "linear-gradient(270deg, #76ff01 0.73%, #edff0b 18%, #15ff3e 33.06%, #17ffff 49.89%, #1700f5 67.61%, #ec00e7 85.77%)",
                      }}
                    ></li>
                    <li
                      style={{
                        zIndex: -1,
                        height: "3px",
                        border: "1px solid",
                        backgroundImage:
                          "linear-gradient(270deg, #76ff01 0.73%, #edff0b 18%, #15ff3e 33.06%, #17ffff 49.89%, #1700f5 67.61%, #ec00e7 85.77%)",
                      }}
                    ></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item navDropdownDIv">
                <div
                  className="dropdown "
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <p
                    className={`dropdown-toggle `}
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ color: "white", marginBottom: "0" }}
                  >
                    Solution
                  </p>
                  <ul
                    className="dropdown-menu "
                    // className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                    aria-labelledby="dropdownMenuButton1"
                    // style={{
                    //   border: "1px solid #eee", // Border color is black
                    // }}
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        target="_blank"
                        style={{ color: "#aaa" }}
                        onClick={() => handleLinkClick("https://chatnext.co/")}
                      >
                        Interactive AI Chatbot
                        {/* AI Debt Collection */}
                      </Link>
                      <Link
                        className="dropdown-item"
                        style={{ color: "#aaa" }}
                        onClick={() =>
                          handleLinkClick(
                            "https://ai.triosoft.ai/debt-collection"
                          )
                        }
                      >
                        AI-Powered Debt Recovery Solutions
                        {/* AI Appointment Management */}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={() =>
                          handleLinkClick(
                            "https://ai.triosoft.ai/appointment-management"
                          )
                        }
                        style={{ color: "#aaa" }}
                      >
                        Automated AI Appointment Scheduling
                        {/* AI CX Software */}
                      </Link>
                      <Link
                        className="dropdown-item"
                        onClick={() =>
                          handleLinkClick("https://ai.triosoft.ai/cx-software")
                        }
                        style={{ color: "#aaa" }}
                      >
                        AI-Enhanced Customer Experience Platform
                      </Link>
                    </li>
                    <li
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1,
                        height: "3px",
                        border: "1px solid",
                        backgroundImage:
                          "linear-gradient(270deg, #76ff01 0.73%, #edff0b 18%, #15ff3e 33.06%, #17ffff 49.89%, #1700f5 67.61%, #ec00e7 85.77%)",
                      }}
                    ></li>
                    <li
                      style={{
                        zIndex: -1,
                        height: "3px",
                        border: "1px solid",
                        backgroundImage:
                          "linear-gradient(270deg, #76ff01 0.73%, #edff0b 18%, #15ff3e 33.06%, #17ffff 49.89%, #1700f5 67.61%, #ec00e7 85.77%)",
                      }}
                    ></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item navDropdownDIv">
                <div
                  className="dropdown "
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <p
                    className={`dropdown-toggle `}
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ color: "white", marginBottom: "0" }}
                  >
                    Product
                  </p>
                  <ul
                    className="dropdown-menu "
                    // className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                    aria-labelledby="dropdownMenuButton1"
                    // style={{
                    //   border: "1px solid #eee", // Border color is black
                    // }}
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        onClick={() => handleLinkClick("https://chatnext.co/")}
                        style={{ color: "#aaa" }}
                      >
                        Chatnext
                      </Link>
                      <Link
                        className="dropdown-item"
                        onClick={() =>
                          handleLinkClick("https://www.wowreviews.co/")
                        }
                        style={{ color: "#aaa" }}
                      >
                        Wowreviews
                      </Link>
                      <Link
                        className="dropdown-item"
                        onClick={() =>
                          handleLinkClick("https://www.chatgptnotepad.com/")
                        }
                        style={{ color: "#aaa" }}
                      >
                        Chatgptnotepad
                      </Link>
                    </li>
                    <li
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1,
                        height: "3px",
                        border: "1px solid",
                        backgroundImage:
                          "linear-gradient(270deg, #76ff01 0.73%, #edff0b 18%, #15ff3e 33.06%, #17ffff 49.89%, #1700f5 67.61%, #ec00e7 85.77%)",
                      }}
                    ></li>
                    <li
                      style={{
                        zIndex: -1,
                        height: "3px",
                        border: "1px solid",
                        backgroundImage:
                          "linear-gradient(270deg, #76ff01 0.73%, #edff0b 18%, #15ff3e 33.06%, #17ffff 49.89%, #1700f5 67.61%, #ec00e7 85.77%)",
                      }}
                    ></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item navDropdownDIv">
                <div
                  className="dropdown "
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <p
                    className={`dropdown-toggle ${
                      location.pathname === "/Insights" ||
                      location.pathname === "/Testimonials"
                        ? "navIemTextSelected"
                        : ""
                    }`}
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ color: "white", marginBottom: "0" }}
                  >
                    Insights
                  </p>
                  <ul
                    className="dropdown-menu "
                    // className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                    aria-labelledby="dropdownMenuButton1"
                    // style={{
                    //   border: "1px solid #eee", // Border color is black
                    // }}
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/Insights"
                        style={{ color: "#aaa" }}
                      >
                        Blogs
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/Testimonials"
                        style={{ color: "#aaa" }}
                      >
                        Testimonials
                      </Link>
                    </li>
                    <li
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1,
                        height: "3px",
                        border: "1px solid",
                        backgroundImage:
                          "linear-gradient(270deg, #76ff01 0.73%, #edff0b 18%, #15ff3e 33.06%, #17ffff 49.89%, #1700f5 67.61%, #ec00e7 85.77%)",
                      }}
                    ></li>
                    <li
                      style={{
                        zIndex: -1,
                        height: "3px",
                        border: "1px solid",
                        backgroundImage:
                          "linear-gradient(270deg, #76ff01 0.73%, #edff0b 18%, #15ff3e 33.06%, #17ffff 49.89%, #1700f5 67.61%, #ec00e7 85.77%)",
                      }}
                    ></li>
                  </ul>
                </div>
              </li>
            </ul>
            <span className="navbar-text headerBtns">
              <Link to="/Casestudy">
                <button className="careerbTNhEADER">Case studies</button>
              </Link>
              <Link to="/contact-us">
                <button className="primary_btn ">
                  <p>Contact Us </p>
                  <span className="right_arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </button>
              </Link>
            </span>
          </div>
        </div>
      </nav>
      <div className="socialIcnsonScree d-none d-lg-block">
        <ul>
          <li
            className="sideIcons1"
            onClick={() => sendMessageToWhatsApp(dataloop.mobile_link)}
          >
            <img src={Whatsapp} alt="icon" />
          </li>
          <li className="sideIcons2">
            <a href={dataloop.instagram_link} target="_blank">
              <img src={Instagram} alt="icon" />
            </a>
          </li>
          <li className="sideIcons3">
            <a href={dataloop.linkdin_link} target="_blank">
              <img src={Linked} alt="icon" />
            </a>
          </li>
          <li
            className="sideIcons4"
            onClick={() => sendEmail(dataloop.email_link)}
          >
            <img src={Email} alt="icon" />
          </li>
        </ul>
      </div>

      <div className="scrollDown" id="scrollDown" onClick={scrollToForm}>
        <img src={ScrollDown} alt="icon" />
      </div>

      {/* <div className="FloatingDiv">
        <a style={{ cursor: "pointer" }} className="float">
          <img src={FloatingIcnBot} alt="botIcon" />
        </a>
        <ul style={{ listStyle: "none" }}>
          <li>
            <div className="chatboxHead">
              <p>
                Welcome to Triosoft! How can <br />
                we help you?
              </p>
            </div>
          </li>
          <li>
            <Link to="#">
              <div className="flaotingLinks">
                <img src={ChatIconNew} alt="chat icon" />
                <p>Chat with us </p>
              </div>
            </Link>
          </li>
          <li>
            <Link onClick={() => handleLinkClick("/Career")}>
              <div className="flaotingLinks">
                <img src={OfficeIcon} alt="chat icon" />
                <p>Looking for A Job Opportunity !</p>
              </div>
            </Link>
          </li>
        </ul>
      </div> */}
    </div>
  );
}

export default Header;
